import { yupResolver } from "@hookform/resolvers/yup";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Typography } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { MainButton } from "../../components/common/Button/MainButton";
import { Card } from "../../components/common/Container/Containers";
import { MainPageWrapper } from "../../components/common/Container/MainPage";
import { PageWrapper } from "../../components/common/Container/PageWrapper";
import { TwoColumnsContainer } from "../../components/common/Container/TwoColumnsContainer";
import { Cover } from "../../components/common/Cover/Cover";
import { Form } from "../../components/common/Form/Form";
import { FormInput } from "../../components/common/Form/FormInput";
import { FormSelect } from "../../components/common/Form/FormSelect";
import { FormStack } from "../../components/common/Form/FormStack";
import {
  AdditionalData,
  AdditionalDataWrapper,
} from "../../components/common/StepComponents/AdditionalData";
import { SectionTitle } from "../../components/common/StepComponents/StepContainers";
import { SurfaceArea, SurfaceAreaForm } from "../../components/common/SurfaceArea";
import { Photos } from "../../components/photos/Photos";
import { useAppSelector } from "../../store/hooks";
import {
  deleteFloorElement,
  editFloorElement,
  getBuildingConstructionYear,
  getCategorySpecificProducts,
  getFilteredImageTags,
  getFilteredSuggestions,
  getFloorByName,
  getFloorsData,
  saveFloorElement,
} from "../../store/slices";
import { RootState } from "../../store/store";
import {
  Category,
  Crawlspace,
  CrawlspaceHumidity,
  FloorType,
  IFloor,
  InsulationMaterial,
  SoilInsulationMaterial,
  SuggestionCategory,
} from "../../types";
import { getFloorSchema } from "../../types/schema";
import isNameUnique from "../../utils/helpers/nameChecker";
import { useConfirm } from "../../components/common/Dialog/ConfirmDialog";
import { toast } from "react-toastify";
import stringToNumber from "../../utils/helpers/strToNum";
import { updateHouseImageRequestsWithNewName } from "src/utils/updateHouseImageRequestsWithNewName";
import { deleteHouseImageRequestFromLocalStorageForHousePart } from "src/utils/deleteHouseImageRequestFromLocalStorageForHousePart";
import { getTrimmedName } from "src/utils/helpers/getTrimmedName";

const FLOOR_NAME_UNKNOWN = "floor_unknown";
interface SituationProps {
  form: UseFormReturn<IFloor & SurfaceAreaForm, any>;
}

interface newSituationProps extends SituationProps {
  floorInsulationMaterials: { id: string; name: string }[];
  soilInsulationMaterials: { id: string; name: string }[];
}

const CurrentSituation: FC<SituationProps> = ({ form }) => {
  const { t } = useTranslation();
  const [showSurface, setShowSurface] = useState(false);
  const {
    register,
    formState: { errors },
    watch,
    trigger,
    setValue,
    clearErrors,
  } = form;

  const soilInsulationMaterial = watch("soilInsulationMaterial");
  const currentFloorInsulationMaterial = watch("insulationMaterial");
  const crawlspace = watch("crawlspace");

  const handleSelectCrawlspace = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.target;
    trigger();
    if (value === t("none")) {
      setValue("crawlspaceHeight", 0);
      setValue("compartmentCount", 0);
      clearErrors(["crawlspaceHeight", "compartmentCount"]);
    }
  };
  const handleSelectInsulation = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.target;
    trigger();
    if (value === t("none")) {
      setValue("insulationThickness", 0);
      clearErrors("insulationThickness");
    }
  };

  const handleSelectSoilInsulation = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { value } = event.target;
    trigger();
    if (value === t("none")) {
      setValue("soilInsulationThickness", 0);
      clearErrors("soilInsulationThickness");
    }
  };
  return (
    <Card height="98%">
      <Box display="flex" alignItems="center">
        <Typography variant="h2" fontWeight={600}>
          {t("currentSituation")}
        </Typography>
      </Box>

      <FormStack pt={4}>
        <FormInput {...register("name")} label={t("name")} error={errors.name} />

        <FormInput
          {...register("constructionYear", { valueAsNumber: true })}
          label={t("constructionYear")}
          error={errors.constructionYear}
        />

        <FormInput
          {...register("area", { setValueAs: (v) => stringToNumber(v) })}
          label={t("surfaceArea")}
          sx={{ ".MuiInputLabel-shrink": { left: "0" } }}
          error={errors.area}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <MainButton
                  variant="white"
                  text={t("edit")}
                  icon={<EditIcon fontSize="small" sx={{ display: "flex" }} />}
                  onClick={() => setShowSurface(!showSurface)}
                />
              </InputAdornment>
            ),
          }}
        />
        {showSurface && <SurfaceArea form={form} isFloor={true} />}

        <FormSelect
          name="floorType"
          form={form}
          label={t("floorType")}
          options={Object.values(FloorType)}
          error={errors.floorType}
          value={form.getValues("floorType")}
        />

        <FormSelect
          form={form}
          {...register("crawlspace", { onChange: (event) => handleSelectCrawlspace(event) })}
          label={t("crawlspace")}
          options={Object.values(Crawlspace)}
          error={errors.crawlspace}
          value={form.getValues("crawlspace")}
        />

        <FormInput
          {...register("crawlspaceHeight", { setValueAs: (v) => stringToNumber(v) })}
          label={t("crawlspaceHeight")}
          error={errors.crawlspaceHeight}
          disabled={crawlspace === undefined}
        />

        <FormInput
          {...register("compartmentCount", { setValueAs: (v) => stringToNumber(v) })}
          label={t("compartmentCount")}
          error={errors.compartmentCount}
          disabled={crawlspace === undefined}
        />

        <FormSelect
          name="crawlspaceHumidity"
          form={form}
          label={t("crawlspaceHumidity")}
          options={
            crawlspace === Object.values(Crawlspace)[0]
              ? [Object.values(CrawlspaceHumidity)[0]]
              : Object.values(CrawlspaceHumidity)
          }
          error={errors.crawlspaceHumidity}
          value={form.getValues("crawlspaceHumidity")}
        />

        <SectionTitle title={t("floorInsulation")} mt={2} />

        <FormSelect
          form={form}
          {...register("insulationMaterial", {
            onChange: (event) => handleSelectInsulation(event),
          })}
          label={t("currentFloorInsulationMaterial")}
          options={Object.values(InsulationMaterial)}
          error={errors.insulationMaterial}
          value={form.getValues("insulationMaterial")}
        />

        <FormInput
          {...register("insulationThickness", { setValueAs: (v) => stringToNumber(v) })}
          label={t("currentFloorInsulationThickness")}
          error={errors.insulationThickness}
          disabled={currentFloorInsulationMaterial === undefined}
        />

        <SectionTitle title={t("soilInsulation")} mt={2} />

        <FormSelect
          form={form}
          {...register("soilInsulationMaterial", {
            onChange: (event) => handleSelectSoilInsulation(event),
          })}
          label={t("currentSoilInsulationMaterial")}
          options={Object.values(SoilInsulationMaterial)}
          error={errors.soilInsulationMaterial}
          value={form.getValues("soilInsulationMaterial")}
        />

        <FormInput
          {...register("soilInsulationThickness", { setValueAs: (v) => stringToNumber(v) })}
          label={t("currentSoilInsulationThickness")}
          error={errors.soilInsulationThickness}
          disabled={soilInsulationMaterial === undefined}
        />
      </FormStack>
    </Card>
  );
};

const NewSituation: FC<newSituationProps> = ({
  form,
  floorInsulationMaterials,
  soilInsulationMaterials,
}) => {
  const { t } = useTranslation();

  const {
    register,
    formState: { errors },
    watch,
    setValue,
    clearErrors,
    trigger,
  } = form;

  const floorInsulation = watch("measure.floorInsulationProduct.name");
  const soilInsulation = watch("measure.soilInsulationProduct.name");

  const handleSelectInsulationProduct = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { value } = event.target;
    trigger();
    if (value === "geen") {
      setValue("measure.floorInsulationThickness", 0);
      clearErrors("measure.floorInsulationThickness");
    }
  };

  const handleSelectSoilInsulation = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    trigger();
    const { value } = event.target;

    if (value === "geen") {
      setValue("measure.soilInsulationThickness", 0);
      setValue("measure.soilWallSeparationLength", 0);
      clearErrors(["measure.soilInsulationThickness", "measure.soilWallSeparationLength"]);
    }
  };

  return (
    <Card height="98%">
      <Box display="flex" alignItems="center">
        <Typography variant="h2" fontWeight={600}>
          {t("newSituation")}
        </Typography>
      </Box>

      <FormStack pt={4}>
        <FormSelect
          form={form}
          {...register("measure.floorInsulationProduct.name", {
            onChange: (event) => handleSelectInsulationProduct(event),
          })}
          label={t("floorInsulationMaterial")}
          options={floorInsulationMaterials.map((el) => el.name)}
          error={errors.measure?.floorInsulationProduct?.name}
        />

        <FormInput
          {...register("measure.floorInsulationThickness", {
            setValueAs: (v) => stringToNumber(v),
          })}
          label={t("floorInsulationThickness")}
          error={errors.measure?.floorInsulationThickness}
          disabled={floorInsulation === undefined}
        />

        <FormSelect
          form={form}
          {...register("measure.soilInsulationProduct.name", {
            onChange: (event) => handleSelectSoilInsulation(event),
          })}
          label={t("soilInsulationProduct")}
          options={soilInsulationMaterials.map((el) => el.name)}
          error={errors.measure?.soilInsulationProduct?.name}
        />

        <FormInput
          {...register("measure.soilInsulationThickness", { setValueAs: (v) => stringToNumber(v) })}
          label={t("soilInsulationThickness")}
          error={errors.measure?.soilInsulationThickness}
          disabled={soilInsulation === undefined}
        />

        <FormInput
          {...register("measure.soilWallSeparationLength", {
            setValueAs: (v) => stringToNumber(v),
          })}
          label={t("soilWallSeparationLength")}
          error={errors.measure?.soilWallSeparationLength}
          disabled={soilInsulation === undefined}
        />
      </FormStack>
    </Card>
  );
};

export const AddFloorElement: FC = () => {
  const { floorId, id } = useParams();
  const sphId = id ? id : "";

  const confirm = useConfirm();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const floors = useAppSelector(getFloorsData);
  const floor = useAppSelector((state: RootState) => getFloorByName(state, floorId || ""));

  const floorInsulationMaterials = useAppSelector(getCategorySpecificProducts(Category.FLOOR));
  const soilInsulationMaterials = useAppSelector(getCategorySpecificProducts(Category.SOIL));

  const houseInfoConstructionYear = useAppSelector(getBuildingConstructionYear);

  const tags = useAppSelector(getFilteredImageTags(Category.ROOF));
  const suggestions = useAppSelector(getFilteredSuggestions(SuggestionCategory.EXECUTIVE_FLOOR));

  const formDefaultValues = {
    ...floor,
    area: floor?.area,
    constructionYear: floor?.constructionYear || houseInfoConstructionYear,
    floorType: floor?.floorType,
    crawlspace: floor?.crawlspace,
    insulationMaterial: floor?.insulationMaterial,
    insulationThickness: floor?.insulationThickness,
    soilInsulationMaterial: floor?.soilInsulationMaterial,
    soilInsulationThickness: floor?.soilInsulationThickness,
    crawlspaceHumidity: floor?.crawlspaceHumidity,
    crawlspaceHeight: floor?.crawlspaceHeight,
    compartmentCount: floor?.compartmentCount,
    measure: {
      floorInsulationProduct: {
        id: floor?.measure?.floorInsulationProduct?.id || "",
        name:
          floor?.measure.floorInsulationProduct === null
            ? "geen"
            : floor?.measure?.floorInsulationProduct?.name || "",
      },
      soilInsulationProduct: {
        id: floor?.measure?.soilInsulationProduct?.id || "",
        name:
          floor?.measure.soilInsulationProduct === null
            ? "geen"
            : floor?.measure?.soilInsulationProduct?.name || "",
      },
      soilWallSeparationLength: floor?.measure?.soilWallSeparationLength,
      soilInsulationThickness: floor?.measure.soilInsulationThickness,
      floorInsulationThickness: floor?.measure?.floorInsulationThickness,
    },
    images: floor?.images || [],
    notes: floor?.notes || "",
  };

  const form = useForm<IFloor & SurfaceAreaForm>({
    resolver: yupResolver(getFloorSchema(t)),
    mode: "all",
    defaultValues: formDefaultValues,
  });

  const {
    formState: { errors },
    trigger,
    watch,
  } = form;

  const floorName = getTrimmedName(watch("name"));

  const handleSave = async () => {
    await trigger();
    const selectedFloorInsulation = form.getValues("measure.floorInsulationProduct.name");
    const selectedSoilInsulation = form.getValues("measure.soilInsulationProduct.name");
    const selectedFloorInsulationProduct = floorInsulationMaterials.find(
      (el) => el.name === selectedFloorInsulation,
    );
    const selectedSoilInsulationProduct = soilInsulationMaterials.find(
      (el) => el.name === selectedSoilInsulation,
    );

    selectedFloorInsulationProduct &&
      form.setValue("measure.floorInsulationProduct.id", selectedFloorInsulationProduct.id);
    selectedSoilInsulationProduct &&
      form.setValue("measure.soilInsulationProduct.id", selectedSoilInsulationProduct.id);

    if ("name" in form.formState.errors || "name" in form.formState.errors) {
      toast.error(t("mandatoryField"));
      form.setFocus("name");
      return;
    }

    if (floorName !== floorId && isNameUnique(floors, floorName)) {
      toast.error(t("uniqueName"));
      form.setFocus("name");
      return;
    }

    if (floorId) {
      dispatch(
        editFloorElement({
          ...form.getValues(),
          name: floorName,
          errors: Object.keys(form.formState.errors),
          floorId,
        }),
      );
    } else {
      dispatch(
        saveFloorElement({
          ...form.getValues(),
          name: floorName,
          errors: Object.keys(form.formState.errors),
        }),
      );
    }

    // Determine the name of the element, defaulting to "floor_unknown" if 'floor.name' is undefined
    const previousFloorName = floor?.name ?? FLOOR_NAME_UNKNOWN;

    // Update image requests in local storage where elementName mathches with the new 'previousFloorName'
    updateHouseImageRequestsWithNewName(sphId, previousFloorName, floorName);

    navigate(-1);
  };

  useEffect(() => {
    trigger();
  }, [trigger]);

  const handleDelete = async () => {
    const choice = await confirm({
      title: t("delete"),
      description: t("deleteFloor"),
      confirmBtnLabel: t("delete"),
    });

    if (choice) {
      dispatch(deleteFloorElement({ floorId }));

      // Determine the name of the element, defaulting to "floor_unknown" if  'floor.name' is undefined
      const floorName = floor?.name ?? FLOOR_NAME_UNKNOWN;
      // Remove image requests from local storage for the deleted floor element if they exist
      deleteHouseImageRequestFromLocalStorageForHousePart(floorName, sphId);

      navigate(-1);
    }
  };

  return (
    <MainPageWrapper>
      <Cover
        title={floorId ? t("editFloor") : t("addFloor")}
        showBackButton={true}
        handleDelete={floorId ? handleDelete : undefined}
      />
      <PageWrapper>
        <Form>
          <TwoColumnsContainer
            childrenFirstColumn={<CurrentSituation form={form} />}
            childrenSecondColumn={
              <NewSituation
                form={form}
                floorInsulationMaterials={floorInsulationMaterials}
                soilInsulationMaterials={soilInsulationMaterials}
              />
            }
          />

          <Photos
            tags={tags}
            sphId={sphId}
            form={form}
            housePartElement="floor"
            elementName={floor?.name ?? FLOOR_NAME_UNKNOWN}
          />

          <AdditionalDataWrapper title={t("additionalExecutiveComment")}>
            <AdditionalData
              name="notes"
              form={form}
              suggestions={suggestions}
              error={errors.notes}
            />
          </AdditionalDataWrapper>

          <MainButton
            text={t("save")}
            variant="primary"
            sx={{ margin: "30px 0" }}
            onClick={handleSave}
          />
        </Form>
      </PageWrapper>
    </MainPageWrapper>
  );
};
