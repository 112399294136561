import {
  Card,
  CardHeader,
  TableCell,
  TableRow,
  Select,
  FormControl,
  styled,
  List,
} from "@mui/material";

export const HouseCardStyled = styled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  borderRadius: "25px",
  margin: theme.spacing(4),
  padding: theme.spacing(6),
  [theme.breakpoints.down("md")]: {
    margin: theme.spacing(3),
    padding: theme.spacing(3),
  },
}));

export const HouseTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "500",
  fontSize: "14px",
  textAlign: "left",
  color: theme.palette.neutral[900],
  "&.MuiTableCell-head": {
    fontWeight: "600",
  },
}));

export const HouseSearch = styled(FormControl)(() => ({
  ".MuiSvgIcon-root": {
    fontSize: "2rem",
    paddingBottom: ".5rem",
  },
  ".MuiFormLabel-root": {
    top: "2px",
    left: "35px",
    fontSize: "16px",
    color: "#8B8B8B", // TODO: Move to color Palette
    fontWeight: "600",
  },
  ".MuiFormLabel-filled": {
    opacity: "0 !important",
  },
  "label.MuiFormLabel-root.Mui-focused": {
    opacity: "0 !important",
  },
}));

export const SelectDropdown = styled(Select)(() => ({
  display: "flex",
  width: "100%",
  marginLeft: "auto",
  ".MuiOutlinedInput-notchedOutline > label": {
    height: "20px",
    span: {
      opacity: "1 !important",
    },
  },
}));

export const TableRows = styled(TableRow)(() => ({
  height: "50px",
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const InspectionLists = styled(List)(({ theme }) => ({
  margin: "0 0 0 40px",
  padding: 0,
  ".MuiListItem-root span": {
    fontWeight: 600,
    marginRight: theme.spacing(1),
  },
}));

export const InspectionCardStyled = styled(Card)(({ theme }) => ({
  padding: theme.spacing(2),
  height: "100%",
  borderRadius: "25px",
  boxShadow: "none",
}));

export const InspectionCardHeader = styled(CardHeader)(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(3),
  ".MuiTypography-root": {
    fontWeight: "600"
  }
}));
