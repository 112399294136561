import { Box, styled } from "@mui/material";

export const BlackBackground = styled(Box)(({ theme }) => ({
  position: "fixed",
  top: 0,
  // left: "-60px",
  zIndex: 1101,
  // width: "calc(100% + 60px)",
  width: "100%",
  height: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.palette.neutral[900],
}));

export const ButtonName = styled(Box)(() => ({ 
  display: "flex",
  marginTop: "35px",
  justifyContent: "flex-end"
}));

