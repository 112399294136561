import { useNavigate } from "react-router-dom";
import backIcon from "../../../assets/icons/arrow_back.svg";
import { useTranslation } from "react-i18next";
import { useConfirm } from "../Dialog/ConfirmDialog";
import { Box, BoxProps } from "@mui/material";

interface IBackButton {
  previousPage?: boolean;
  props?: BoxProps;
}

const BackButton = ({ previousPage, props }: IBackButton) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const confirm = useConfirm();

  const navigateToPreviousPage = async () => {
    const choice = await confirm({
      title: t("inspection"),
      description: t("cancelInspection"),
      confirmBtnLabel: t("continue"),
      deleteMessage: t("inspectionCancel"),
    });

    if (choice) {
      navigate("/houses");
    }
  };

  return (
    <Box {...props}>
      {previousPage ? (
        <img src={backIcon} alt="icon" onClick={() => navigateToPreviousPage()} />
      ) : (
        <img src={backIcon} alt="icon" onClick={() => navigate(-1)} />
      )}
    </Box>
  );
};

export default BackButton;
