import { Direction, IImage, IWindowGroup } from ".";

export enum DormerType {
  STANDARD = "standaard",
  WITH_PARAPET = "met borstwering",
  RAISED_RIDGE = "verhoogde nok",
  SHIFTED_RIDGE = "verlegde nok",
}

export enum InsulationCondition {
  BAD = "niet of waarschijnlijk niet",
  MODERATE = "matig (slecht of gedeeltelijk geisoleerd)",
  GOOD = "goed (niet ouder dan 30 jaar of goed nageisoleerd)",
  PERFECT = "perfect (niet ouder dan ca 5 jaar)",
}

export interface IDormer {
  name: string;
  dormerType: DormerType;
  width: number;
  length: number;
  height: number;
  insulationCondition: InsulationCondition;
  windowGroups: IWindowGroup[];
  images: IImage[];
  errors?: string[];
  roofName?: string;
  area?: number;
  direction?: Direction;
}
