import { Box, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { Card } from "../../components/common/Container/Containers";
import { PageWrapper } from "../../components/common/Container/PageWrapper";
import { TwoColumnsContainer } from "../../components/common/Container/TwoColumnsContainer";
import {
  AdditionalData,
  AdditionalDataWrapper,
} from "../../components/common/StepComponents/AdditionalData";
import { MainPageWrapper } from "../../components/common/Container/MainPage";
import { Cover } from "../../components/common/Cover/Cover";
import { SurfaceArea, SurfaceAreaForm } from "../../components/common/SurfaceArea";
import { MainButton } from "../../components/common/Button/MainButton";
import { useForm, UseFormReturn } from "react-hook-form";
import { FormInput } from "../../components/common/Form/FormInput";
import { Form } from "../../components/common/Form/Form";
import { FormStack } from "../../components/common/Form/FormStack";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormSelect } from "../../components/common/Form/FormSelect";
import InputAdornment from "@mui/material/InputAdornment";
import EditIcon from "@mui/icons-material/Edit";
import {
  deleteAtticFloor,
  getAtticFloorData,
  getCategorySpecificProducts,
  getFilteredImageTags,
  getFilteredSuggestions,
  saveAtticFloor,
} from "../../store/slices";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { IAtticFloor, Category, SuggestionCategory, Prefixes } from "../../types";
import { Photos } from "../../components/photos/Photos";
import { useAppSelector } from "../../store/hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { getAtticFloorSchema } from "../../types/schema";
import { useTranslation } from "react-i18next";
import { useConfirm } from "../../components/common/Dialog/ConfirmDialog";
import stringToNumber from "../../utils/helpers/strToNum";
import { FailedImageUploadRequest } from "src/types/failedImageUploadRequest";
import customLocalStorage from "src/utils/localStorage";

export type AtticSituationProps = {
  form: UseFormReturn<IAtticFloor & SurfaceAreaForm, any>;
};

const CurrentSituation: FC<AtticSituationProps> = ({ form }) => {
  const [showSurface, setShowSurface] = useState(false);
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
  } = form;

  return (
    <Card>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography variant="h2" fontWeight={600}>
          {t("currentSituation")}
        </Typography>
      </Box>
      <FormStack mt={4}>
        <FormInput
          {...register("area", { setValueAs: (v) => stringToNumber(v) })}
          label={t("surfaceArea")}
          error={errors.area}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <MainButton
                  variant="white"
                  text={t("edit")}
                  icon={<EditIcon fontSize="small" sx={{ display: "flex" }} />}
                  onClick={() => setShowSurface(!showSurface)}
                />
              </InputAdornment>
            ),
          }}
        />

        {showSurface && <SurfaceArea form={form} />}

        <FormInput
          {...register("ridgeHeight", { setValueAs: (v) => stringToNumber(v) })}
          InputLabelProps={{ shrink: true }}
          label={t("ridgeHeightAttic")}
          error={errors.ridgeHeight}
        />
      </FormStack>
    </Card>
  );
};

const NewSituation: FC<
  AtticSituationProps & { insulationMaterials: { id: string; name: string }[] }
> = ({ form, insulationMaterials }) => {
  const { t } = useTranslation();

  const {
    register,
    formState: { errors },
    watch,
    clearErrors,
    setValue,
  } = form;

  const possibleInsulation = watch("measure.insulationProduct.name");

  useEffect(() => {
    if (possibleInsulation === "geen") {
      setValue("measure.insulationThickness", 0);
      clearErrors("measure.insulationProduct");
      clearErrors("measure.insulationThickness");
    }
  }, [clearErrors, possibleInsulation, setValue]);

  return (
    <Card height="93%">
      <Box display="flex" alignItems="center">
        <Typography variant="h2" fontWeight={600}>
          {t("newSituation")}
        </Typography>
      </Box>

      <FormStack pt={4}>
        <FormSelect
          name={"measure.insulationProduct.name"}
          form={form}
          label={t("insulationMaterial")}
          options={insulationMaterials.map((el) => el.name)}
          error={errors.measure?.insulationProduct?.name}
          InputLabelProps={{ shrink: true }}
        />

        <FormInput
          {...register("measure.insulationThickness", { setValueAs: (v) => stringToNumber(v) })}
          label={t("insulationThickness")}
          error={errors.measure?.insulationThickness}
          disabled={possibleInsulation === undefined || possibleInsulation === "geen"}
          InputLabelProps={{ shrink: true }}
        />
      </FormStack>
    </Card>
  );
};

export const AddAtticFloor: FC = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const floor = useSelector((state: RootState) => getAtticFloorData(state));
  const confirm = useConfirm();
  const sphId = id ? id : "";

  const insulationMaterials = useAppSelector(getCategorySpecificProducts(Category.ATTIC_FLOOR));

  const formDefaultValues = {
    area: floor?.area,
    notes: floor?.notes || "",
    ridgeHeight: floor?.ridgeHeight,
    measure: {
      insulationProduct: {
        id: floor?.measure?.insulationProduct?.id,
        name:
          floor?.measure.insulationProduct === null
            ? "geen"
            : floor?.measure?.insulationProduct?.name || "",
      },
      insulationThickness: floor?.measure?.insulationThickness,
    },
    images: floor?.images || [],
  };

  const form = useForm<IAtticFloor & SurfaceAreaForm>({
    resolver: yupResolver(getAtticFloorSchema(t)),
    mode: "all",
    defaultValues: formDefaultValues,
  });

  const {
    formState: { errors },
    trigger,
    handleSubmit,
  } = form;

  const tags = useAppSelector(getFilteredImageTags(Category.ATTIC_FLOOR));

  const roofSuggestionExecutive = useAppSelector(
    getFilteredSuggestions(SuggestionCategory.EXECUTIVE_ROOF),
  );

  const handleSave = async () => {
    await trigger();
    const selectedInsulationProduct = form.getValues("measure.insulationProduct.name");
    const selectedInsulationProductId = insulationMaterials.find(
      (el) => el.name === selectedInsulationProduct,
    );
    selectedInsulationProductId &&
      form.setValue("measure.insulationProduct.id", selectedInsulationProductId.id);
    dispatch(saveAtticFloor({ ...form.getValues(), errors: Object.keys(form.formState.errors) }));

    navigate(-1);
  };

  const handleDelete = async () => {
    const choice = await confirm({
      title: t("delete"),
      description: t("deleteAtticFloor"),
      confirmBtnLabel: t("delete"),
    });

    if (choice) {
      dispatch(deleteAtticFloor());
      deleteHouseImageRequestsFromLocalStorage();
      navigate(-1);
    }
  };

  const deleteHouseImageRequestsFromLocalStorage = () => {
    const requests = customLocalStorage.getItem(`${Prefixes.HOUSE_IMAGES}${sphId}`);

    // Filter out image requests from local storage where housePartElement matches 'atticFloor'
    const updatedRequests = requests.filter(
      (item: FailedImageUploadRequest) => item.housePartElement !== "atticFloor",
    );
    customLocalStorage.setItem(`${Prefixes.HOUSE_IMAGES}${sphId}`, JSON.stringify(updatedRequests));
  };

  useEffect(() => {
    form.reset({
      area: floor?.area,
      notes: floor?.notes,
      ridgeHeight: floor?.ridgeHeight,
      measure: {
        insulationProduct: {
          id: floor?.measure?.insulationProduct?.id,
          name: floor?.measure.insulationProduct?.name,
        },
        insulationThickness: floor?.measure?.insulationThickness,
      },
      images: floor?.images || [],
    });
    trigger();
  }, [trigger, floor]);

  return (
    <MainPageWrapper>
      <Cover
        title={t("addAtticFloor")}
        showBackButton={true}
        handleDelete={floor ? handleDelete : undefined}
      />
      <PageWrapper>
        <Form onSubmit={handleSubmit(handleSave, handleSave)}>
          <TwoColumnsContainer
            childrenFirstColumn={<CurrentSituation form={form} />}
            childrenSecondColumn={
              <NewSituation form={form} insulationMaterials={insulationMaterials} />
            }
          />

          <Photos tags={tags} sphId={sphId} form={form} housePartElement="atticFloor" />

          <AdditionalDataWrapper title={t("additionalExecutiveComment")}>
            <AdditionalData
              name="notes"
              form={form}
              suggestions={roofSuggestionExecutive}
              error={errors.notes}
            />
          </AdditionalDataWrapper>

          <MainButton text={t("save")} variant="primary" type="submit" sx={{ margin: "30px 0" }} />
        </Form>
      </PageWrapper>
    </MainPageWrapper>
  );
};
