import { Box, Typography } from "@mui/material";
import NetworkStatus from "../../components/NetworkStatus/NetworkStatus";
import CheckIcon from "@mui/icons-material/Check";
import { InspectionPosition, InspectionPage } from "./InspectionStyle";
import { useTranslation } from "react-i18next";
import logo from "../../assets/icons/logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import { Path } from "src/routes";

export const InspectionComplete = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();

  console.log(state);
  return (
    <InspectionPage>
      <Box display="flex">
        <Box px={7} mt={3} ml={"auto"}>
          <Box sx={{ position: "absolute", left: "10px", top: "5px" }}>
            <img
              src={logo}
              width="70px"
              alt={"DeWoonpas"}
              onClick={() => navigate(`${Path.Houses}/${state.id}`)}
            />
          </Box>
          <NetworkStatus />
        </Box>
      </Box>
      <InspectionPosition>
        <CheckIcon sx={{ fontSize: 150 }} />
        <Typography variant="h1">{t("Inspection Complete")}</Typography>
      </InspectionPosition>
    </InspectionPage>
  );
};
