import { Grid, CardContent, CardHeader, ListItem } from "@mui/material";
import ConstructionIcon from "@mui/icons-material/Construction";
import PersonIcon from "@mui/icons-material/Person";
import FaceIcon from "@mui/icons-material/Face";
import { InspectionLists, InspectionCardStyled } from "./Houses.styles";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getProducts } from "../../store/slices";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { fetchProducts } from "../../store/thunks";
import { useFilteredHouse } from "../../utils/hooks/useFilteredHouse";
import { useParams } from "react-router-dom";

export const HouseInspectionCard = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const sphId = Number(id);

  const filteredHouse = useFilteredHouse();
  const products = useAppSelector(getProducts);

  const getSupplier = (category: string): string | undefined => {
    if (products) {
      const filtered = products.filter((product) => product.category === category);
      return filtered.length > 0 ? filtered[0].supplier : undefined;
    }
  };

  useEffect(() => {
    if (!products) dispatch(fetchProducts(sphId));
  }, [dispatch, sphId, products]);

  return (
    <Grid container py={5} spacing={3}>
      <Grid item xs={12} sm={6}>
        <InspectionCardStyled>
          <CardHeader avatar={<PersonIcon sx={{ fontSize: 23 }} />} title={t("homeInfo")} />
          <CardContent sx={{ padding: 0, margin: 0 }}>
            <InspectionLists>
              {filteredHouse !== null && (
                <>
                  <ListItem>
                    <span> {t("address")}: </span> {filteredHouse?.streetName}{" "}
                    {filteredHouse?.houseNumber}
                  </ListItem>
                  <ListItem>
                    <span> {t("postalCode")}: </span> {filteredHouse?.postalCode}
                  </ListItem>
                  <ListItem>
                    <span> {t("location")}: </span> {filteredHouse?.municipality}
                  </ListItem>
                </>
              )}
            </InspectionLists>
          </CardContent>

          <CardHeader
            sx={{ marginTop: "35px" }}
            avatar={<FaceIcon sx={{ fontSize: 23 }} />}
            title={t("homeOwner")}
          />
          <CardContent sx={{ margin: 0, padding: 0 }}>
            {filteredHouse !== null && (
              <InspectionLists>
                <ListItem>
                  <span> {t("contactName")}: </span> {filteredHouse?.contactName}
                </ListItem>
                <ListItem>
                  <span> {t("contactEmail")}: </span> {filteredHouse?.contactEmail}
                </ListItem>
                <ListItem>
                  <span> {t("contactPhone")}: </span> {filteredHouse?.contactPhone}
                </ListItem>
              </InspectionLists>
            )}
          </CardContent>
        </InspectionCardStyled>
      </Grid>
      <Grid item xs={12} sm={6}>
        <InspectionCardStyled>
          <CardHeader
            avatar={<ConstructionIcon sx={{ fontSize: 23 }} />}
            title={t("constructors")}
          />
          <CardContent sx={{ margin: 0, padding: 0 }}>
            <InspectionLists>
              <ListItem>
                <span>
                  {t("solar")} {t("contractor")}:
                </span>
                {getSupplier("solar")}
              </ListItem>
              <ListItem>
                <span>
                  {t("insulation")} {t("contractor")}:
                </span>
                {getSupplier("floor")}{" "}
                {/* contractors for all insulation categories (floor/wall/roof) should be the same, show only one */}
              </ListItem>
              <ListItem>
                <span>
                  {t("glass")} {t("contractor")} :
                </span>
                {getSupplier("glass")}
              </ListItem>
            </InspectionLists>
          </CardContent>
        </InspectionCardStyled>
      </Grid>
    </Grid>
  );
};
