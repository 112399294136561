import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { Box, Divider, ToggleButtonGroup, Typography } from "@mui/material";
import { FC, PropsWithChildren, memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NestedKeyOf, SummaryAgreement, SummaryData, SummaryElements } from "../../types";
import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";
import { Controller, useFormContext } from "react-hook-form";
import {
  StyledContainer,
  StyledToggleButton,
  SummaryHeaderContainer,
  Textarea,
} from "./SummaryCard.styled";
import { saveOwnerPreferences } from "src/store/slices";
import { useAppDispatch } from "src/store/hooks";

type HousePart = "wall" | "roof" | "solar" | "windows" | "floor";

export type SummarySchemaType = {
  windows?:
    | {
        reason?: string | undefined;
        preference: string;
      }[]
    | undefined;
  floor: {
    reason?: string | undefined;
    preference: string;
  };
  wall: {
    reason?: string | undefined;
    preference: string;
  };
  roof: {
    reason?: string | undefined;
    preference: string;
  };
  solar: {
    reason?: string | undefined;
    preference: string;
  };
};

type SummarySchemaKeys = NestedKeyOf<SummarySchemaType, keyof SummarySchemaType>;

export interface ISummaryCardProps {
  elementSummary: SummaryData;
  title: keyof typeof SummaryElements;
  agreement?: string;
}

type SummaryHeaderProps = {
  title: string;
  controllerName: Exclude<HousePart, "windows">;
};

type SummaryContentProps = {
  elementSummary: SummaryData;
  selectedOption: string | undefined;
  controllerName: Exclude<HousePart, "windows">;
};

const SummaryHeader: FC<PropsWithChildren<SummaryHeaderProps>> = ({ title, controllerName }) => {
  const [summaryTitleImage, setSummaryTitleImage] = useState();
  const form = useFormContext<Exclude<SummarySchemaType, "windows">>();
  const { control, trigger } = form;
  const { t } = useTranslation();
  useEffect(() => {
    const importImage = async () => {
      const image = await import(`../../assets/summaryImages/${title.toLowerCase()}.png`);
      setSummaryTitleImage(image.default);
    };
    importImage();
  }, [title]);
  return (
    <SummaryHeaderContainer>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <img src={summaryTitleImage} alt={title.toLowerCase()} width="55px" />
        <Typography variant="h5" component={"h2"} fontSize={"1.5rem !important"} ml={2}>
          {t(title.toLowerCase())}
        </Typography>
      </Box>
      <Controller
        name={(controllerName + ".preference") as SummarySchemaKeys}
        control={control}
        render={({ field: { onBlur, onChange, ref, value }, fieldState: { error } }) => {
          return (
            <ToggleButtonGroup
              className={`parent-${controllerName}`}
              onBlur={onBlur}
              onChange={(e, text) => {
                onChange(text);
                trigger();
              }}
              ref={ref}
              value={value}
              exclusive
              sx={{ gap: 2 }}
            >
              <StyledToggleButton
                value="accept"
                className="accept"
                selected={value === SummaryAgreement.ACCEPT}
                disabled={value === SummaryAgreement.ACCEPT}
                hasErr={!!error}
              >
                <CheckIcon />
              </StyledToggleButton>
              <StyledToggleButton
                value="doubt"
                className="doubt"
                selected={value === SummaryAgreement.DOUBT}
                disabled={value === SummaryAgreement.DOUBT}
                hasErr={!!error}
              >
                <QuestionMarkIcon />
              </StyledToggleButton>
              <StyledToggleButton
                value="decline"
                className="decline"
                selected={value === SummaryAgreement.DECLINE}
                disabled={value === SummaryAgreement.DECLINE}
                hasErr={!!error}
              >
                <CloseIcon />
              </StyledToggleButton>
            </ToggleButtonGroup>
          );
        }}
      />
    </SummaryHeaderContainer>
  );
};

const ReasonComponent = memo(
  ({ title, controllerName }: { title: string; controllerName: Exclude<HousePart, "windows"> }) => {
    const { t } = useTranslation();
    const { control, getValues } = useFormContext<SummarySchemaType>();
    const dispatch = useAppDispatch();

    return (
      <Box width={"100%"}>
        <Box display={"flex"} alignItems={"center"} mb={2} mt={4}>
          <FeedOutlinedIcon fontSize="small" sx={{ marginRight: "10px" }} />
          <Typography color={"#003257"} fontSize={"14px"} fontWeight={"600"}>
            {t("reasonFor") + " " + t(title)}
          </Typography>
        </Box>
        <Controller
          name={`${controllerName}.reason`}
          control={control}
          render={({ field: { name, value, ref, onChange, onBlur }, fieldState: { error } }) => {
            return (
              <Textarea
                inputRef={ref}
                name={name}
                value={value}
                onChange={onChange}
                ref={ref}
                onBlur={() => dispatch(saveOwnerPreferences(getValues()))}
                aria-label={`reason for ${title}`}
                placeholder="Type.."
                minRows="10"
                aria-multiline={false}
                multiline={true}
                sx={{ width: "100%" }}
                hasErr={!!error}
              />
            );
          }}
        />
      </Box>
    );
  },
);

const SummaryContent = memo(
  ({ elementSummary, selectedOption, controllerName }: SummaryContentProps) => {
    const { t } = useTranslation();

    return (
      <Box display={"flex"} flexDirection={"column"} p={4} width={"100%"}>
        <Box sx={{ display: "flex", flexDirection: "column", mb: 3 }}>
          {elementSummary.elements.map((el, index) =>
            el.panelCount ? (
              <Typography key={el.name + index} variant="body1">
                {`${t("elementName")}: `}
                <Typography component={"span"} variant="caption">
                  {`${el.name}, `}
                </Typography>
                {`${t("panelCount")}: `}
                <Typography component={"span"} variant="caption">
                  {el.panelCount}
                </Typography>
              </Typography>
            ) : el.soilInsulation?.name ? (
              <Typography key={el.name + index} variant="body1">
                {`${t("elementName")}: `}
                <Typography component={"span"} variant="caption">
                  {`${el.name}, `}
                </Typography>
                {`${t("insulation")}: `}
                <Typography component={"span"} variant="caption">
                  {`${el.insulation?.name}, `}
                </Typography>
                {`${t("soilInsulation")}: `}
                <Typography component={"span"} variant="caption">
                  {el.soilInsulation?.name}
                </Typography>
              </Typography>
            ) : el.insulation?.name ? (
              <Typography key={el.name + index} variant="body1">
                {`${t("elementName")}: `}
                <Typography component={"span"} variant="caption">
                  {`${el.name}, `}
                </Typography>
                {`${t("insulation")}: `}
                <Typography component={"span"} variant="caption">
                  {el.insulation?.name}
                </Typography>
              </Typography>
            ) : (
              <Typography component={"span"} key={el.name + index}>
                {t("noChanges")}
              </Typography>
            ),
          )}
          {elementSummary.atticFloor && (
            <Typography variant="body1" mt={2}>
              <Typography fontWeight={600} component={"span"}>
                {t("atticFloor") + ": "}
              </Typography>
              <Typography component={"span"} variant="caption">
                {t("atticFloorNewInsulation")}
              </Typography>
            </Typography>
          )}
        </Box>
        {elementSummary.ownerAgreements && (
          <>
            <Typography variant="body1" fontWeight={"600"}>
              {t("ownerAgreements") + ": "}
            </Typography>
            <Typography component={"span"} variant="caption">
              {elementSummary.ownerAgreements}
            </Typography>
          </>
        )}
        {selectedOption && selectedOption !== SummaryAgreement.ACCEPT ? (
          <ReasonComponent title={selectedOption || ""} controllerName={controllerName} />
        ) : null}
      </Box>
    );
  },
);

export const SummaryCard = memo(({ title, elementSummary }: ISummaryCardProps) => {
  const controllerName = SummaryElements[title];
  const { watch } = useFormContext<SummarySchemaType>();
  const selectedOption = watch(`${controllerName}.preference`);
  return (
    <StyledContainer
      sx={{
        borderColor: selectedOption === "accept" ? "#54C1F0" : "#D6D6D6",
      }}
    >
      <SummaryHeader title={title} controllerName={controllerName} />
      <Divider variant="fullWidth" sx={{ width: "100%" }} />
      <SummaryContent
        elementSummary={elementSummary}
        selectedOption={selectedOption}
        controllerName={controllerName}
      />
    </StyledContainer>
  );
});
