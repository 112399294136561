import { Stack, styled } from "@mui/material";

export const CoverStyled = styled(Stack)(({ theme }) => ({
  justifyContent: "space-between",
  backgroundColor: theme.palette.primary[700],
  padding: "24px",
  minHeight: "300px",
  width: "100%",
}));

export const CoverActionsStyled = styled(Stack)(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between"
}));