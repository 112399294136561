import { Stack } from "@mui/material";
import Sidebar from "../sidebar/Sidebar";
import { ScrollToTop } from "../common/ScrollToTop";

interface Props {
  isSidebarHidden: boolean;
  children: React.ReactNode;
}

export const Layout: React.FC<Props> = ({ children, isSidebarHidden }) => {
  return (
    <Stack direction="row" height="100%" minHeight={"100vh"} bgcolor="neutral.800">
      {!isSidebarHidden && <Sidebar /> }
      {children}
      <ScrollToTop />
    </Stack>
  );
};
