import { Box, styled } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { MainButton } from "../../components/common/Button/MainButton";
import { Card } from "../../components/common/Container/Containers";
import { MainPageWrapper } from "../../components/common/Container/MainPage";
import { PageWrapper } from "../../components/common/Container/PageWrapper";
import {
  DashboardElement,
  DashboardItemContainer,
} from "../../components/common/Dashboard/Dashboard";
import {
  EmptyContainer,
  SectionTitle,
} from "../../components/common/StepComponents/StepContainers";
import NetworkStatus from "../../components/NetworkStatus/NetworkStatus";
import { Path, useNavigation } from "../../routes";
import { useAppSelector } from "../../store/hooks";
import {
  getAtticDashboard,
  getCompletedInspection,
  getDashboardHasElements,
  getFloorsDashboard,
  getRoofDashboard,
  getSolarDashboard,
  getWallsDashboard,
} from "../../store/slices";
import {
  FloorIcon,
  GeneralInfo,
  HouseIcon,
  RoofIcon,
  SolarIcon,
} from "../../components/common/Icons";

import { getGeneralDashboard } from "../../store/slices";
import { SmallCircleBox } from "src/components/NetworkStatus/NetworkStatus.styles";
import Ajv from "ajv";
import schema from "../../schema.json";
import { toast } from "react-toastify";

// Needs to be here to avoid rendering issues
const DashboardCard = styled(Card)(() => ({
  padding: 0,
}));

const StyledDashboardItemContainer = styled(DashboardItemContainer)(({ theme }) => ({
  padding: theme.spacing(3),
  borderBottom: `2px solid ${theme.palette.border.main}`,
  ":last-of-type": {
    border: "none",
  },
  position: "relative",
}));

const SmallCircleBoxStyled = styled(SmallCircleBox)(() => ({
  position: "absolute",
  left: "64px",
  top: "42px",
}));

export const Dashboard: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const navigation = useNavigation();
  const hasElements = useAppSelector(getDashboardHasElements);
  const dashboardRoof = useAppSelector(getRoofDashboard(t));
  const dashboardAttic = useAppSelector(getAtticDashboard(t));
  const dashboardWalls = useAppSelector(getWallsDashboard(t));
  const dashboardFloors = useAppSelector(getFloorsDashboard(t));
  const dashboardSolar = useAppSelector(getSolarDashboard(t));
  const dashboardGeneral = useAppSelector(getGeneralDashboard(t));
  const inspection = useAppSelector(getCompletedInspection);
  const { id } = useParams();
  const ajv = new Ajv();
  const validate = ajv.compile(schema);

  const handleOnSummaryBtnClick = () => {
    const valid = validate(inspection);
    if (!valid) toast.info(`${t("invalidData")}, ${ajv.errorsText(validate.errors)}`);

    navigation(Path.SummaryPage, { id });
  };
  return (
    <MainPageWrapper>
      <Box px={7} mt={3} ml={"auto"}>
        <NetworkStatus />
      </Box>
      <PageWrapper>
        <SectionTitle title={t("houseStructure")} sx={{ mt: 5 }} />
        {!hasElements ? (
          <EmptyContainer title={t("noElementsAdded")} />
        ) : (
          <>
            <DashboardCard>
              {dashboardGeneral &&
                dashboardGeneral.map((houseEl) => (
                  <StyledDashboardItemContainer
                    key={houseEl.housePartName}
                    Icon={GeneralInfo}
                    text={houseEl.housePartName}
                    alt={houseEl.housePartName}
                  >
                    <SmallCircleBoxStyled
                      sx={{
                        backgroundColor:
                          houseEl.housePartsWithWindowGroup?.flatMap((item) => item.errors)
                            .length ||
                          houseEl.notes.ownerWindowNotes.filter((note) => !note.length).length
                            ? "#C90202"
                            : "#41F24F",
                        mr: "10px",
                      }}
                    />
                    {houseEl.housePartsWithWindowGroup &&
                      houseEl.housePartsWithWindowGroup?.map((part) => (
                        <DashboardElement
                          key={part.name}
                          title={part.name}
                          element={part.windowFrames}
                          parentErrorMessage={part.errors}
                          handleHousePartEdit={() => navigate(`${part.path}`)}
                        />
                      ))}
                    {houseEl.notes &&
                      houseEl.notes.ownerWindowNotes?.map((note, index) => (
                        <DashboardElement
                          key={`note-${index}`}
                          title={`${t("storey")} ${index}`}
                          parentErrorMessage={!note[index] ? ["notitie"] : []}
                          handleHousePartEdit={() =>
                            navigation(
                              Path.AddWindowGroupNotes,
                              { id, storeyId: index.toString() },
                              {
                                state: {
                                  windowSuggestionOwner: houseEl.notes.windowSuggestionOwner,
                                  windowSuggestionSupplier: houseEl.notes.windowSuggestionSupplier,
                                  ownerWindowNotes: houseEl.notes.ownerWindowNotes,
                                  supplierWindowNotes: houseEl.notes?.supplierWindowNotes,
                                },
                              },
                            )
                          }
                        />
                      ))}
                  </StyledDashboardItemContainer>
                ))}
              {dashboardRoof &&
                dashboardRoof.map((houseEl) => (
                  <StyledDashboardItemContainer
                    key={houseEl.housePartName}
                    Icon={RoofIcon}
                    text={houseEl.housePartName}
                    alt={houseEl.housePartName}
                  >
                    <SmallCircleBoxStyled
                      sx={{
                        backgroundColor: houseEl.errors?.length ? "#C90202" : "#41F24F",
                        mr: "10px",
                      }}
                    />
                    {houseEl.housePartsWithWindowGroup &&
                      houseEl.housePartsWithWindowGroup?.map((part) => (
                        <DashboardElement
                          key={part.name}
                          title={part.name}
                          element={part.windowFrames}
                          parentErrorMessage={part.errors}
                          handleHousePartEdit={() => navigate(`${part.path}`)}
                        />
                      ))}
                  </StyledDashboardItemContainer>
                ))}
              {dashboardAttic &&
                dashboardAttic.map((houseEl) => (
                  <StyledDashboardItemContainer
                    key={houseEl.housePartName}
                    Icon={FloorIcon}
                    text={houseEl.housePartName}
                    alt={houseEl.housePartName}
                  >
                    <SmallCircleBoxStyled
                      sx={{
                        backgroundColor: houseEl.housePartsWithWindowGroup?.flatMap(
                          (item) => item.errors,
                        ).length
                          ? "#C90202"
                          : "#41F24F",
                        mr: "10px",
                      }}
                    />
                    {houseEl.housePartsWithWindowGroup &&
                      houseEl.housePartsWithWindowGroup?.map((part) => (
                        <DashboardElement
                          key={part.name}
                          title={part.name}
                          element={part.windowFrames}
                          parentErrorMessage={part.errors}
                          handleHousePartEdit={() => navigate(`${part.path}`)}
                        />
                      ))}
                  </StyledDashboardItemContainer>
                ))}
              {dashboardWalls &&
                dashboardWalls.map((houseEl) => (
                  <StyledDashboardItemContainer
                    key={houseEl.housePartName}
                    Icon={HouseIcon}
                    text={houseEl.housePartName}
                    alt={houseEl.housePartName}
                  >
                    <SmallCircleBoxStyled
                      sx={{
                        backgroundColor: houseEl.errors?.length ? "#C90202" : "#41F24F",
                        mr: "10px",
                      }}
                    />
                    {houseEl.generalWallData && (
                      <DashboardElement
                        key={houseEl.generalWallData.name}
                        title={houseEl.generalWallData.name}
                        element={houseEl.generalWallData.windowFrames}
                        parentErrorMessage={houseEl.generalWallData.errors}
                        handleHousePartEdit={() => navigate(`${houseEl.generalWallData.path}`)}
                      />
                    )}
                    {houseEl.housePartsWithWindowGroup &&
                      houseEl.housePartsWithWindowGroup?.map((part) => (
                        <DashboardElement
                          key={part.name}
                          title={part.name}
                          element={part.windowFrames}
                          parentErrorMessage={part.errors}
                          handleHousePartEdit={() => navigate(`${part.path}`)}
                        />
                      ))}
                  </StyledDashboardItemContainer>
                ))}
              {dashboardFloors &&
                dashboardFloors.map((houseEl) => (
                  <StyledDashboardItemContainer
                    key={houseEl.housePartName}
                    Icon={FloorIcon}
                    text={houseEl.housePartName}
                    alt={houseEl.housePartName}
                  >
                    <SmallCircleBoxStyled
                      sx={{
                        backgroundColor: houseEl.housePartsWithWindowGroup?.flatMap(
                          (item) => item.errors,
                        ).length
                          ? "#C90202"
                          : "#41F24F",
                        mr: "10px",
                      }}
                    />
                    {houseEl.housePartsWithWindowGroup &&
                      houseEl.housePartsWithWindowGroup?.map((part) => (
                        <DashboardElement
                          key={part.name}
                          title={part.name}
                          element={part.windowFrames}
                          parentErrorMessage={part.errors}
                          handleHousePartEdit={() => navigate(`${part.path}`)}
                        />
                      ))}
                  </StyledDashboardItemContainer>
                ))}
              {dashboardSolar &&
                dashboardSolar.map((houseEl) => (
                  <StyledDashboardItemContainer
                    key={houseEl.housePartName}
                    Icon={SolarIcon}
                    text={houseEl.housePartName}
                    alt={houseEl.housePartName}
                  >
                    <SmallCircleBoxStyled
                      sx={{
                        backgroundColor:
                          houseEl.housePartsWithWindowGroup?.flatMap((item) => item.errors)
                            .length || houseEl.generalSolarData.errors?.length
                            ? "#C90202"
                            : "#41F24F",
                        mr: "10px",
                      }}
                    />
                    {houseEl.generalSolarData ? (
                      <DashboardElement
                        key={houseEl.generalSolarData.name}
                        title={houseEl.generalSolarData.name}
                        element={[]}
                        parentErrorMessage={houseEl.generalSolarData.errors}
                        handleHousePartEdit={() => navigate(`${houseEl.generalSolarData.path}`)}
                      />
                    ) : null}
                    {houseEl.housePartsWithWindowGroup &&
                      houseEl.housePartsWithWindowGroup?.map((part) => (
                        <DashboardElement
                          key={part.name}
                          title={part.name}
                          element={part.windowFrames}
                          parentErrorMessage={part.errors}
                          handleHousePartEdit={() => navigate(`${part.path}`)}
                        />
                      ))}
                  </StyledDashboardItemContainer>
                ))}
            </DashboardCard>
            <MainButton
              text={t("toSummary")}
              variant="primary"
              sx={{ my: 3, width: "100%" }}
              handleClick={handleOnSummaryBtnClick}
            />
          </>
        )}
      </PageWrapper>
    </MainPageWrapper>
  );
};
