import { Box, Grid, Typography } from "@mui/material";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FormSelect } from "../../components/common/Form/FormSelect";
import { FormStack } from "../../components/common/Form/FormStack";
import { FormInput } from "../../components/common/Form/FormInput";
import { Form } from "../../components/common/Form/Form";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { getSolarArraySchema } from "../../types/schema";
import { Cover } from "../../components/common/Cover/Cover";
import { MainPageWrapper } from "../../components/common/Container/MainPage";
import { PageWrapper } from "../../components/common/Container/PageWrapper";
import { Card } from "../../components/common/Container/Containers";
import { Photos } from "../../components/photos/Photos";
import { useDispatch } from "react-redux";
import {
  getSolarByMeasure,
  saveSolarPanel,
  deleteSolar,
  getCategorySpecificProducts,
  getSolar,
} from "../../store/slices/";
import { MainButton } from "../../components/common/Button/MainButton";
import { getFilteredImageTags } from "../../store/slices";
import { RoofingType, Direction, Category } from "../../types";
import { useAppSelector } from "../../store/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { useConfirm } from "../../components/common/Dialog/ConfirmDialog";
import isNameUnique from "../../utils/helpers/nameChecker";
import { toast } from "react-toastify";
import stringToNumber from "../../utils/helpers/strToNum";
import { updateHouseImageRequestsWithNewName } from "src/utils/updateHouseImageRequestsWithNewName";
import { deleteHouseImageRequestFromLocalStorageForHousePart } from "src/utils/deleteHouseImageRequestFromLocalStorageForHousePart";
import { getTrimmedName } from "src/utils/helpers/getTrimmedName";

const SOLAR_UNKNOWN_NAME = "solar_array_unknown";

export const AddSolarArray: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const confirm = useConfirm();
  const tags = useAppSelector(getFilteredImageTags(Category.SOLAR_MEASURE));
  const { id, solarId } = useParams();
  const solar = useAppSelector(getSolarByMeasure(solarId || ""));
  const dispatch = useDispatch();
  const solarProducts = useAppSelector(getCategorySpecificProducts(Category.SOLAR));
  const { solarArr } = useAppSelector(getSolar);

  const formDefaultValues = {
    ...solar,
    panelType: solar?.solarProduct === null ? "geen" : solar?.solarProduct.name || "",
    panelCount: solar?.panelCount,
    panelDirection: solar?.panelDirection,
    roofingType: solar?.roofingType,
    initialSolarName: solar?.solarProduct?.name,
    images: solar?.images || [],
  };

  const form = useForm<any>({
    resolver: yupResolver(getSolarArraySchema(t)),
    mode: "all",
    defaultValues: formDefaultValues,
  });

  const {
    register,
    formState: { errors },
    trigger,
    getValues,
    watch,
  } = form;

  const name = getTrimmedName(watch("name"));

  const handleSave = async () => {
    await trigger();

    const { panelType } = form.formState.errors;

    if (panelType && "message" in panelType) {
      toast.error(t("mandatoryField"));
      form.setFocus("panelType");
      return;
    }

    if (name !== solarId && isNameUnique(solarArr, name)) {
      toast.error(t("uniqueName"));
      form.setFocus("name");
      return;
    }

    const selectedPanelName = getValues("panelType");
    const selectedPanelProduct = solarProducts.find((el) => el.name === selectedPanelName);

    dispatch(
      saveSolarPanel({
        ...form.getValues(),
        name,
        panelTypeId: selectedPanelProduct?.id || "",
        errors: Object.keys(form.formState.errors),
        solarId,
      }),
    );

    // Determine the name of the element, defaulting to  "solar_array_unknown" if  'solar.name' is undefined
    const previousSolarName = solar?.name ?? SOLAR_UNKNOWN_NAME;
    // Update image requests in local storage where elementName mathches with the new 'previousSolarName'
    updateHouseImageRequestsWithNewName(id ?? "", previousSolarName, name);

    navigate(-1);
  };

  const onDeleteSolarArray = async () => {
    const choice = await confirm({
      title: t("delete"),
      description: t("deleteSolar"),
      confirmBtnLabel: t("delete"),
    });

    if (choice) {
      navigate(-1);
      dispatch(deleteSolar({ solarId }));

      // Determine the name of the element, defaulting to  "solar_array_unknown" if  'solar.name' is undefined
      const solarName = solar?.name ?? SOLAR_UNKNOWN_NAME;
      // Remove image requests from local storage for the deleted solar element if  exist
      deleteHouseImageRequestFromLocalStorageForHousePart(solarName, id ?? "");
    }
  };

  useEffect(() => {
    trigger();
  }, [trigger]);

  return (
    <MainPageWrapper>
      <Cover
        title={solarId ? t("editSolar") : t("addSolar")}
        showBackButton={true}
        handleDelete={solarId ? onDeleteSolarArray : undefined}
      />
      <PageWrapper>
        <Form onSubmit={form.handleSubmit(handleSave, handleSave)}>
          <Box py={6}>
            <Card>
              <Box p={2}>
                <Box height={40} display="flex" alignItems="center" mb={2}>
                  <Typography variant="h2" fontWeight={600}>
                    {t("solarArray")}
                  </Typography>
                </Box>

                <FormStack>
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={6}>
                      <FormInput label={t("name")} {...register("name")} error={errors.name} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormInput
                        label={t("panelCount")}
                        {...register("panelCount", { setValueAs: (v) => stringToNumber(v) })}
                        error={errors.panelCount}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormSelect
                        options={Object.values(Direction)}
                        label={t("orientation")}
                        error={errors.panelDirection}
                        name="panelDirection"
                        form={form}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {solarProducts?.length > 0 && (
                        <FormSelect
                          form={form}
                          {...register("panelType")}
                          options={solarProducts.map((el) => el.name)}
                          label={t("panelType")}
                          error={errors.panelType}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormSelect
                        label={t("roofingType")}
                        options={Object.values(RoofingType)}
                        error={errors.roofingType}
                        name="roofingType"
                        form={form}
                      />
                    </Grid>
                  </Grid>
                </FormStack>
              </Box>
            </Card>
          </Box>

          <Photos
            tags={tags}
            sphId={id || ""}
            form={form}
            housePartElement="solarArray"
            elementName={solar?.name ?? SOLAR_UNKNOWN_NAME}
          />

          <MainButton text={t("save")} variant="primary" type="submit" sx={{ margin: "30px 0" }} />
        </Form>
      </PageWrapper>
    </MainPageWrapper>
  );
};
