import { useNavigate } from "react-router-dom";
import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Typography } from "@mui/material/";
import { useAuthentication } from "../../context/useAuthenticationContext";
import { api } from "../../API/axios";
import { Card } from "../../components/common/Container/Containers";
import { DarkBlueBackground } from "../../styles/DarkBlueBg";
import { MainButton } from "../../components/common/Button/MainButton";
import { FormInput } from "../../components/common/Form/FormInput";
import { FormStack } from "../../components/common/Form/FormStack";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useTranslation } from "react-i18next";

interface ILoginForm {
  username: string;
  password: string;
}

export interface ILoginUserResponse {
  username: string;
  token: string;
}

export const validationSchema = yup.object().shape({
  username: yup.string().required(),
  password: yup.string().required(),
});

export const Login: FC = () => {
  const { t } = useTranslation();
  const [validationError, setValidationError] = useState<string>("");
  const [showPassword, setShowPassword] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    watch,
  } = useForm<ILoginForm>({
    resolver: yupResolver(validationSchema),
  });
  const navigate = useNavigate();

  const { signIn } = useAuthentication();
  const username = watch("username");
  const password = watch("password");

  const handleClickShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const LoginUser = async () => {
    const user = {
      username: getValues("username"),
      password: getValues("password"),
    };

    try {
      const response = await api.post<ILoginUserResponse | undefined>("/login", user);

      if (response.data?.username && response.data?.token) {
        signIn(response.data.username, response.data.token);
        navigate("/houses", { replace: true });
      }
    } catch (error) {
      setValidationError((error as { response: { data: any } }).response?.data?.message);
    }
  };

  return (
    <DarkBlueBackground>
      <Box
        sx={{
          display: "flex",
          height: "100vh",
          width: "100vw",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Card>
          <Box p={3}>
            <form onSubmit={handleSubmit(LoginUser)}>
              <FormStack sx={{ minWidth: "350px" }}>
                <Typography variant="h1" mb={5}>
                  {t("signIn")}
                </Typography>
                <FormInput
                  {...register("username")}
                  label={t("username")}
                  error={errors.username}
                  InputLabelProps={{ shrink: username ? true : false }}
                />
                <FormInput
                  sx={{ "&.MuiFormControl-root": { marginTop: "40px" } }}
                  type={showPassword ? "text" : "password"}
                  {...register("password")}
                  label={t("password")}
                  error={errors.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{ shrink: password ? true : false }}
                />
              </FormStack>
              {validationError && <Box mt={2}>{validationError}</Box>}
              <Box mt={6}>
                <MainButton text={t("signIn")} type="submit" variant="primary" />
              </Box>
            </form>
          </Box>
        </Card>
      </Box>
    </DarkBlueBackground>
  );
};
