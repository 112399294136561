import { IImage, IProduct } from ".";

export enum FloorType {
  MANTA = "manta",
  SANDWICH = "broodjes",
  GIBBERISH = "kwaaitaal",
  NEHOBO = "NeHoBo",
  WOOD = "hout",
  RIBBED = "ribcassete",
  CHANNEL_PLATE = "kanaalplaat",
}

export enum InsulationMaterial {
  NONE = "geen",
  UNKNOWN = "onbekend",
  EPS = "EPS",
  MINERAL_WOOL = "minerale wol",
  POLYURETHANE_FOAM_SPRAY = "PU spray",
  REFLECTIVE_FOIL = "reflecterende folie",
}

export enum SoilInsulationMaterial {
  NONE = "geen",
  UNKNOWN = "onbekend",
  EPS = "EPS",
  INSULATION_CHIPS = "isolatiechips",
  GROUND_COVER = "bodemafdekker",
  OTHER = "overig",
}

export enum Crawlspace {
  INACCESSIBLE = "ontoegankelijk",
  ACCESSIBLE = "toegankelijk",
}

export enum CrawlspaceHumidity {
  DRY = "droog",
  DAMP = "vochtig",
  WATER = "water",
}

export interface IFloor {
  name: string;
  notes: string;
  constructionYear: number;
  area: number;
  floorType: FloorType;
  insulationMaterial: InsulationMaterial;
  insulationThickness: number;
  soilInsulationMaterial: SoilInsulationMaterial;
  soilInsulationThickness: number;
  crawlspace: Crawlspace;
  crawlspaceHeight: number;
  crawlspaceHumidity: CrawlspaceHumidity;
  compartmentCount: number;
  images: IImage[];
  errors?: string[];

  measure: {
    floorInsulationProduct: IProduct | null;
    floorInsulationThickness: number;
    soilInsulationProduct: IProduct | null;
    soilInsulationThickness: number;
    soilWallSeparationLength: number;
  };
}
