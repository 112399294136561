import { Box, Stack, styled } from "@mui/material";

export const DividerStack = styled(Stack)(({ theme }) => ({
  "> div": {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
}));

export const CenteredEmptyBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  padding: theme.spacing(3),
  border: `1px solid ${theme.palette.border.main}`,
  borderRadius: "10px",
}));

export const IconEmptyBox = styled(Box)(({theme}) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  padding: theme.spacing(3),
  border: `1px solid ${theme.palette.border.main}`,
  borderRadius: "10px",
}));