import { Checkbox } from "@mui/material";
import { styled } from "@mui/system";

export const StyledCheckbox = styled(Checkbox)(() => ({
  color: "#54C1F0",
  fontWeight: "400",
  "&.Mui-checked": {
    color: "#54C1F0",
    fontWeight: "500"
  },
}));
