import { yupResolver } from "@hookform/resolvers/yup";
import { Box, FormControlLabel } from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import type { InferType } from "yup";
import SolarIcon from "../../assets/icons/solar_power.svg";
import { MainButton } from "../../components/common/Button/MainButton";
import { Card, CardSection, EmptyContainer } from "../../components/common/Container/Containers";
import { MainPageWrapper } from "../../components/common/Container/MainPage";
import { PageWrapper } from "../../components/common/Container/PageWrapper";
import { Cover } from "../../components/common/Cover/Cover";
import { Form } from "../../components/common/Form/Form";
import { MapIcon, PlusIcon } from "../../components/common/Icons";
import {
  AdditionalData,
  AdditionalDataWrapper,
} from "../../components/common/StepComponents/AdditionalData";
import { SectionTitle } from "../../components/common/StepComponents/StepContainers";
import { convertBlobToBase64, Photos } from "../../components/photos/Photos";
import { Path, useNavigation } from "../../routes";
import { useAppSelector } from "../../store/hooks";
import {
  duplicateElement,
  getFilteredImageTags,
  getFilteredSuggestions,
  getNotesData,
  getSolar,
  getSolarCount,
  getSolarObject,
  saveSolarPage,
} from "../../store/slices";
import {
  Category,
  MainFuseBoxLocation,
  PhaseConnection,
  SuggestedCableRoute,
  SuggestionCategory,
} from "../../types";
import { getSolarSchema } from "../../types/schema";
import { toast } from "react-toastify";
import { fetchOrientationImage } from "../../store/thunks";
import { PhotoModal } from "../../components/photos/Modal";
import { FormSelect } from "../../components/common/Form/FormSelect";
import { FormStack } from "../../components/common/Form/FormStack";
import { getSolarCheckboxesValues } from "src/utils/helpers/getSolarCheckboxesValues";
import { StyledCheckbox } from "src/components/common/StepComponents/Checkbox.styled";

export const Solar: FC = () => {
  const [orientationImage, setOrientationImage] = useState<string>("");
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigation();
  const notes = useAppSelector(getNotesData);
  const dispatch = useDispatch();
  const { id } = useParams();
  const sphId = id ? id : "";

  const solarArrayCount = useAppSelector(getSolarCount);

  const solarSuggestionsOwner = useAppSelector(
    getFilteredSuggestions(SuggestionCategory.OWNER_SOLAR),
  );
  const solarSuggestionExecutive = useAppSelector(
    getFilteredSuggestions(SuggestionCategory.EXECUTIVE_SOLAR),
  );
  const { solarArr } = useAppSelector(getSolar);
  const solar = useAppSelector(getSolarObject);

  const solarSchema = getSolarSchema(t, solarArrayCount);
  type SolarSchemaForm = InferType<typeof solarSchema>;

  const form = useForm<SolarSchemaForm>({
    mode: "all",
    resolver: yupResolver(solarSchema),
    defaultValues: {
      ownerSolar: notes?.ownerSolar || "",
      supplierSolar: notes?.supplierSolar || "",
      currentPhaseConnection: solar?.currentPhaseConnection,
      requiredPhaseConnection: solar?.requiredPhaseConnection,
      mainFuseBoxNeedsReplacement: solar?.mainFuseBoxNeedsReplacement || false,
      mainFuseBoxLocation: solar?.mainFuseBoxLocation,
      hasFuseBoxInGarage: solar?.hasFuseBoxInGarage || false,
      suggestedCableRoute: solar?.suggestedCableRoute,
      images: solar?.images || [],
      measures: solar?.measures || [],
    },
  });
  const {
    formState: { errors },
    handleSubmit,
    trigger,
    watch,
    setValue,
    control,
  } = form;

  const measures = watch("measures");
  const currentPhaseConnection = watch("currentPhaseConnection");
  const requiredPhaseConnection = watch("requiredPhaseConnection");
  const mainFuseBoxLocation = watch("mainFuseBoxLocation");
  const suggestedCableRoute = watch("suggestedCableRoute");

  useEffect(() => {
    trigger();
  }, [trigger]);

  const getOrientationImage = useCallback(async () => {
    const imageName = process.env.REACT_APP_ROOFPLAN_IMAGE || "roofplan.jpeg";
    const data = await fetchOrientationImage(+sphId, imageName);
    const blobToImg: string = data ? ((await convertBlobToBase64(data.file)) as string) : "";
    if (data) setOrientationImage(blobToImg);
  }, [sphId]);

  const showOrientationImage = () => setIsOpen(!isOpen);

  useEffect(() => {
    (async () => await getOrientationImage())();
  }, []);

  useEffect(() => {
    if (measures?.length) {
      if (currentPhaseConnection === PhaseConnection.UNKNOWN)
        setValue("currentPhaseConnection", "");

      if (requiredPhaseConnection === PhaseConnection.UNKNOWN)
        setValue("requiredPhaseConnection", "");
    }
  }, [measures?.length, setValue, currentPhaseConnection, requiredPhaseConnection]);

  const handleSave = async () => {
    await form.trigger();
    if ("ownerSolar" in form.formState.errors) {
      toast.error(t("mandatoryField"));
      form.setFocus("ownerSolar");
      return;
    }

    const solarData = { ...form.getValues() };
    const solarCheckboxValues = getSolarCheckboxesValues(
      measures ?? [],
      solarData.currentPhaseConnection,
      solarData.requiredPhaseConnection,
      solarData.mainFuseBoxLocation,
      solarData.suggestedCableRoute,
    );

    dispatch(
      saveSolarPage({
        ...solarData,
        currentPhaseConnection: solarCheckboxValues.currentPhaseConnection,
        requiredPhaseConnection: solarCheckboxValues.requiredPhaseConnection,
        mainFuseBoxLocation: solarCheckboxValues.mainFuseBoxLocation,
        suggestedCableRoute: solarCheckboxValues.suggestedCableRoute,
        errors: Object.keys(form.formState.errors),
      }),
    );
    navigate(`../${Path.Dashboard}`);
  };

  const handleDuplicate = (solarArrayName: string) =>
    dispatch(duplicateElement({ name: solarArrayName, housePart: "solarArray" }));

  const tags = useAppSelector(getFilteredImageTags(Category.SOLAR));

  const getPhaseOptions = () => {
    return measures?.length
      ? Object.values(PhaseConnection).filter((value) => value !== PhaseConnection.UNKNOWN)
      : Object.values(PhaseConnection);
  };
  return (
    <MainPageWrapper>
      <Cover title={t("solarPanels")} />
      <PageWrapper buttonText={t("save") || ""}>
        <SectionTitle title={t("solarArray")} sx={{ my: 5 }}>
          <Box>
            <MainButton
              variant="white"
              icon={<MapIcon height="15px" width="15px" />}
              text={t("viewRoofplan")}
              onClick={showOrientationImage}
              sx={{ marginRight: 2, color: "primary" }}
            />
            <MainButton
              text={t("add")}
              icon={<PlusIcon color="white" width="10px"></PlusIcon>}
              variant="secondary"
              onClick={() => navigate(Path.AddSolarArray)}
            />
          </Box>
        </SectionTitle>
        {solarArr && solarArr.length === 0 ? (
          <EmptyContainer title={t("noSolarAdded")} />
        ) : (
          <Card>
            {solarArr.map((item) => {
              return (
                <CardSection
                  key={item.name}
                  title={item.name}
                  errors={item.errors || []}
                  icon={SolarIcon}
                  panels={item.panelCount}
                  path={"/"}
                  direction={item.panelDirection}
                  mb={2}
                  handleClick={() => navigate(Path.AddSolarArray, { solarId: item.name })}
                  handleDuplicate={() => handleDuplicate(item?.name)}
                />
              );
            })}
          </Card>
        )}
        <Card>
          <SectionTitle title={t("connection")} mb={3} />
          <FormStack>
            <Controller
              control={control}
              name={"mainFuseBoxNeedsReplacement"}
              defaultValue={form.getValues("mainFuseBoxNeedsReplacement")}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  sx={{ margin: 0 }}
                  label={t("mainFuseBoxNeedsReplacement")}
                  control={<StyledCheckbox checked={value || false} onChange={onChange} />}
                />
              )}
            />
            <Controller
              control={control}
              name={"hasFuseBoxInGarage"}
              defaultValue={form.getValues("hasFuseBoxInGarage")}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  sx={{ margin: "0!important" }}
                  label={t("hasFuseBoxInGarage")}
                  control={<StyledCheckbox checked={value || false} onChange={onChange} />}
                />
              )}
            />
            <FormStack direction={"row"}>
              <FormSelect
                form={form}
                name={"mainFuseBoxLocation"}
                label={t("mainFuseBoxLocation")}
                options={Object.values(MainFuseBoxLocation)}
                defaultValue={mainFuseBoxLocation}
                error={errors?.mainFuseBoxLocation}
              />
              <FormSelect
                form={form}
                name={"suggestedCableRoute"}
                label={t("suggestedCableRoute")}
                options={Object.values(SuggestedCableRoute)}
                defaultValue={suggestedCableRoute}
                error={errors?.suggestedCableRoute}
              />
            </FormStack>
            <FormStack direction={"row"}>
              <FormSelect
                form={form}
                name={"currentPhaseConnection"}
                label={t("currentPhaseConnection")}
                options={getPhaseOptions()}
                defaultValue={currentPhaseConnection}
                error={errors?.currentPhaseConnection}
              />
              <FormSelect
                form={form}
                name={"requiredPhaseConnection"}
                label={t("requiredPhaseConnection")}
                options={getPhaseOptions()}
                defaultValue={requiredPhaseConnection}
                error={errors?.requiredPhaseConnection}
              />
            </FormStack>
          </FormStack>
        </Card>

        <Photos
          tags={tags}
          sphId={id || ""}
          form={form}
          isWindowsPage={!measures?.length}
          housePartElement="solar"
        />

        <Form onSubmit={handleSubmit(handleSave, handleSave)}>
          <AdditionalDataWrapper title={t("ownerAgreements")}>
            <AdditionalData
              name="ownerSolar"
              form={form}
              suggestions={solarSuggestionsOwner}
              error={errors.ownerSolar}
            />
          </AdditionalDataWrapper>
          <AdditionalDataWrapper title={t("contractorAgreements")}>
            <AdditionalData
              name="supplierSolar"
              form={form}
              suggestions={solarSuggestionExecutive}
              error={errors.supplierSolar}
            />
          </AdditionalDataWrapper>
          <MainButton text={t("save")} variant="primary" type="submit" sx={{ margin: "30px 0" }} />
        </Form>
        <PhotoModal
          imageSrc={orientationImage}
          isOpen={isOpen}
          handlePhotoModal={showOrientationImage}
        />
      </PageWrapper>
    </MainPageWrapper>
  );
};
