import { yupResolver } from "@hookform/resolvers/yup";
import EditIcon from "@mui/icons-material/Edit";
import { Box, FormControlLabel, InputAdornment, MenuItem, Stack, TextField } from "@mui/material";
import { ChangeEvent, ChangeEventHandler, FC, useCallback, useEffect, useState } from "react";
import { Controller, UseFormReturn, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { MainButton } from "../../components/common/Button/MainButton";
import { Card } from "../../components/common/Container/Containers";
import { MainPageWrapper } from "../../components/common/Container/MainPage";
import { PageWrapper } from "../../components/common/Container/PageWrapper";
import { TwoColumnsContainer } from "../../components/common/Container/TwoColumnsContainer";
import { Cover } from "../../components/common/Cover/Cover";
import { useConfirm } from "../../components/common/Dialog/ConfirmDialog";
import { Form } from "../../components/common/Form/Form";
import { FormInput } from "../../components/common/Form/FormInput";
import { FormSelect } from "../../components/common/Form/FormSelect";
import { FormStack } from "../../components/common/Form/FormStack";
import {
  AdditionalData,
  AdditionalDataWrapper,
} from "../../components/common/StepComponents/AdditionalData";
import { StyledCheckbox } from "../../components/common/StepComponents/Checkbox.styled";
import { SectionTitle } from "../../components/common/StepComponents/StepContainers";
import { SurfaceArea } from "../../components/common/SurfaceArea";
import { PhotoModal } from "../../components/photos/Modal";
import { Photos, convertBlobToBase64 } from "../../components/photos/Photos";
import { useAppSelector } from "../../store/hooks";
import {
  deleteWall,
  getBuildingConstructionYear,
  getCategorySpecificProducts,
  getFilteredImageTags,
  getFilteredSuggestions,
  getWallByName,
  getWallsData,
  saveWall,
} from "../../store/slices";
import { fetchOrientationImage } from "../../store/thunks";
import {
  Category,
  CavityPolution,
  Condition,
  Direction,
  IWall,
  JointType,
  SuggestionCategory,
  WallInsulationMaterial,
  brickType,
} from "../../types";
import { getWallElementSchema } from "../../types/schema";
import isNameUnique from "../../utils/helpers/nameChecker";
import stringToNumber from "../../utils/helpers/strToNum";
import { updateHouseImageRequestsWithNewName } from "src/utils/updateHouseImageRequestsWithNewName";
import { deleteHouseImageRequestFromLocalStorageForHousePart } from "src/utils/deleteHouseImageRequestFromLocalStorageForHousePart";
import { getTrimmedName } from "src/utils/helpers/getTrimmedName";

const WALL_NAME_UNKNOWX = "wall_unknown";
interface SituationProps {
  form: UseFormReturn<IWall>;
  handleExtraThickness?: ((event: any) => void) | undefined;
  showOrientationImage?: () => void | undefined;
}

const CurrentSituation: FC<SituationProps> = ({
  form,
  handleExtraThickness,
  showOrientationImage,
}) => {
  const { t } = useTranslation();
  const [showSurface, setShowSurface] = useState(false);
  const {
    control,
    register,
    formState: { errors },
    watch,
    setValue,
    clearErrors,
  } = form;

  const insulationMaterial = watch("insulationMaterial");

  const handleSelectInsulationMaterial = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { value } = event.target;
    setValue("insulationMaterial", value as WallInsulationMaterial);
    clearErrors("insulationMaterial");
    if (value === "geen") {
      setValue("insulationThickness", 0);
      clearErrors("insulationThickness");
    }
  };
  return (
    <Card>
      <FormStack>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} pb={1}>
          <SectionTitle title={t("currentSituation")} />
          <MainButton
            variant="white"
            text={t("viewOrientation")}
            onClick={showOrientationImage}
          ></MainButton>
        </Box>
        <FormInput {...register("name")} label={t("name")} error={errors?.name} />
        <FormInput
          {...register("constructionYear", { valueAsNumber: true })}
          label={t("year")}
          error={errors?.constructionYear}
        />
        <FormSelect
          form={form}
          name={"direction"}
          label={t("direction")}
          options={Object.values(Direction)}
          error={errors?.direction}
        />
        <Box>
          <FormInput
            {...register("area", { setValueAs: (v) => stringToNumber(v) })}
            label={t("surfaceArea")}
            sx={{ ".MuiInputLabel-shrink": { left: "0" } }}
            error={errors.area}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <MainButton
                    variant="white"
                    text={t("edit")}
                    icon={<EditIcon fontSize="small" sx={{ display: "flex" }} />}
                    onClick={() => setShowSurface(!showSurface)}
                  />
                </InputAdornment>
              ),
            }}
          />
          {showSurface && <SurfaceArea form={form} />}
        </Box>
        <FormInput
          {...register("cavityWidth", {
            setValueAs: (v) => stringToNumber(v),
            onChange: handleExtraThickness,
          })}
          label={t("cavityWidth")}
          error={errors?.cavityWidth}
        />
        <FormSelect
          form={form}
          name={"cavityPollution"}
          label={t("cavityPollution")}
          options={Object.values(CavityPolution)}
          error={errors?.cavityPollution}
        />
        <FormInput
          {...register("doorCount", { valueAsNumber: true })}
          label={t("doorCount")}
          error={errors?.doorCount}
        />
        <FormInput
          {...register("windLoadedLength", { setValueAs: (v) => stringToNumber(v) })}
          label={t("windLoadedLength")}
          error={errors?.windLoadedLength}
        />
        {/* passing custom onChange function for it to work => not using FormSelect, investigate further */}
        <Controller
          name={"insulationMaterial"}
          control={form.control}
          render={({ field: { ref, value } }) => (
            <TextField
              label={t("insulationMaterial")}
              variant="outlined"
              error={!!form.getFieldState("insulationMaterial").error?.message}
              ref={ref}
              onChange={handleSelectInsulationMaterial}
              value={value || ""}
              helperText={form.getFieldState("insulationMaterial").error?.message}
              select
              fullWidth
            >
              {Object.values(WallInsulationMaterial).map((option) => (
                <MenuItem key={option} value={option} sx={{ fontSize: "25px" }}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
        <FormInput
          {...register("insulationThickness", {
            setValueAs: (v) => stringToNumber(v),
            onChange: handleExtraThickness,
          })}
          label={t("insulationThickness")}
          error={errors?.insulationThickness}
          disabled={insulationMaterial === undefined}
        />
        <FormSelect
          form={form}
          name={"wallCondition"}
          label={t("wallCondition")}
          options={Object.values(Condition)}
          error={errors?.wallCondition}
        />
        <FormSelect
          form={form}
          name={"brickType"}
          label={t("brickType")}
          options={Object.values(brickType)}
          error={errors?.brickType}
        />
        <FormSelect
          form={form}
          name={"jointType"}
          label={t("jointType")}
          options={Object.values(JointType)}
          error={errors?.jointType}
        />
        <FormSelect
          form={form}
          name={"jointCondition"}
          label={t("jointCondition")}
          options={Object.values(Condition)}
          error={errors?.jointCondition}
        />
        <Controller
          control={control}
          name={"hasExpansionJoint"}
          defaultValue={form.getValues("hasExpansionJoint")}
          render={({ field: { onChange, value } }) => (
            <FormControlLabel
              label={t("hasExpansionJoint")}
              control={<StyledCheckbox checked={value || false} onChange={onChange} />}
            />
          )}
        />
        <Controller
          control={control}
          name={"hasCrack"}
          defaultValue={form.getValues("hasCrack")}
          render={({ field: { onChange, value } }) => (
            <FormControlLabel
              label={t("hasCrack")}
              control={<StyledCheckbox checked={value || false} onChange={onChange} />}
            />
          )}
        />
        <Controller
          control={control}
          name={"hasVaporBarier"}
          defaultValue={form.getValues("hasVaporBarier")}
          render={({ field: { onChange, value } }) => (
            <FormControlLabel
              label={t("hasVaporBarier")}
              control={<StyledCheckbox checked={value || false} onChange={onChange} />}
            />
          )}
        />
        <Controller
          control={control}
          name={"hasVegetation"}
          defaultValue={form.getValues("hasVegetation")}
          render={({ field: { onChange, value } }) => (
            <FormControlLabel
              label={t("hasVegetation")}
              control={<StyledCheckbox checked={value || false} onChange={onChange} />}
            />
          )}
        />
        <Controller
          control={control}
          name={"hasMoistureProblem"}
          defaultValue={form.getValues("hasMoistureProblem")}
          render={({ field: { onChange, value } }) => (
            <FormControlLabel
              label={t("hasMoistureProblem")}
              control={<StyledCheckbox checked={value || false} onChange={onChange} />}
            />
          )}
        />
        <Controller
          control={control}
          name={"hasHoles"}
          defaultValue={form.getValues("hasHoles")}
          render={({ field: { onChange, value } }) => (
            <FormControlLabel
              label={t("hasHoles")}
              control={<StyledCheckbox checked={value || false} onChange={onChange} />}
            />
          )}
        />
      </FormStack>
    </Card>
  );
};

interface NewSituationProps extends SituationProps {
  insulationProducts: { id: string; name: string }[];
  handleSelectChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
}
const NewSituation: FC<NewSituationProps> = ({ form, insulationProducts, handleSelectChange }) => {
  const {
    register,
    watch,
    formState: { errors },
  } = form;
  const { t } = useTranslation();
  const extraInsulationProduct = watch("measure.insulationProduct.name");

  return (
    <Card height="99%">
      <FormStack>
        <SectionTitle title={t("newSituation")} sx={{ height: 40 }} />
        {/* passing custom onChange function for it to work => not using FormSelect, investigate further */}
        <Controller
          name={"measure.insulationProduct.name"}
          control={form.control}
          render={({ field: { ref, value } }) => (
            <TextField
              label={t("insulationProduct")}
              variant="outlined"
              error={!!form.getFieldState("measure.insulationProduct.name").error?.message}
              ref={ref}
              onChange={handleSelectChange}
              value={value || ""}
              helperText={form.getFieldState("measure.insulationProduct.name").error?.message}
              select
              fullWidth
            >
              {insulationProducts.map(({ name }) => (
                <MenuItem key={name} value={name} sx={{ fontSize: "25px" }}>
                  {name}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
        <FormInput
          {...register("measure.insulationThickness", { setValueAs: (v) => stringToNumber(v) })}
          label={t("extraInsulationThickness")}
          error={errors?.measure?.insulationThickness}
          disabled={extraInsulationProduct === undefined || extraInsulationProduct === "geen"}
        />
      </FormStack>
    </Card>
  );
};

export const AddWallElement = () => {
  const [orientationImage, setOrientationImage] = useState<string>("");
  const [isOpen, setIsOpen] = useState(false);
  const confirm = useConfirm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { wallId, id } = useParams();
  const tags = useAppSelector(getFilteredImageTags(Category.WALL));
  const sphId = id ? id : "";
  const getOrientationImage = useCallback(async () => {
    const imageName = process.env.REACT_APP_ORIENTATION_IMAGE || "orientation.jpeg";
    const data = await fetchOrientationImage(+sphId, imageName);
    const blobToImg: string = data ? ((await convertBlobToBase64(data.file)) as string) : "";
    if (data) setOrientationImage(blobToImg);
  }, [sphId]);

  const showOrientationImage = () => setIsOpen(!isOpen);

  useEffect(() => {
    (async () => await getOrientationImage())();
  }, []);
  const wallSuggestionExecutive = useAppSelector(
    getFilteredSuggestions(SuggestionCategory.EXECUTIVE_WALL),
  );
  const wallInsulationProducts = useAppSelector(getCategorySpecificProducts(Category.WALL));
  const walls = useAppSelector(getWallsData);
  const wall = useAppSelector(getWallByName(wallId || ""));
  const houseInfoConstructionYear = useAppSelector(getBuildingConstructionYear);

  const wallDefault = {
    ...wall,
    hasExpansionJoint: wall?.hasExpansionJoint || false,
    hasCrack: wall?.hasCrack || false,
    hasVaporBarier: wall?.hasVaporBarier || false,
    hasVegetation: wall?.hasVegetation || false,
    hasMoistureProblem: wall?.hasMoistureProblem || false,
    hasHoles: wall?.hasHoles || false,
    constructionYear: houseInfoConstructionYear || wall?.constructionYear,
    insulationMaterial: wall?.insulationMaterial,
    insulationThickness: wall?.insulationThickness || 0,
    measure: {
      insulationProduct: {
        id: wall?.measure?.insulationProduct?.id,
        name:
          wall?.measure.insulationProduct === null
            ? "geen"
            : wall?.measure.insulationProduct?.name || "",
      },
      insulationThickness: wall?.measure?.insulationThickness || 0,
    },
    images: wall?.images || [],
    windowGroups: wall?.windowGroups || [],
    notes: wall?.notes || "",
  };
  const form = useForm<IWall, any>({
    resolver: yupResolver(getWallElementSchema(t)),
    mode: "all",
    defaultValues: wallDefault,
  });
  const {
    formState: { errors },
    trigger,
    handleSubmit,
    clearErrors,
    setValue,
    watch,
  } = form;
  const cavityWidthValue = watch("cavityWidth");
  const currentThicknessValue = watch("insulationThickness");
  const extraInsulationProduct = watch("measure.insulationProduct.name");
  const handleSelectChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = event.target.value;
    setValue("measure.insulationProduct.name", value);
    clearErrors("measure.insulationProduct.name");
    if (
      wall?.measure.insulationProduct?.name !== undefined &&
      wall.measure.insulationProduct.name !== "geen"
    ) {
      if (
        wall?.measure.insulationThickness === undefined ||
        wall?.measure.insulationThickness === 0
      ) {
        const extraThicknessValue = watch("cavityWidth") - watch("insulationThickness");
        setValue("measure.insulationThickness", +extraThicknessValue.toFixed(4));
      }
    }
    if (value === "geen" || wall?.measure.insulationProduct?.name === "geen") {
      setValue("measure.insulationThickness", 0);
      clearErrors("measure.insulationThickness");
    }
  };

  const handleExtraThickness = () => {
    if (watch("measure.insulationProduct.name")) {
      const extraThicknessValue = watch("cavityWidth") - watch("insulationThickness");
      setValue("measure.insulationThickness", +extraThicknessValue.toFixed(4));
      trigger("measure.insulationThickness");
    }
  };

  useEffect(() => {
    trigger();
  }, [trigger]);

  useEffect(() => {
    if (
      (wall?.measure.insulationThickness === undefined ||
        wall?.measure.insulationThickness === 0) &&
      extraInsulationProduct !== undefined &&
      extraInsulationProduct !== "geen" &&
      extraInsulationProduct !== null
    ) {
      if (cavityWidthValue && currentThicknessValue) {
        const extraThicknessValue = cavityWidthValue - currentThicknessValue;
        setValue("measure.insulationThickness", +extraThicknessValue.toFixed(4));
      }
    }
  }, [
    cavityWidthValue,
    currentThicknessValue,
    extraInsulationProduct,
    setValue,
    wall?.measure.insulationThickness,
    watch,
  ]);

  const wallName = getTrimmedName(watch("name"));

  const handleSave = async () => {
    await trigger();
    const insulationProductName = form.getValues("measure.insulationProduct.name");
    const selectedInsulationProduct = wallInsulationProducts.find(
      (el) => el.name === insulationProductName,
    );
    if (selectedInsulationProduct) {
      form.setValue("measure.insulationProduct.id", selectedInsulationProduct.id);
    }

    if ("name" in form.formState.errors || "name" in form.formState.errors) {
      toast.error(t("mandatoryField"));
      form.setFocus("name");
      return;
    }

    if (wallName !== wallId && isNameUnique(walls, wallName)) {
      toast.error(t("uniqueName"));
      form.setFocus("name");
      return;
    }

    dispatch(
      saveWall({
        wallId,
        ...form.getValues(),
        name: wallName,
        errors: Object.keys(form.formState.errors),
      }),
    );

    // Determine the name of the element, defaulting to "wall_unknown" if  'wall.name' is undefined
    const prevWallName = wall?.name ?? WALL_NAME_UNKNOWX;

    // Update requests in local storage where elementName or parentName mathches with the new 'prevWallName'
    updateHouseImageRequestsWithNewName(sphId, prevWallName, wallName, true);

    navigate(-1);
  };

  const handleDelete = async () => {
    const choice = await confirm({
      title: t("deleteWall"),
      description: wall?.windowGroups?.length
        ? `${t("wallHas")} ${wall?.windowGroups?.length} ${t("windowFrames")}. ${t(
            "deleteTheseAsWell",
          )}`
        : `${t("delete")}?`,
      confirmBtnLabel: t("delete"),
    });

    if (choice) {
      dispatch(deleteWall({ wallId }));

      // Remove image requests from local storage for the deleted wall element if they exist
      const wallName = wall?.name ?? WALL_NAME_UNKNOWX;
      deleteHouseImageRequestFromLocalStorageForHousePart(wallName, sphId, true);

      navigate(-1);
    }
  };

  return (
    <MainPageWrapper>
      <Cover
        title={wallId ? t("editWall") : t("addWall")}
        showBackButton={true}
        handleDelete={wallId ? handleDelete : undefined}
      />
      <PageWrapper>
        <Form onSubmit={handleSubmit(handleSave, handleSave)}>
          <Stack>
            <TwoColumnsContainer
              childrenFirstColumn={
                <CurrentSituation
                  form={form}
                  handleExtraThickness={handleExtraThickness}
                  showOrientationImage={showOrientationImage}
                />
              }
              childrenSecondColumn={
                <NewSituation
                  form={form}
                  insulationProducts={wallInsulationProducts}
                  handleSelectChange={handleSelectChange}
                />
              }
            />
          </Stack>
          <Photos
            tags={tags}
            sphId={sphId}
            form={form}
            housePartElement="walls"
            elementName={wall?.name ?? WALL_NAME_UNKNOWX}
          />
          <AdditionalDataWrapper title={t("ownerAgreements")}>
            <AdditionalData
              name="notes"
              form={form}
              suggestions={wallSuggestionExecutive}
              error={errors.notes}
            />
          </AdditionalDataWrapper>
          <MainButton text={t("save")} variant="primary" type="submit" sx={{ margin: "30px 0" }} />
        </Form>
        <PhotoModal
          imageSrc={orientationImage}
          isOpen={isOpen}
          handlePhotoModal={showOrientationImage}
        />
      </PageWrapper>
    </MainPageWrapper>
  );
};
