import { useState, useEffect } from "react";

export const useNetworkStatus = (): boolean => {
  const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine);

  useEffect(() => {
    const handleNetworkStatus = (): void => setIsOnline(navigator.onLine);

    window.addEventListener("online", handleNetworkStatus);
    window.addEventListener("offline", handleNetworkStatus);

    return () => {
      window.removeEventListener("online", handleNetworkStatus);
      window.removeEventListener("offline", handleNetworkStatus);
    };
  }, [isOnline]);

  return isOnline;
};
