import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  FormControl,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import edit from "../../assets/icons/edit.svg";
import logo from "../../assets/icons/logo.png";
import { MainButton } from "../../components/common/Button/MainButton";
import { BlueBgPageWrapper } from "../../components/common/Container/BlueWrapper";
import { EmptyContainer } from "../../components/common/StepComponents/StepContainers";
import NetworkStatus from "../../components/NetworkStatus/NetworkStatus";
import { Path, useNavigation } from "../../routes";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  getHouses,
  getHousesLoadingStatus,
  getMunicipalities,
  getMunicipalitiesError,
  getMunicipalitiesLoadingStatus,
} from "../../store/slices";
import { fetchHouses, fetchMunicipalities } from "../../store/thunks";
import { IHouse, IMunicipality, Loading } from "../../types";
import customLocalStorage from "../../utils/localStorage";
import {
  HouseCardStyled,
  HouseSearch,
  HouseTableCell,
  SelectDropdown,
  TableRows,
} from "./Houses.styles";

export const Houses: FC = () => {
  const navigate = useNavigation();
  const { t } = useTranslation();
  const user = customLocalStorage.getUsername();
  const dispatch = useAppDispatch();

  const municipalities = useAppSelector(getMunicipalities);
  const municipalitiesLoadingStatus = useAppSelector(getMunicipalitiesLoadingStatus);
  const municipalitiesError = useAppSelector(getMunicipalitiesError);

  const houses = useAppSelector(getHouses);
  const [filteredHouses, setFilteredHouses] = useState<IHouse[] | []>([]);
  const housesLoadingStatus = useAppSelector(getHousesLoadingStatus);
  const housesError = useAppSelector(getHousesLoadingStatus);

  const [selectedMunicipality, setSelectedMunicipality] = useState<IMunicipality | undefined>({
    id: "",
    name: "",
  });

  useEffect(() => {
    if (municipalitiesLoadingStatus === Loading.IDLE) dispatch(fetchMunicipalities());
    if (housesLoadingStatus === Loading.IDLE) dispatch(fetchHouses());
  }, [dispatch, municipalitiesLoadingStatus, housesLoadingStatus]);

  const handleHouseSearch = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFilteredHouses(
      houses.filter(
        (house) =>
          house.municipality === selectedMunicipality?.name &&
          (house.streetName + " " + house.houseNumber)
            .toLowerCase()
            .includes(event.target.value.toLowerCase()),
      ),
    );
  };

  return (
    <BlueBgPageWrapper>
      <Box>
        <Box display="flex">
          <img src={logo} width="60px" height="60px" alt={logo} />
          <Box px={7} mt={3} ml={"auto"}>
            <NetworkStatus />
          </Box>
        </Box>
        <Typography variant="h1" ml="30px" mt={"50px"}>
          {t("hello")}, {user}
        </Typography>

        <HouseCardStyled>
          <Grid container>
            <Grid item xs={12} sm={7}>
              <Typography variant="h2" sx={{ fontWeight: "600" }}>
                {t("houses")} ({houses.length})
              </Typography>
            </Grid>
            {municipalitiesLoadingStatus === Loading.FULLFILLED ? (
              <Grid item xs={12} sm={5}>
                <FormControl fullWidth>
                  <InputLabel id="house-label">{t("selectMunicipality")}</InputLabel>
                  <SelectDropdown
                    labelId="house-label"
                    label={t("selectMunicipality")}
                    value={selectedMunicipality?.id}
                  >
                    <MenuItem
                      value="select"
                      onClick={() => {
                        setFilteredHouses([]);
                        setSelectedMunicipality({
                          id: "",
                          name: "",
                        });
                      }}
                    >
                      {t("select")}
                    </MenuItem>
                    {municipalities?.map((municipality) => {
                      return (
                        <MenuItem
                          key={municipality.id}
                          value={municipality.id}
                          onClick={() => {
                            setFilteredHouses(
                              houses.filter((house) => house.municipality === municipality.name),
                            );
                            setSelectedMunicipality(municipality);
                          }}
                        >
                          {municipality.name}
                        </MenuItem>
                      );
                    })}
                  </SelectDropdown>
                </FormControl>
              </Grid>
            ) : (
              <Typography>{municipalitiesError}</Typography>
            )}
          </Grid>
          <Grid container py={5}>
            <HouseSearch fullWidth variant="standard">
              <Input
                autoComplete="off"
                id="house-search"
                type={"search"}
                onChange={handleHouseSearch}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
              <InputLabel htmlFor="house-search">{t("searchAddress")}</InputLabel>
            </HouseSearch>
          </Grid>
          <Grid container py={5} justifyContent="center">
            {housesLoadingStatus === Loading.FULLFILLED ? (
              filteredHouses.length ? (
                <Table>
                  <TableHead>
                    <TableRow>
                      <HouseTableCell>{t("address")}</HouseTableCell>
                      <HouseTableCell>{t("city")}</HouseTableCell>
                      <HouseTableCell>{t("municipality")}</HouseTableCell>
                      <HouseTableCell>{t("status")}</HouseTableCell>
                      <HouseTableCell></HouseTableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {filteredHouses.map((house) => (
                      <TableRows key={house.sphId}>
                        <HouseTableCell>
                          {house.streetName} {house.houseNumber}
                        </HouseTableCell>
                        <HouseTableCell>{house.city}</HouseTableCell>
                        <HouseTableCell>{house.municipality}</HouseTableCell>
                        <HouseTableCell>{house.status}</HouseTableCell>
                        <HouseTableCell sx={{ textAlign: "right" }}>
                          <MainButton
                            text={t("select")}
                            variant="text"
                            icon={<img src={edit} width="15px" height="15px" alt="edit-icon" />}
                            sx={{ ml: "auto" }}
                            onClick={() => navigate(Path.HouseInspection, { id: house.sphId })}
                          />
                        </HouseTableCell>
                      </TableRows>
                    ))}
                  </TableBody>
                </Table>
              ) : (
                <EmptyContainer
                  sx={{ width: "75%", justifyContent: "center" }}
                  title={
                    selectedMunicipality?.name
                      ? t("noDataForSelectedMunicipality")
                      : t("pleaseChooseMunicipality")
                  }
                />
              )
            ) : (
              <Typography>{housesError}</Typography>
            )}
          </Grid>
        </HouseCardStyled>
      </Box>
    </BlueBgPageWrapper>
  );
};
