export enum BuildingType {
  CORNER_HOUSE = "hoekwoning",
  TERRACED_HOUSE = "tussenwoning",
  SEMIDETACHED_HOUSE = "twee-onder-een-kap",
  DETACHED_HOUSE = "vrijstaand",
}

export enum VentilationType {
  NATURAL = "natuurlijk",
  BALANCED = "balans",
  MECHANICAL_DRAIN = "mechanische afvoer",
  MECHANICAL_SUPPLY = "mechanische toevoer",
}

export enum HeatRecoveryInstallationType {
  NONE = "geen",
  CROSS_FLOW_EXCHANGER = "kruisstroomwisselaar",
  HIGH_EFFICIENCY_EXCHANGER = "hoogrendementwisselaar",
}

export enum HeatingTemperatureClass {
  HT = "HT",
  LT = "LT",
}

export enum BoilerType {
  CONVENTIONAL = "conventioneel",
  HR_100 = "HR-100",
  HR_104 = "HR-104",
  HR_107 = "HR-107",
}

export interface IBuilding {
  constructionYear: number;
  buildingType?: BuildingType;
  ridgeHeight?: number;
  ventilationType?: VentilationType;
  storeyCount: number;
  storeyAreas: number[]; // Surface area of each storey (level). Length should be equal to building storeys.
  heatRecoveryInstallationType?: HeatRecoveryInstallationType;
  heatingTemperatureClass?: HeatingTemperatureClass;
  boilerType?: BoilerType;
  errors: string[];
}
