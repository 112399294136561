import { IImage, IProduct } from ".";

export enum FrameType {
  WOOD = "hout",
  PLASTIC = "kunststof",
  METAL_INSULATED = "metaal geisoleerd",
  METAL_UNINSULATED = "metaal ongeisoleerd",
}

export enum FrameCondition {
  BAD = "slecht",
  MODERATE = "matig",
  GOOD = "goed",
}

export enum WindowType {
  FIXED = "vast",
  ROTATING = "draaiend",
  ROTATING_TEMPERED_GLASS = "draaiend gehard glas",
  SKYLIGHT = "dakraam",
}

export enum GlassType {
  SINGLE = "enkel",
  DOUBLE = "dubbel",
  HR = "HR",
  HR_PLUS = "HR+",
  HR_PLUS_PLUS = "HR++",
  TRIPLE = "triple",
  PANEL = "paneel",
  CROSS = "voorzet",
}

export enum VentilationGrill {
  YES = "Ja",
  NONE = "geen",
}

export interface IWindowGroup {
  name: string;
  notes: string;
  storey: number;
  frameType: FrameType;
  frameCondition: FrameCondition;
  windows: IWindow[];
  images: IImage[];
  errors: string[] | [];
}

export interface IWindow {
  name: string;
  windowType?: WindowType;
  glassType?: GlassType;
  area: number;
  headThickness: number;
  hasVentilationGrille: boolean;
  errors?: string[];

  measure: {
    glassProduct: IProduct | null;
    ventilationGrilleWidth: number;
    windowType?: WindowType;
    installVentilationGrille?: boolean;
    isNearFloor?: boolean;
  };
}
