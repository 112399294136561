import { Box, Button, styled } from "@mui/material";

export const AddPhotoBoxStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "180px",
  ".MuiTypography-root": {
    marginTop: "12px",
    textTransform: "uppercase",
    color: theme.palette.primary[800],
  },
}));

export const AddPhotoButtonStyled = styled(Button)(({ theme }) => ({
  variant: "contained",
  component: "label",
  backgroundColor: theme.palette.primary.light,
  width: "100%",
  height: "50%",
  "label.MuiButtonBase-root": {
    minWidth: "100%",
  },
  ".MuiSvgIcon-root": {
    fill: theme.palette.primary[800],
  },
  "&:hover": {
    ".MuiTypography-root": {
      color: theme.palette.white.main,
    },
    ".MuiSvgIcon-root": {
      fill: theme.palette.white.main,
    },
  },
})) as typeof Button;
