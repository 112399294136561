import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

const initialState = {
  notes: "",
};

export const notesSlice = createSlice({
  name: "notes",
  initialState,
  reducers: {
    update: (state, action) => {
      state.notes = action.payload;
    },
  },
});

export const { update } = notesSlice.actions;
export const getNotes = (state: RootState) => state.notes;
