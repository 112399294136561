import { AppRoutes } from "./routes";
import { CssBaseline } from "@mui/material";
import { responsiveFontSizes, ThemeProvider } from "@mui/material/styles";
import { lightTheme } from "./theme/theme";
import { db } from "./store/indexedDB/objectStore";
import { ConfirmDialogProvider } from "./components/common/Dialog/ConfirmDialog";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";

function App() {
  const theme = responsiveFontSizes(lightTheme);
  /* prevent page refresh on tablet ( chrome ) */
  useEffect(() => {
    document.body.style.overscrollBehaviorY = "contain";
  }, []);
  (async () => await db)();
  return (
    <ThemeProvider theme={theme}>
      <ConfirmDialogProvider>
        <CssBaseline enableColorScheme />
        <AppRoutes />
        <ToastContainer />
      </ConfirmDialogProvider>
    </ThemeProvider>
  );
}

export default App;
