import { TextFieldProps, TextField, MenuItem } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { FC, forwardRef } from "react";
import { Controller } from "react-hook-form";

export type InputFieldError = {
  message?: string;
};

export type InputProps = {
  options: string[] | number[];
  error?: InputFieldError;
  label: string;
  form: any;
  name: string;
  defaultValue?: string;
  shouldUpdateNewSituationWindowType?: boolean;
} & Omit<TextFieldProps, "error">;

export type FreeFormSelectSelectProps = {
  options: string[];
  error?: InputFieldError;
  label: string;
  defaultValue?: string;
} & Omit<TextFieldProps, "error">;

export const FreeFormSelect: FC<FreeFormSelectSelectProps> = forwardRef(
  ({ error, value, label, options, defaultValue, ...props }, ref) => {
    const errorText = error?.message;
    return (
      <TextField
        {...props}
        label={label}
        variant="outlined"
        error={!!errorText}
        ref={ref}
        InputProps={{}}
        helperText={errorText}
        select
        fullWidth
        defaultValue={defaultValue || ""}
        InputLabelProps={{ shrink: true }}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option} sx={{ fontSize: "25px" }}>
            {option}
          </MenuItem>
        ))}
      </TextField>
    );
  },
);

export const FormSelect: FC<InputProps> = forwardRef(
  (
    {
      error,
      label,
      options,
      defaultValue,
      name,
      form,
      shouldUpdateNewSituationWindowType,
      ...props
    },
    ref,
  ) => {
    const errorText = error?.message;

    return (
      <FormControl fullWidth>
        <Controller
          name={name}
          control={form.control}
          render={({ field: { onChange, ref, value } }) => (
            <TextField
              {...props}
              label={label}
              variant="outlined"
              error={!!errorText}
              ref={ref}
              onChange={(e) => {
                onChange(e);
                // Update the WindowType in the New Situation whenever we change value of the window type in Current situation
                if (shouldUpdateNewSituationWindowType) {
                  form.setValue("measure.windowType", e.target.value);
                }
              }}
              value={value || ""}
              helperText={errorText}
              select
              fullWidth
            >
              {options.map((option) => (
                <MenuItem key={option} value={option} sx={{ fontSize: "25px" }}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </FormControl>
    );
  },
);
