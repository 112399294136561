import { Box, styled } from "@mui/material";

export const InspectionPosition = styled(Box)(() => ({
  display: "flex", 
  flexDirection: "column", 
  justifyContent: "center", 
  alignItems: "center", 
  height: "calc(100vh - 100px)"
}));

export const InspectionPage = styled(Box)(() => ({ 
  backgroundColor: "#54C1F0", // TODO
  height: "100vh"
}));
