import { PhaseConnection, MainFuseBoxLocation, SuggestedCableRoute } from "src/types";

export const getSolarCheckboxesValues = (
  measures: any[],
  currentPhaseConnection?: string,
  requiredPhaseConnection?: string,
  mainFuseBoxLocation?: string,
  suggestedCableRoute?: string,
) => {
  return {
    currentPhaseConnection:
      !currentPhaseConnection && !measures.length
        ? PhaseConnection.UNKNOWN
        : (currentPhaseConnection as PhaseConnection),
    requiredPhaseConnection:
      !requiredPhaseConnection && !measures.length
        ? PhaseConnection.UNKNOWN
        : (requiredPhaseConnection as PhaseConnection),
    mainFuseBoxLocation:
      !mainFuseBoxLocation && !measures.length
        ? MainFuseBoxLocation.UNKNOWN
        : (mainFuseBoxLocation as MainFuseBoxLocation),
    suggestedCableRoute:
      !suggestedCableRoute && !measures.length
        ? SuggestedCableRoute.UNKNOWN
        : (suggestedCableRoute as SuggestedCableRoute),
  };
};
