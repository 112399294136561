import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNetworkStatus } from "../../utils/hooks/networkStatus";
import {
  HoverBox,
  LogoutBox,
  SingOutBox,
  SmallCircleBox,
  UsernameBox,
  UsernameInitials,
} from "./NetworkStatus.styles";
import { Alert, Box, Fade, Snackbar, Typography } from "@mui/material";
import customLocalStorage from "../../utils/localStorage";
import { Card } from "../common/Container/Containers";
import { LogoutIcon } from "../common/Icons";
import { useAuthentication } from "../../context/useAuthenticationContext";
import { useNavigate } from "react-router-dom";
import { api } from "../../API/axios";
import { HttpStatusCode } from "axios";
import { Path } from "../../routes";

const NetworkStatus = () => {
  const { i18n } = useTranslation();
  const isOnline = useNetworkStatus();
  const [isEN, setIsEN] = useState(true);
  const [isLogoutVisible, setIsLogoutVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const { t } = useTranslation();
  const [username, setUsername] = useState("");
  const [initials, setInitials] = useState("");
  const { signOut } = useAuthentication();
  const navigate = useNavigate();

  // Just for development while it's design stage
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const changeLang = () => {
    setIsEN(!isEN);
    if (isEN) {
      i18n.changeLanguage("en");
    } else {
      i18n.changeLanguage("nl");
    }
  };

  useEffect(() => {
    const username = customLocalStorage.getUsername();
    if (username) {
      setUsername(username);
      const tempInitials = getInitials(username);
      if (tempInitials) setInitials(tempInitials);
    }
  }, []);

  const getInitials = (name: string) => {
    return name.match(/(\b\S)?/g)?.join("");
  };

  const onLogout = async () => {
    try {
      const res = await api.delete("/login");
      if (res.status === HttpStatusCode.Ok) {
        navigate("/", { replace: true });
        signOut();
        setErrorMessage(false);
      }
    } catch (error) {
      setErrorMessage(true);
    }
  };

  const closeErrorWarning = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setErrorMessage(false);
  };

  return (
    <>
      <HoverBox
        onMouseEnter={() => setIsLogoutVisible(true)}
        onMouseLeave={() => setIsLogoutVisible(false)}
      >
        <UsernameBox onClick={changeLang}>
          {isOnline ? (
            <SmallCircleBox sx={{ backgroundColor: "#2EB138" }}></SmallCircleBox>
          ) : (
            <SmallCircleBox sx={{ backgroundColor: "#DB0303" }}></SmallCircleBox>
          )}
          <Typography variant="body1">{username}</Typography>
        </UsernameBox>
      </HoverBox>
      {isLogoutVisible && (
        <Fade in={isLogoutVisible}>
          <LogoutBox
            onMouseEnter={() => setIsLogoutVisible(true)}
            onMouseLeave={() => setIsLogoutVisible(false)}
          >
            <form onSubmit={onLogout}>
              <Card>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <UsernameInitials>{initials?.toUpperCase()}</UsernameInitials>
                  <Box ml={1}>
                    <Typography>{username}</Typography>
                  </Box>
                </Box>
              </Card>
              <SingOutBox onClick={onLogout}>
                <LogoutIcon color="#707070" />
                <Typography sx={{ marginLeft: "10px" }}>{t("signOut")}</Typography>
              </SingOutBox>
            </form>
          </LogoutBox>
        </Fade>
      )}
      {errorMessage && (
        <Snackbar open={errorMessage} autoHideDuration={2000} onClose={closeErrorWarning}>
          <Alert onClose={closeErrorWarning} severity="success" sx={{ width: "100%" }}>
            {t("somethingWrong")}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default NetworkStatus;
