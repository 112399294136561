import { configureStore } from "@reduxjs/toolkit";
import { dataSlice, inspectionSlice, notesSlice } from "./slices";

export const store = configureStore({
  reducer: {
    data: dataSlice.reducer,
    inspection: inspectionSlice.reducer,
    notes: notesSlice.reducer,
  },
  devTools: process.env.REACT_APP_NODE_ENV !== "production",
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
