import { IImage, IInspection } from "../types";
import customLocalStorage from "../utils/localStorage";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError, HttpStatusCode } from "axios";
import { base64StringToBlob } from "blob-util";
import { api } from "../API/axios";
import { Tables, setIDBImage } from "./indexedDB/objectStore";
import HmacSHA256 from "crypto-js/hmac-sha256";

export const fetchMunicipalities = createAsyncThunk("fetchMunicipalities", async () => {
  try {
    const response = await api.get("/municipalities");
    if (response) return response.data;
  } catch (error) {
    return error;
  }
});

export const fetchHouses = createAsyncThunk("fetchHouses", async () => {
  try {
    const response = await api.get("/houses");
    if (response) return response.data;
  } catch (error) {
    return error;
  }
});

const makeSignature = (
  token: string,
  p: { sphId: number; filename: string; username: string; timestamp: number },
): string => {
  const content = `${p.sphId}${p.filename}${p.username}${p.timestamp}`;
  const sha256Hasher = HmacSHA256(content, token);
  return sha256Hasher.toString();
};

const manageImageResponse = async (
  houseId: number,
  dbTable: Tables,
  image: IImage,
  element: string,
) => {
  const token = customLocalStorage.getToken();
  const username = customLocalStorage.getUsername();
  const currentTime = new Date().getTime();

  const signature = makeSignature(token, {
    filename: image.filename,
    sphId: houseId,
    timestamp: currentTime,
    username: username,
  });

  await api
    .get(
      `/houses/${houseId}/images/${image.filename}?username=${username}&timestamp=${currentTime}&signature=${signature}`,
      { responseType: "arraybuffer" },
    )
    .then(async (response) => {
      const imageB64Data = btoa(
        new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), ""),
      );
      const contentType = "image/jpeg";
      const blob = base64StringToBlob(imageB64Data, contentType);

      await setIDBImage(dbTable, {
        file: blob,
        filename: image.filename,
        tag: image.tag,
        element: element,
      });
    })
    .catch((err) => console.log(err.message));
};

export const fetchImage = async (houseId: number, image: IImage) => {
  const token = customLocalStorage.getToken();
  const username = customLocalStorage.getUsername();
  const currentTime = new Date().getTime();

  const signature = makeSignature(token, {
    filename: image.filename,
    sphId: houseId,
    timestamp: currentTime,
    username: username,
  });

  const response = await api
    .get(
      `/houses/${houseId}/images/${image.filename}?username=${username}&timestamp=${currentTime}&signature=${signature}`,
      { responseType: "arraybuffer" },
    )
    .then(async (response) => {
      const imageB64Data = btoa(
        new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), ""),
      );
      const contentType = "image/jpeg";
      const blob = base64StringToBlob(imageB64Data, contentType);
      return { file: blob, filename: image.filename, tag: image.tag };
    })
    .catch((err) => console.log(err.message));

  return response;
};

export const fetchOrientationImage = async (houseId: number, imageType: string) => {
  const token = customLocalStorage.getToken();
  const username = customLocalStorage.getUsername();
  const currentTime = new Date().getTime();

  const signature = makeSignature(token, {
    filename: imageType,
    sphId: houseId,
    timestamp: currentTime,
    username: username,
  });
  const response = await api
    .get(
      `/houses/${houseId}/images/${imageType}?username=${username}&timestamp=${currentTime}&signature=${signature}`,
      {
        responseType: "arraybuffer",
      },
    )
    .then(async (response) => {
      const imageB64Data = btoa(
        new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), ""),
      );
      const contentType = "image/jpeg";
      const blob = base64StringToBlob(imageB64Data, contentType);
      return { file: blob };
    })
    .catch((err) => console.log(err.message));

  return response;
};

export const fetchInspection = createAsyncThunk(
  "fetchInspection",
  async (houseId: number, { rejectWithValue }) => {
    try {
      const response = await api.get(`/houses/${houseId}/inspection`);
      if (response.status === HttpStatusCode.Ok) {
        const inspection: IInspection = response.data;

        // fetch images
        if (inspection) {
          inspection.images.map(async (image) => {
            await manageImageResponse(houseId, "houseInfo", image, "houseInfo");
          });

          inspection.floors.map((floor) => {
            floor.images.map(async (image) => {
              await manageImageResponse(houseId, "floorElement", image, floor.name);
            });
          });

          inspection.walls.map((wall) => {
            wall.images.map(async (image) => {
              await manageImageResponse(houseId, "wallElement", image, wall.name);
            });
          });

          inspection.roofs.map((roof) => {
            // roof page
            roof.images.map(
              async (image) => await manageImageResponse(houseId, "roofElement", image, roof.name),
            );

            roof.dormers.map((dormer) => {
              dormer.images.map(
                async (image) =>
                  await manageImageResponse(houseId, "dormerElement", image, dormer.name),
              );
            });

            roof.windowGroups.map((windowGroup) => {
              windowGroup.images.map(
                async (image) =>
                  await manageImageResponse(houseId, "windowElement", image, windowGroup.name),
              );
            });
          });
          if (inspection.atticFloor) {
            inspection.atticFloor.images.map(
              async (image) =>
                await manageImageResponse(houseId, "atticElement", image, "atticFloor"),
            );
          }
          if (inspection.solar) {
            inspection.solar.images.map(
              async (image) =>
                await manageImageResponse(houseId, "solarElement", image, "solarElement"),
            );
            inspection.solar.measures.forEach((measure) =>
              measure.images.map(
                async (image) =>
                  await manageImageResponse(houseId, "solarElement", image, "solarElement"),
              ),
            );
          }
        }

        return response.data;
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const saveInspection = createAsyncThunk(
  "saveInspection",
  async ({ inspection }: { inspection: IInspection }, { rejectWithValue }) => {
    try {
      // TODO: validate inspection data?
      const res = await api.put(`/houses/${inspection.sphId}/inspection`, inspection);
      return res;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return rejectWithValue(error.response?.data);
      }
    }
  },
);

export const uploadInspectionImage = createAsyncThunk(
  "uploadImage",
  async (
    { sphId, image, filename }: { sphId: string; image: File | Blob; filename: string },
    { rejectWithValue },
  ) => {
    const bodyFormData = new FormData();
    bodyFormData.append("title", filename);
    bodyFormData.append("img", image);
    try {
      const res = await api.post(`/houses/${sphId}/images`, bodyFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      const { filename: imgName }: { filename: string } = res.data;
      return {
        sphId,
        imgName,
      };
    } catch (error: any) {
      throw rejectWithValue(error);
    }
  },
);

// TODO: delete image. Service not available

export const fetchProducts = createAsyncThunk("fetchProducts", async (sphId: number) => {
  try {
    const response = await api.get(`/products?sphId=${sphId}`);
    if (response) return response.data;
  } catch (error) {
    return error;
  }
});

export const fetchSuggestions = createAsyncThunk("fetchSuggestions", async () => {
  try {
    const response = await api.get("/suggestions");
    if (response) return response.data;
  } catch (error) {
    return error;
  }
});

export const fetchImageTags = createAsyncThunk("fetchImageTags", async () => {
  try {
    const response = await api.get("/imageTags");
    if (response) return response.data;
  } catch (error) {
    return error;
  }
});

export const deleteImage = async (houseId: number, filename: string) => {
  try {
    const response = await api.delete(`/houses/${houseId}/images/${filename}`);

    return response;
  } catch (error) {
    console.log(error);
  }
};
