import { Box, Button, Card, CardMedia, styled } from "@mui/material";

export const AddPhotoEmptyContainer = styled(Box)(({ theme }) => ({
  width: "33%",
  [theme.breakpoints.down("xl")]: {
    width: "50%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "75%",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

export const PhotosContainer = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(4, 1fr)",
  gridAutoRows: "minmax(280px, auto)",
  rowGap: "10px",
  columnGap: "10px",
  [theme.breakpoints.down("xl")]: {
    gridTemplateColumns: "repeat(3, 1fr)",
    rowGap: "5px",
    columnGap: "5px",
  },
  [theme.breakpoints.down("lg")]: {
    gridTemplateColumns: "repeat(2, 1fr)",
  },
})) as typeof Box;

export const ImageContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  lineHeight: 0,
  width: "90%",
  height: "90%",
}));

export const PhotoBoxStyled = styled(Card)(() => ({
  position: "relative",
  width: "100%",
  ".MuiBox-root:nth-of-type(1)": {
    transition: ".4s ease-in-out",
    background:
      "linear-gradient(to bottom, rgba(40, 40, 40, 0.9) 0%, rgba(170, 170, 170, .5) 15%, rgba(256, 256, 256, 0.1) 30%)",
    height: "100%",
    opacity: "1",
  },
})) as typeof Box;

export const Image = styled(CardMedia)(() => ({
  position: "relative",
  width: "100%",
  ".MuiCardMedia-img": {
    minHeight: "100%",
    objectFit: "cover",
  },
})) as typeof CardMedia;

export const ImageOverlay = styled(Box)(() => ({
  position: "absolute",
  cursor: "pointer",
  width: "100%",
  opacity: "1",
  top: "0",
  img: {
    position: "absolute",
    right: "10px",
    top: "10px",
    zIndex: 1,
  },
})) as typeof Box;
