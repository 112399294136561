import { TextFieldProps, TextField } from "@mui/material";
import { FC, forwardRef, useEffect } from "react";
import { useForwardRef } from "../../../utils/hooks/useForwardRef";

export type InputFieldError = {
  message?: string;
};

export type InputProps = { error?: InputFieldError; label: string } & Omit<TextFieldProps, "error">;

export const FormInput: FC<InputProps> = forwardRef(
  ({ error, autoFocus, label, disabled, InputProps, ...props }, ref) => {
    const errorText = error?.message;
    const inputRef = useForwardRef<HTMLInputElement | null>(props.inputRef || ref);
    
    /* the useEffect bellow is used to convert number with dot separators to comma separators - github issue #209 */

    useEffect(() => {
      if(inputRef.current?.value && inputRef.current.value.match(/^\d+[.]\d*$/)) {
        inputRef.current.value = inputRef.current.value.replace(".", ",")
      }
    }, [inputRef])

    
    useEffect(() => {
      if (autoFocus && inputRef?.current) {
        inputRef.current?.focus();
      }
    }, [autoFocus, inputRef]);

    return (
      <TextField
        {...props}
        label={label}
        inputRef={inputRef}
        variant="outlined"
        error={!!errorText}
        autoFocus={autoFocus}
        ref={ref}
        InputProps={InputProps}
        helperText={errorText}
        disabled={disabled}
        fullWidth
        InputLabelProps={{ shrink: true }}
        // value={inputRef?.current?.value && inputRef?.current?.value}
      />
    );
  },
);
