import { Typography, Box, MenuItem, styled, MenuList, AppBar } from "@mui/material";
import { Link } from "react-router-dom";

export const SidebarStyled = styled(AppBar)(({theme}) => ({
  position: "relative",
  width: "60px",
  boxShadow: "none",
  backgroundColor: theme.palette.neutral[800],
}));

export const NavListStyled = styled(MenuList)(({ theme }) => ({
  backgroundColor: theme.palette.neutral[800],
  position: "fixed",
  width: "60px",
  height: "100%",
  borderRight: "2px solid #EFEFEF",
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),
  overflowY: "auto",
  overflowX: "hidden",
  lineHeight: 0,
}));

export const NavItemStyled = styled(MenuItem)(({ theme }) => ({
  width: "100%",
  padding: "5px",
  textAlign: "center",
  ":hover": {
    backgroundColor: theme.palette.neutral[800],
    ".MuiBox-root": {
      backgroundColor: theme.palette.primary[700],
    },
  },
}));

export const NavIconWrapper = styled(Box)(() => ({
  textDecoration: "none",
  width: "50px",
  lineHeight: 0,
  borderRadius: "10px",
  padding: "8px 0"
}));

export const NavLinkStyled = styled(Link)(() => ({
  textDecoration: "none",
  width: "100%",
}));

export const NavLinkTextStyled = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary[600],
}));
