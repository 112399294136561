import { createTheme, Theme } from "@mui/material/styles";
import { breakpoints } from "./breakpoints";
import {
  greenColor,
  greyColor,
  blueColor,
  redColor,
  pinkColor,
  gradientColor,
  orangeColor,
} from "./colors";
import { Color, PaletteColorOptions } from "@mui/material";
import "@fontsource/roboto-flex";

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    primary: true;
    secondary: true;
    disabled: true;
    white: true;
    text: true;
    suggest: true;
    delete: true;
  }
}
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    body2: false;
    sidebar: true;
    fontWeightSemiBold: true;
    fontWeightBold: false;
  }
}
declare module "@mui/material/styles" {
  interface TypographyVariants {
    sidebar: React.CSSProperties;
    fontWeightSemiBold: React.CSSProperties["fontWeight"];
  }
  interface TypographyVariantsOptions {
    fontWeightSemiBold?: React.CSSProperties["fontWeight"];
    sidebar?: React.CSSProperties;
  }
  interface PaletteColor extends Color {
    50: string;
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
    dark: string;
    light: string;
    main: string;
    newColor?: string; // TODO: redefine color palette
  }

  interface Theme {
    palette: {
      primary: PaletteColor;
      secondary: PaletteColor;
      success: PaletteColor;
      error: PaletteColor;
      neutral: PaletteColor;
      border: PaletteColor;
      purple: PaletteColor;
      white: PaletteColor;
      green: PaletteColor;
      pink: PaletteColor;
      gradient: PaletteColor;
      orange: PaletteColor;
      red: PaletteColor;
    };
  }

  interface PaletteOptions {
    green?: PaletteColorOptions;
    pink?: PaletteColorOptions;
    brown?: PaletteColorOptions;
    purple?: PaletteColorOptions;
    neutral?: PaletteColorOptions;
    border?: PaletteColorOptions;
    white?: PaletteColorOptions;
    gradient?: PaletteColorOptions;
    orange?: PaletteColorOptions;
    red?: PaletteColorOptions;
  }
}

export type ThemeProps = { theme: Theme };

export const lightTheme = createTheme({
  typography: {
    fontFamily: "Roboto-flex, sans-serif",
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightSemiBold: 600,
    fontSize: 16,
    button: { fontSize: "14px", textTransform: "none", fontWeight: 500 },
    h1: { fontSize: "43px", fontWeight: 300 },
    h2: { fontSize: "18px", fontWeight: 300 },
    h3: { fontSize: "14px", fontWeight: 500 },
    subtitle1: { fontSize: "11px", fontWeight: 400 },
    body1: { fontSize: "14px", fontWeight: 500 },
    sidebar: { fontSize: "11px", fontWeight: 400 },
  },
  palette: {
    primary: blueColor,
    secondary: { main: "#DB8E03" },
    info: greenColor,
    error: redColor,
    success: greenColor,
    text: { primary: "#3E3E3E" },
    neutral: greyColor,
    white: { main: "#fff" },
    green: greenColor,
    pink: pinkColor,
    gradient: gradientColor,
    orange: orangeColor,
    red: redColor,
    border: { main: "#DDDDDD", dark: "#8B8B8B" },
  },

  breakpoints: {
    values: { ...breakpoints },
  },
  components: {
    MuiSvgIcon: {
      defaultProps: { fontSize: "medium" },
      styleOverrides: {
        fontSizeMedium: {
          fontSize: "2rem",
        },
        fontSizeLarge: {
          fontSize: "5rem",
        },
      },
    },
    // TODO: Try to add colors as variables
    MuiButton: {
      variants: [
        {
          props: { variant: "primary" },
          style: {
            background: "#3C7726",
            color: "#FFF",
            minHeight: "45px",
            width: "100%",
            "&:hover": { backgroundColor: "#2EB138" },
          },
        },
        {
          props: { variant: "secondary" },
          style: {
            background: "#003257",
            minHeight: "35px",
            color: "#FFF",
            "&:hover": { backgroundColor: "#C8E6FC", color: "#003257" },
          },
        },
        {
          props: { variant: "disabled" },
          style: {
            background: "#9A9797",
            color: "#FFF !important",
            minHeight: "35px",
            "&:hover": { backgroundColor: "#C8E6FC", color: "#003257" },
          },
        },
        {
          props: { variant: "white" },
          style: {
            border: "2px solid #54C1F0",
            background: "#FFF",
            minHeight: "30px",
            color: "#54C1F0",
            // TODO: Check with Danny if button should have hover effect
            // "&:hover": {
            //   backgroundColor: "#003257",
            //   color: "#FFF"
            // },
          },
        },
        {
          props: { variant: "text" },
          style: {
            border: "2px solid white",
            background: "#FFF",
            minHeight: "30px",
            color: "#54C1F0",
            "&:hover": {
              backgroundColor: "#003257",
              color: "#FFF",
            },
          },
        },
        {
          props: { variant: "suggest" },
          style: {
            background: "#C8E6FC",
            minHeight: "30px",
            borderRadius: "20px !important", // TODO: Check
            color: "#000",
            minWidth: "170px", // TODO: Check
            "&:hover": {
              backgroundColor: "#003257",
              color: "#FFF",
            },
          },
        },
        {
          props: { variant: "delete" },
          style: {
            background: "#FFF",
            minHeight: "30px",
            color: "#8B8B8B",
            border: "#8B8B8B",
            "&:hover": {
              backgroundColor: "#003257",
              color: "#FFF",
            },
          },
        },
      ],
    },
  },
});
