export enum Direction {
  NORTH = "N",
  EAST = "O",
  WEST = "W",
  SOUTH = "Z",
  NORTH_EAST = "NO",
  NORTH_WEST = "NW",
  SOUTH_EAST = "ZO",
  SOUTH_WEST = "ZW",
}

export const enum Loading {
  IDLE = "idle",
  PENDING = "pending",
  FULLFILLED = "fullfilled",
  REJECTED = "rejected",
}

export enum SuggestionCategory {
  ACCESSIBILITY = "accessibility",
  OWNER_WALL = "ownerWall",
  OWNER_FLOOR = "ownerFloor",
  OWNER_WINDOW = "ownerWindow",
  OWNER_ROOF = "ownerRoof",
  OWNER_HOUSE = "ownerHouse",
  OWNER_SOLAR = "ownerSolar",
  EXECUTIVE_WALL = "executiveWall",
  EXECUTIVE_FLOOR = "executiveFloor",
  EXECUTIVE_WINDOW = "executiveWindow",
  EXECUTIVE_ROOF = "executiveRoof",
  EXECUTIVE_HOUSE = "executiveHouse",
  EXECUTIVE_SOLAR = "executiveSolar",
}

export enum Category {
  HOUSE = "house",
  INSPECTION = "inspection",
  FLOOR = "floor",
  WALL = "wall",
  WINDOW_GROUP = "windowGroup",
  ROOF = "roof",
  ATTIC_FLOOR = "atticFloor",
  SOLAR = "solar",
  SOLAR_MEASURE = "solarMeasure",
  SOIL = "soil",
  GLASS = "glass",
}

export enum HoseDistance {
  LESS_THAN_FIFTY = "<50m",
  FIFTY_TO_SEVENTY = "50-70m",
  GREATER_THAN_SEVENTY = ">70m",
}

export enum PhaseConnection {
  MONO_PHASE = "1-fase",
  THREE_PHASE = "3-fase",
  UNKNOWN = "onbekend",
}

export enum DefaultValues {
  NONE = "geen",
  UNKNOWN = "onbekend",
}

export enum Prefixes {
  LOCAL = "local-",
  HOUSE_IMAGES = "house_images_",
}

export enum MainFuseBoxLocation {
  UNKNOWN = "onbekend",
  EXTERIOR_WALL = "aan buitenmuur",
  INTERIOR_WALL = "aan binnenmuur",
  GARAGE = "garage",
  OTHER = "anders",
}

export enum SuggestedCableRoute {
  UNKNOWN = "onbekend",
  INSIDE = "binnendoor (koof, leiding)",
  OOTSIDE = "buitenom (gevel, kruipruimte)",
  PV_DISTRIBUTION_BOX = "pv verdeelkast (bestaande groep, max 12 panelen)",
  DIG_TRENCH = "geul graven (dakvlak niet aangrenzend woning)",
}
