import { SvgIconTypeMap, Typography } from "@mui/material";
import { FC, FunctionComponent } from "react";
import { NavIconWrapper, NavItemStyled, NavLinkStyled, NavLinkTextStyled } from "./Sidebar.styles";
import { IconBaseProps } from "../common/Icons";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { useLocation, useParams } from "react-router-dom";

interface ISidebar {
  Icon:
    | FunctionComponent<IconBaseProps>
    | (OverridableComponent<SvgIconTypeMap<IconBaseProps, "svg">> & { muiName: string });
  text: string;
  path: string;
}

export const SidebarItem: FC<ISidebar> = ({ Icon, path, text }) => {
  const location = useLocation();
  const { id } = useParams();
  const fullPath = `/houses/${id}/${path}`;

  return (
    <NavItemStyled selected={fullPath === location.pathname}>
      <NavLinkStyled to={path}>
        <NavIconWrapper>{<Icon color="action" />}</NavIconWrapper>
        <NavLinkTextStyled variant="sidebar">
          <Typography component="span" variant={"sidebar"}>{text}</Typography>
        </NavLinkTextStyled>
      </NavLinkStyled>
    </NavItemStyled>
  );
};
