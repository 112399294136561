export const blueColor = {
  50: "#EAF5FA",
  100: "#54C1F0",
  200: "#326981",
  300: "#0D597B",
  400: "#093548",
  500: "#59BCE7",
  600: "#405360",
  700: "#C8E6FC",
  800: "#003257",
  900: "#E8F4FC",
  light: "#8DD5F4",
  dark: "#093548",
  main: "#0D597B",
  newColor: "#C8E6FC",
};

export const orangeColor = {
  50: "#D5C984",
  100: "#FFB100",
  200: "#F58F44",
  300: "#DB8E03",
  light: "#D5C984",
  dark: "#DB8E03",
  main: "#FFB100",
};

export const greyColor = {
  50: "#FFFFFF",
  100: "#F0F0F0",
  200: "#EDEDED",
  300: "#E6E6E6",
  400: "#D8D8D8",
  500: "#9F9F9F",
  600: "#707070",
  700: "#5C5C5C",
  800: "#F2F5F7",
  900: "#000000",
  light: "#FFFFFF",
  dark: "#5C5C5C",
  main: "#EDEDED",
};

export const greenColor = {
  100: "#71C030",
  200: "#4FB143",
  300: "#117B0D",
  400: "#3C7726",
  light: "#71C030",
  dark: "#117B0D",
  main: "#4FB143",
};

export const redColor = {
  300: "#D58484",
  light: "#C04630",
  main: "#A21313",
  dark: "#C04630",
};

export const pinkColor = {
  main: "#E0CCF7",
};

export const gradientColor = {
  light: "linear-gradient(to bottom right, #BCD2DD 45%, #DED8C4 90%)",
  main: "linear-gradient(to bottom right, #125F80 45%, #C37D00 90%)",
};
