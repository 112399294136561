import { Box, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { CoverStyled, CoverActionsStyled } from "./Cover.styled";
import NetworkStatus from "../../NetworkStatus/NetworkStatus";
import BackButton from "../Button/BackButton";
import { MainButton } from "../Button/MainButton";
import { t } from "i18next";
import { DeleteIcon } from "../Icons";
interface ICover {
  title: string;
  previousPage?: boolean;
  showBackButton?: boolean;
  handleDelete?: () => void;
}

export const Cover: FC<ICover> = ({ title, showBackButton, previousPage, handleDelete }) => {
  return (
    <CoverStyled>
      <CoverActionsStyled>
        {showBackButton ? (
          <>
            <BackButton previousPage={previousPage} />
            <NetworkStatus />
          </>
        ) : (
          <Box sx={{ marginLeft: "auto" }}>
            <NetworkStatus />
          </Box>
        )}
      </CoverActionsStyled>
      <Stack display={"flex"} direction={"row"} justifyContent={"space-between"}>
        <Typography variant="h1">{title}</Typography>
        {handleDelete && (
          <MainButton
            text={t("delete")}
            sx={{
              backgroundColor: "inherit",
              color: "black",
              border: "1px solid black",
              minWidth: "200px",
            }}
            onClick={() => {
              handleDelete();
            }}
            icon={<DeleteIcon />}
          />
        )}
      </Stack>
    </CoverStyled>
  );
};
