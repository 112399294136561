import { Box, Typography } from "@mui/material";
import { FC, PropsWithChildren } from "react";
import { DashboardHeaderIcon, ColumnContainer } from "../Dashboard/Dashboard.styled";
import WindowIcon from "../../../assets/icons/window.svg";
import EditIcon from "../../../assets/icons/edit.svg";
import { MainButton } from "../Button/MainButton";
import { useTranslation } from "react-i18next";
import { StraightenIcon } from "../Icons";

interface IAccordionSummaryItem {
  title: string;
  area?: number | undefined;
  errors: string[] | [];
  expanded: boolean;
}

export const AccordionSummaryItem: FC<PropsWithChildren<IAccordionSummaryItem>> = ({
  title,
  area,
  errors,
  expanded,
  ...props
}) => {
  const errorCount = errors.length;
  const error = errorCount ? (errorCount > 1 ? errorCount + " errors" : errors[0]) : "Completed";
  const color = errorCount ? "#A21313" : "inherit"
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        px: 1,
      }}
    >
      <DashboardHeaderIcon>
        <img src={WindowIcon} alt={"card-icon"} width={"40px"} height={"40px"} />
      </DashboardHeaderIcon>
      <ColumnContainer width={"100%"}>
        <Typography>{title}</Typography>
        <Typography variant="subtitle1" color={color}>
          {error}
        </Typography>
      </ColumnContainer>
      {!isNaN(area as number) && (area as number) > 0 && (
        <Box sx={{ mx: 1, display: "flex", alignItems: "center" }}>
          <StraightenIcon color="disabled" />
          {(area as number).toFixed(2)}m<sup>2</sup>
        </Box>
      )}
      {expanded ? (
        <MainButton
          text={t("close")}
          variant="text"
          icon={undefined}
          /* x icon and grey color */ sx={{ mr: 1 }}
        />
      ) : (
        <MainButton text={t("edit")} variant="text" icon={<img src={EditIcon} />} sx={{ mr: 1 }} />
      )}
    </Box>
  );
};
