import { Box, SvgIconTypeMap, Typography, styled } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { FC, FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { IconBaseProps } from "../common/Icons";
import { NavIconWrapper, NavItemStyled } from "./Sidebar.styles";

const NotesStyled = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  cursor: "pointer",
}));

export type SidebarNotesProps = {
  Icon:
    | FunctionComponent<IconBaseProps>
    | OverridableComponent<SvgIconTypeMap<IconBaseProps, "svg">>;
  handleNotes: () => void;
};

export const SidebarNotes: FC<SidebarNotesProps> = ({ Icon, handleNotes }) => {
  const { t } = useTranslation();
  return (
    <NavItemStyled sx={{ flexDirection: "column" }} onClick={handleNotes}>
      <NotesStyled onClick={handleNotes}>
        {/* modify when theme is modified */}
        <NavIconWrapper>{<Icon color="action" />}</NavIconWrapper>
        <Typography style={{ color: "#000" }} component="span" variant={"sidebar"}>
          {t("notes")}
        </Typography>
      </NotesStyled>
    </NavItemStyled>
  );
};
