import { FailedImageUploadRequest } from "src/types/failedImageUploadRequest";

const storageUsername = `${process.env.REACT_APP_LOCAL_STORAGE_INSPECTION_APP}_username`;
const storageToken = `${process.env.REACT_APP_LOCAL_STORAGE_INSPECTION_APP}_token`;
const storageSphId = `${process.env.REACT_APP_LOCAL_STORAGE_INSPECTION_APP}_sph_id`;

const customLocalStorage = {
  getItem: (key: string) => {
    const rejectedRequests = localStorage.getItem(
      `${process.env.REACT_APP_LOCAL_STORAGE_INSPECTION_APP}_${key}`,
    );
    const parsedRequests: FailedImageUploadRequest[] = rejectedRequests?.length
      ? JSON.parse(rejectedRequests)
      : [];

    return parsedRequests;
  },
  setItem: (key: string, value: string): void => {
    return localStorage.setItem(
      `${process.env.REACT_APP_LOCAL_STORAGE_INSPECTION_APP}_${key}`,
      value,
    );
  },
  removeItem: (key: string): void => {
    return localStorage.removeItem(`${process.env.REACT_APP_LOCAL_STORAGE_INSPECTION_APP}_${key}`);
  },
  getUsername: () => {
    return window.localStorage.getItem(storageUsername) as string;
  },
  getToken: () => {
    return window.localStorage.getItem(storageToken) as string;
  },
  setToken: (username: string, token: string) => {
    window.localStorage.setItem(storageUsername, username);
    window.localStorage.setItem(storageToken, token);
  },
  clearToken: () => {
    window.localStorage.removeItem(storageUsername);
    window.localStorage.removeItem(storageToken);
  },
  setSphId: (sphId: string) => {
    window.localStorage.setItem(storageSphId, sphId);
  },
  getSphId: () => {
    return window.localStorage.getItem(storageSphId);
  },
};

export default customLocalStorage;
