import { FC, MouseEventHandler, PropsWithChildren, ReactElement } from "react";
import { Button, Box, ButtonProps } from "@mui/material";

interface IMainButton extends ButtonProps {
  text?: string | undefined | null;
  icon?: ReactElement<any, any>; 
  variant?: "primary" | "secondary" | "disabled" | "white" | "text" | "suggest";
  path?: string;
  handleClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const MainButton: FC<PropsWithChildren<IMainButton>> = ({
  icon,
  text,
  variant,
  handleClick,
  ...props
}) => {
  return (
    /* replace icon with <img src={icon} /> */
    <Button variant={variant} onClick={handleClick} sx={{ borderRadius: "3px" }} {...props}>
      {icon && <Box sx={{ lineHeight: 0, pr: 1 }}>{icon}</Box>}
      <Box>{text}</Box>
    </Button>
  );
};
