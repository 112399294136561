import { Prefixes } from "src/types";
import customLocalStorage from "./localStorage";

export const deleteHouseImageRequestFromLocalStorageForHousePart = (
  elementName: string,
  houseId: string,
  isParentElement?: boolean,
) => {
  const requests = customLocalStorage.getItem(`${Prefixes.HOUSE_IMAGES}${houseId}`);

  /** Filter out requests where elementName matches */
  const updatedRequests = requests.filter((item) => item.elementName !== elementName);

  /** If it is not parent element, update local storage with the 'updatedRequests' */
  if (!isParentElement) {
    return customLocalStorage.setItem(
      `${Prefixes.HOUSE_IMAGES}${houseId}`,
      JSON.stringify(updatedRequests),
    );
  }

  /** Filter out requests where parentName matches */
  const updatedParentNameInRequests = updatedRequests.filter(
    (item) => item.parentName !== elementName,
  );

  customLocalStorage.setItem(
    `${Prefixes.HOUSE_IMAGES}${houseId}`,
    JSON.stringify(updatedParentNameInRequests),
  );
};
