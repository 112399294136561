import { IFormContext } from "../../pages/Houses/HouseInfo";
import { FC, useEffect } from "react";
import { useFormContext, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IRoof } from "../../types";
import { FormInput } from "./Form/FormInput";
import { FormStack } from "./Form/FormStack";
import { parseNumber } from "src/utils/helpers/parseNumber";

export type SurfaceAreaProps = {
  form: UseFormReturn<IRoof & SurfaceAreaForm, any>;
};

export type SurfaceAreaForm = {
  width: number;
  height: number;
};

export const SurfaceArea: FC<any> = ({ form, isFloor }) => {
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = form;
  const width = watch("width");
  const height = watch("height");

  const calculateSurfaceArea = (width: number, height: string) => {
    const widthInMeters = parseNumber(width);
    const heightInMeters = parseNumber(height);
    const areaInSquareMeters = +(widthInMeters * heightInMeters).toFixed(2);

    setValue("area", areaInSquareMeters);
    form.trigger();
  };
  const { t } = useTranslation();

  return (
    <FormStack pt={2}>
      <FormInput
        {...register("width", {
          onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
            return setValue("width", e.target.value);
          },
        })}
        label={t("widthM")}
        onBlur={() => calculateSurfaceArea(width, height)}
      />
      <FormInput
        {...register("height", {
          onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
            calculateSurfaceArea(width, e.target.value);
            return setValue("height", e.target.value);
          },
        })}
        label={isFloor ? t("lengthM") : t("heightM")}
      />
    </FormStack>
  );
};

export const FloorAreas: FC<{ index: number }> = ({ index }) => {
  const { t } = useTranslation();

  const form = useFormContext<IFormContext>();
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = form;
  const width = watch(`floorAreas.${index}.width`) || 0;
  const height = watch(`floorAreas.${index}.height`) || 0;

  const calculateSurfaceArea = (width: number, height: number) => {
    const widthInMeters = parseNumber(width);
    const heightInMeters = parseNumber(height);
    const areaInSquareMeters = +(widthInMeters * heightInMeters).toFixed(2);
    setValue(`floorAreas.${index}.area`, areaInSquareMeters);
    form.trigger();
  };

  return (
    <FormStack pt={2}>
      <FormInput
        {...register(`floorAreas.${index}.width`, {
          onChange: (e) => {
            const value = e.target.value;
            return setValue(`floorAreas.${index}.width`, value);
          },
        })}
        label={t("widthM")}
        onBlur={() => calculateSurfaceArea(width, height)}
        error={errors.floorAreas && errors.floorAreas[index]?.width}
      />
      <FormInput
        {...register(`floorAreas.${index}.height`, {
          onChange: (e) => {
            calculateSurfaceArea(e.target.value, width);
            return setValue(`floorAreas.${index}.height`, e.target.value);
          },
        })}
        label={t("lengthM")}
        onBlur={() => calculateSurfaceArea(width, height)}
        error={errors.floorAreas && errors.floorAreas[index]?.height}
      />
    </FormStack>
  );
};
