import { createContext, useCallback, useContext, useRef, useState } from "react";
import Dialog from "./Dialog";

interface ConfirmDialogData {
  title: string;
  description: string;
  confirmBtnLabel: string;
  deleteMessage?: string | undefined | null;
  cancelBtnLabel?: string;
}

type ConfirmDialogContextType = (data: ConfirmDialogData) => Promise<boolean>;

const ConfirmDialog = createContext<ConfirmDialogContextType | undefined>(undefined);

export function ConfirmDialogProvider({ children }: { children: React.ReactNode }) {
  const [state, setState] = useState({ isOpen: false });
  const fn = useRef<(choice: boolean) => void>();

  const confirm = useCallback((data: ConfirmDialogData) => {
    return new Promise<boolean>((resolve) => {
      setState({ ...data, isOpen: true });
      fn.current = (choice) => {
        resolve(choice);
        setState({ ...data, isOpen: false });
      };
    });
  }, []);

  return (
    <ConfirmDialog.Provider value={confirm}>
      {children}
      <Dialog {...state} onClose={() => fn.current?.(false)} onConfirm={() => fn.current?.(true)} />
    </ConfirmDialog.Provider>
  );
}

export function useConfirm() {
  const context = useContext(ConfirmDialog);
  if (!context) {
    throw new Error("useConfirm must be used within a ConfirmDialogProvider");
  }
  return context;
}
