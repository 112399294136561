import { useContext, useState, useCallback, createContext, ReactNode, useMemo } from "react";
import customLocalStorage from "../utils/localStorage";

interface IAuth {
  userToken: string;
  signIn: (username: string, token: string) => void;
  signOut: () => void;
}

export const AuthenticationContext = createContext<IAuth>({
  userToken: "",
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  signIn: (username: string, token: string): void => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  signOut: (): void => {},
});

export function useAuthentication() {
  return useContext(AuthenticationContext);
}

export function AuthenticationProvider({ children }: { children: ReactNode }) {
  const [userToken, setUserToken] = useState<string>("");

  const signIn = useCallback((username: string, token: string) => {
    customLocalStorage.setToken(username, token);
    setUserToken(token);
  }, []);

  const signOut = useCallback(() => {
    customLocalStorage.clearToken();
    setUserToken("");
  }, []);

  const authContextValue = useMemo(
    () => ({
      userToken,
      signIn,
      signOut,
    }),
    [userToken, signIn, signOut],
  );

  return (
    <AuthenticationContext.Provider value={authContextValue}>
      {children}
    </AuthenticationContext.Provider>
  );
}
