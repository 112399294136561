import { Prefixes } from "src/types";
import { FailedImageUploadRequest } from "src/types/failedImageUploadRequest";
import customLocalStorage from "./localStorage";

export const updateHouseImageRequestsWithNewName = (
  houseId: string,
  previousElementName: string,
  newElementName: string,
  isParentElement?: boolean,
  parentName?: string,
) => {
  const reqs = customLocalStorage.getItem(`${Prefixes.HOUSE_IMAGES}${houseId}`);

  /** Update 'elementName' in requests where it matches 'previousElementName' with the new 'newElementName' */
  const updatedElementNameRequests = reqs.map((item: FailedImageUploadRequest) => {
    if (item.elementName === previousElementName) {
      return { ...item, elementName: newElementName, parentName: parentName ?? "" };
    }
    return item;
  });

  /** If it is not parent element, update local storage with the 'updatedElementNameRequests' */
  if (!isParentElement)
    return customLocalStorage.setItem(
      `${Prefixes.HOUSE_IMAGES}${houseId}`,
      JSON.stringify(updatedElementNameRequests),
    );

  /** Update 'parentName' in requests where it matches 'previousElementName' with the new 'newElementName' */
  const updatedParentNameRequests = updatedElementNameRequests.map(
    (item: FailedImageUploadRequest) => {
      if (item.parentName === previousElementName) return { ...item, parentName: newElementName };
      return item;
    },
  );

  customLocalStorage.setItem(
    `${Prefixes.HOUSE_IMAGES}${houseId}`,
    JSON.stringify(updatedParentNameRequests),
  );
};
