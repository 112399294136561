import { Box, BoxProps, Paper, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { FC, PropsWithChildren } from "react";
import {
  ColumnContainer,
  DashboardHeader,
  DashboardHeaderIcon,
} from "../Dashboard/Dashboard.styled";
import edit from "../../../assets/icons/edit.svg";
import { MainButton } from "../Button/MainButton";
import StraightenIcon from "@mui/icons-material/Straighten";
import ExploreIcon from "@mui/icons-material/Explore";
import FaceIcon from "@mui/icons-material/Face";
import { useTranslation } from "react-i18next";
import { PlusIcon } from "../Icons";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";

export interface IContainerWrapperProps {
  title: string;
  titleBackgroundColor?: string;
  children: React.ReactNode;
}

const StyledPaperContainer = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.white.main,
}));

const ErrorCounter = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.red.main,
  width: "20px",
  height: "20px",
  position: "absolute",
  marginTop: "-40px",
  marginLeft: "-5px",
  borderRadius: "10px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: theme.palette.white.main,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.white.main,
  marginBottom: "0.6rem",
  fontSize: "2rem",
}));

const GreyPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.neutral.main,
  minHeight: "100px",
  maxHeight: "200px",
  height: "auto",
  overflowY: "auto",
}));

export const MainContainer = ({
  title,
  titleBackgroundColor,
  children,
}: IContainerWrapperProps) => {
  return (
    <StyledPaperContainer>
      <StyledBox>
        <Typography p={2} variant={"h2"} sx={{ background: titleBackgroundColor }}>
          {title}
        </Typography>
        {/* TODO: add map icon when needed. Currently we don't have icons ready. */}
      </StyledBox>
      {children}
    </StyledPaperContainer>
  );
};

export const ScrollContainer = ({ children }: { children: React.ReactNode }) => {
  return <GreyPaper>{children}</GreyPaper>;
};

export const Card: FC<PropsWithChildren<BoxProps>> = ({ children, ...props }) => {
  return (
    <Box minHeight={100} bgcolor="neutral.50" borderRadius={4} mt={2} p={3} {...props}>
      {children}
    </Box>
  );
};

/* refactor this */

export type BoxContainerProps = {
  title?: string;
} & BoxProps;

export const BoxContainer: FC<PropsWithChildren<BoxContainerProps>> = ({
  children,
  title,
  ...props
}) => {
  return (
    <Box
      my={3}
      p={3}
      display="flex"
      alignItems="center"
      bgcolor="primary.main"
      color="neutral.50"
      justifyContent="space-between"
      {...props}
    >
      {title && <Typography variant="h4">{title}</Typography>}
      {children}
    </Box>
  );
};

interface ICardSection extends BoxProps {
  title: string;
  errors: string[];
  icon?: string;
  path?: string;
  secondaryIconColor?: string;
  area?: string | number;
  direction?: string;
  inspectorName?: string;
  panels?: number;
  handleClick?: () => void;
  handleDuplicate?: () => void;
}

export const CardSection: FC<PropsWithChildren<ICardSection>> = ({
  title,
  icon,
  errors,
  path,
  secondaryIconColor,
  inspectorName,
  panels,
  area,
  direction,
  handleClick,
  handleDuplicate,
  ...props
}) => {
  const errorCount = errors.length;
  const singleErrorMessage = (str: string) => str?.charAt(0).toUpperCase() + str?.slice(1);
  const errorMessage =
    errorCount > 1 ? errorCount + " errors" : `${singleErrorMessage(errors[0])} validation error`;
  const { t } = useTranslation();

  return (
    <DashboardHeader {...props}>
      {errorCount > 0 && <ErrorCounter>{errorCount}</ErrorCounter>}
      <DashboardHeaderIcon>
        <img src={icon} alt={"card-icon"} width={"40px"} height={"40px"} />
      </DashboardHeaderIcon>
      <ColumnContainer width={"100%"}>
        <Typography>{title}</Typography>
        <Typography variant="subtitle1">
          {errorCount > 0 ? errorMessage : t("completed")}
        </Typography>
      </ColumnContainer>
      <Box sx={{ ml: "auto", display: "flex", alignItems: "center" }}>
        {!isNaN(area as number) && (area as number) > 0 && (
          <Box sx={{ mx: 1, display: "flex", alignItems: "center" }}>
            <StraightenIcon color="disabled" />
            {Number(area).toFixed(2)}m<sup>2</sup>
          </Box>
        )}
        {panels && (
          <Box sx={{ mx: 1, display: "flex", alignItems: "center" }}>
            <Typography sx={{ minWidth: "max-content", marginLeft: "5px", color: "#8B8B8B" }}>
              {panels} {t("panels")}
            </Typography>
          </Box>
        )}
        {direction && (
          <Box sx={{ mx: 1, display: "flex", alignItems: "center" }}>
            <ExploreIcon color="disabled" />
            {direction}
          </Box>
        )}
        {inspectorName && (
          <Box sx={{ mx: 1, display: "flex", alignItems: "center" }}>
            <FaceIcon sx={{ color: "#8B8B8B", fontSize: 23 }} />
            <Typography sx={{ minWidth: "max-content", marginLeft: "5px", color: "#8B8B8B" }}>
              {inspectorName}
            </Typography>
          </Box>
        )}
        <MainButton
          text={t("edit")}
          variant="text"
          icon={<img src={edit} width="15px" height="15px" alt="edit-icon" />}
          onClick={handleClick}
        />
        {handleDuplicate && (
          <MainButton
            text={t("duplicate")}
            variant="text"
            icon={
              <ContentCopyRoundedIcon sx={{ color: "#54C1F0" }} width="10px" fontSize="small" />
            }
            onClick={handleDuplicate}
          />
        )}
      </Box>
    </DashboardHeader>
  );
};

interface ICardSectionWindows extends BoxProps {
  title: string;
  icon: string;
  windows: number[] | [];
  editPath: () => void;
}

export const CardSectionWindows: FC<PropsWithChildren<ICardSectionWindows>> = ({
  title,
  icon,
  editPath,
  windows,
  ...props
}) => {
  const windowCount = windows.length;
  const cardSubtitle = windowCount ? (windowCount > 1 ? windowCount + " Windows" : "1 Window") : "";
  const { t } = useTranslation();

  return (
    <DashboardHeader {...props}>
      <DashboardHeaderIcon>
        <img src={icon} alt={"window-icon"} width={"40px"} height={"40px"} />
      </DashboardHeaderIcon>
      <ColumnContainer width={"100%"}>
        <Typography>{title}</Typography>
        <Typography variant="subtitle1">{cardSubtitle}</Typography>
      </ColumnContainer>
      <MainButton
        text={t("edit")}
        variant="text"
        icon={<img src={edit} width="15px" height="15px" alt="edit-icon" />}
        sx={{ ml: "auto" }}
      />
    </DashboardHeader>
  );
};

interface IEmptyContainer {
  title: string;
}

export const EmptyContainer: FC<PropsWithChildren<IEmptyContainer>> = ({ title }) => {
  return (
    <Box
      sx={{
        width: "100%",
        mt: 2,
        mb: 6,
        p: 3,
        display: "flex",
        justifyContent: "center",
        border: "1px solid #DDD",
        borderRadius: "10px",
      }}
    >
      <Typography variant="body1">{title}</Typography>
    </Box>
  );
};
