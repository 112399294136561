import { Box, Typography } from "@mui/material";
import { t } from "i18next";
import { AddPhotoBoxStyled, AddPhotoButtonStyled } from "./AddPhotoButton.styled";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";

interface AddPhotoButtonProps {
  handleImage: (imageArr: HTMLInputElement["files"]) => void;
}

const AddPhotoButton = ({ handleImage }: AddPhotoButtonProps) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <AddPhotoButtonStyled
        component={"label"}
        startIcon={
          <AddPhotoBoxStyled>
            <AddAPhotoIcon />
            <Typography variant="body1" sx={{ fontWeight: "600" }}>
              {t("addPicture")}
            </Typography>
          </AddPhotoBoxStyled>
        }
      >
        <input
          multiple
          type="file"
          accept="image/jpeg"
          capture
          style={{ display: "none" }}
          onChange={(e) => handleImage(e.target.files)}
        />
      </AddPhotoButtonStyled>
      <AddPhotoButtonStyled
        component={"label"}
        startIcon={
          <AddPhotoBoxStyled>
            <CollectionsOutlinedIcon />
            <Typography variant="body1" sx={{ fontWeight: "600" }}>
              {t("selectPicture")}
            </Typography>
          </AddPhotoBoxStyled>
        }
      >
        <input
          multiple
          type="file"
          accept="image/jpeg"
          style={{ display: "none" }}
          onChange={(e) => handleImage(e.target.files)}
        />
      </AddPhotoButtonStyled>
    </Box>
  );
};

export default AddPhotoButton;
