import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Category,
  IHouse,
  IImageTag,
  IMunicipality,
  IProduct,
  ISuggestion,
  Loading,
  SuggestionCategory,
} from "../../types";
import { updateProductsIndexDB } from "../indexedDB/objectStore";
import { RootState } from "../store";
import {
  fetchHouses,
  fetchImageTags,
  fetchMunicipalities,
  fetchProducts,
  fetchSuggestions,
  saveInspection,
  uploadInspectionImage,
} from "../thunks";

interface IInitialState {
  saveInspection: { response: string; loading: string; error: string };
  municipalities: { data: IMunicipality[]; loading: string; error: string };
  houses: { data: IHouse[]; loading: string; error: string };
  products: { data: IProduct[]; loading: string; error: string };
  suggestions: { data: ISuggestion[]; loading: string; error: string };
  imageTags: { data: IImageTag[]; loading: string; error: string };
}

const initialState: IInitialState = {
  saveInspection: {
    response: "",
    loading: "",
    error: "",
  },
  municipalities: {
    data: [],
    loading: Loading.IDLE,
    error: "",
  },
  houses: {
    data: [],
    loading: Loading.IDLE,
    error: "",
  },
  products: {
    data: [],
    loading: Loading.IDLE,
    error: "",
  },
  suggestions: {
    data: [],
    loading: Loading.IDLE,
    error: "",
  },
  imageTags: {
    data: [],
    loading: Loading.IDLE,
    error: "",
  },
};

export const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    syncProducts: (state, action) => {
      state.products.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(saveInspection.pending, (state) => {
      state.saveInspection.loading = Loading.PENDING;
    });
    builder.addCase(saveInspection.fulfilled, (state, action: PayloadAction<any>) => {
      state.saveInspection.loading = Loading.FULLFILLED;
      // state.saveInspection.response = action.payload;
      // redirect to houses screen?
    });
    builder.addCase(saveInspection.rejected, (state, action: PayloadAction<any>) => {
      state.saveInspection.loading = Loading.REJECTED;
      // if (action.error) {
      //   state.saveInspection.error = action.error.message;
      // }
    });

    builder.addCase(fetchMunicipalities.pending, (state) => {
      state.municipalities.loading = Loading.PENDING;
    });
    builder.addCase(fetchMunicipalities.fulfilled, (state, action: PayloadAction<any>) => {
      state.municipalities.loading = Loading.FULLFILLED;
      state.municipalities.data = action.payload;
    });
    builder.addCase(fetchMunicipalities.rejected, (state, action: PayloadAction<any>) => {
      state.municipalities.loading = Loading.REJECTED;
      state.municipalities.error = action.payload;
    });

    builder.addCase(fetchHouses.pending, (state) => {
      state.houses.loading = Loading.PENDING;
    });
    builder.addCase(fetchHouses.fulfilled, (state, action: PayloadAction<any>) => {
      state.houses.loading = Loading.FULLFILLED;
      state.houses.data = action.payload;
    });
    builder.addCase(fetchHouses.rejected, (state, action: PayloadAction<any>) => {
      state.houses.loading = Loading.REJECTED;
      state.houses.error = action.payload;
    });

    builder.addCase(fetchProducts.pending, (state) => {
      state.products.loading = Loading.PENDING;
    });
    builder.addCase(fetchProducts.fulfilled, (state, action: PayloadAction<any>) => {
      state.products.loading = Loading.FULLFILLED;
      state.products.data = action.payload;
      updateProductsIndexDB(action.payload);
    });
    builder.addCase(fetchProducts.rejected, (state, action: PayloadAction<any>) => {
      state.products.loading = Loading.REJECTED;
      state.products.error = action.payload;
    });

    builder.addCase(fetchSuggestions.pending, (state) => {
      state.suggestions.loading = Loading.PENDING;
    });
    builder.addCase(fetchSuggestions.fulfilled, (state, action: PayloadAction<any>) => {
      state.suggestions.loading = Loading.FULLFILLED;
      state.suggestions.data = action.payload;
    });
    builder.addCase(fetchSuggestions.rejected, (state, action: PayloadAction<any>) => {
      state.suggestions.loading = Loading.REJECTED;
      state.suggestions.error = action.payload;
    });

    builder.addCase(fetchImageTags.pending, (state) => {
      state.imageTags.loading = Loading.PENDING;
    });
    builder.addCase(fetchImageTags.fulfilled, (state, action: PayloadAction<any>) => {
      state.imageTags.loading = Loading.FULLFILLED;
      state.imageTags.data = action.payload;
    });
    builder.addCase(fetchImageTags.rejected, (state, action: PayloadAction<any>) => {
      state.imageTags.loading = Loading.REJECTED;
      state.imageTags.error = action.payload;
    });
    builder.addCase(uploadInspectionImage.pending, (state) => {
      // TODO if needed
    });
    builder.addCase(uploadInspectionImage.fulfilled, (state, action: PayloadAction<any>) => {
      // TODO if needed
      // console.log("upload response", action.payload);
      // return action.payload;
    });
    builder.addCase(uploadInspectionImage.rejected, (state, action: PayloadAction<any>) => {
      // TODO if needed
    });
  },
});

export const { syncProducts } = dataSlice.actions;

export const getSaveInspectionLoadingStatus = (state: RootState) =>
  state.data.saveInspection.loading;
export const getSaveInspectionError = (state: RootState) => state.data.saveInspection.error;

export const getMunicipalities = (state: RootState) => state.data?.municipalities?.data;
export const getMunicipalitiesLoadingStatus = (state: RootState) =>
  state.data.municipalities.loading;
export const getMunicipalitiesError = (state: RootState) => state.data.municipalities.error;

export const getHouses = (state: RootState) => state.data.houses.data;
export const getHousesLoadingStatus = (state: RootState) => state.data.houses.loading;
export const getHousesError = (state: RootState) => state.data.houses.error;

export const getProducts = createSelector(
  (state: RootState) => state.data.products.data,
  (products) => products,
);

export const getProductsLoadingStatus = (state: RootState) => state.data.products.loading;
export const getProductsError = (state: RootState) => state.data.products.error;
const fetchedProducts = (houseElement: Category) => (state: RootState) => {
  const filteredProducts = state.data?.products?.data?.filter(
    ({ category }: { category: string }) => category === houseElement,
  );
  if (filteredProducts) {
    filteredProducts.unshift({ id: "0_0", category: houseElement, name: "geen" });
  }
  return filteredProducts?.map((el) => {
    return { id: el.id, name: el.name };
  });
};
export const getCategorySpecificProducts = (houseElement: Category) =>
  createSelector(fetchedProducts(houseElement), (filteredProducts) => filteredProducts);

export const getSuggestions = (state: RootState) => state.data.suggestions.data;
export const getSuggestionsLoadingStatus = (state: RootState) => state.data.suggestions.loading;
export const getSuggestionsError = (state: RootState) => state.data.suggestions.error;
export const getFilteredSuggestions = (category: SuggestionCategory) => (state: RootState) =>
  state.data?.suggestions?.data?.filter((s: any) => s.category === category);

export const getImageTags = (state: RootState) => state.data.imageTags.data;
export const getImageTagsLoadingStatus = (state: RootState) => state.data.imageTags.loading;
export const getImageTagsError = (state: RootState) => state.data.imageTags.error;
const filterImageTags = (tagCategory: string) => (state: RootState) =>
  state.data?.imageTags?.data
    ?.map((tag) => (tag.category === tagCategory ? tag.value : undefined))
    .filter(Boolean);
export const getFilteredImageTags = createSelector([filterImageTags], (tags) => tags);
