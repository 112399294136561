import { yupResolver } from "@hookform/resolvers/yup";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import { Box, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import NetworkStatus from "src/components/NetworkStatus/NetworkStatus";
import BackButton from "src/components/common/Button/BackButton";
import { MainButton } from "src/components/common/Button/MainButton";
import { ScrollToTop } from "src/components/common/ScrollToTop";
import { getSummaryPageSchema } from "src/types/schema";
import type { InferType } from "yup";
import logo from "../../assets/icons/logo.png";
import { BlueBgPageWrapper } from "../../components/common/Container/BlueWrapper";
import { MainPageWrapper } from "../../components/common/Container/MainPage";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  getCompletedInspection,
  getInspectionDataErrors,
  getOwnerPreferencesData,
  getSummaryData,
  getWindowsSummary,
  syncStateWithIndexDB,
} from "../../store/slices";
import { SummaryCard } from "./SummaryCard";
import { WindowSummaryCard } from "./WindowSummaryCard";
import { fetchHouses, saveInspection, uploadInspectionImage } from "src/store/thunks";
import { Path } from "src/routes";
import { IImage, IInspection, Prefixes, SummaryAgreement } from "src/types";
import { getSolarCheckboxesValues } from "src/utils/helpers/getSolarCheckboxesValues";
import { useNetworkStatus } from "src/utils/hooks/networkStatus";
import {
  deleteAllImagesFromHouseDatabase,
  deleteDBInspection,
  deleteImageFromHouseDatabase,
  getImageFromHouseDatabase,
} from "src/store/indexedDB/objectStore";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import { SectionTitle } from "src/components/common/StepComponents/StepContainers";
import customLocalStorage from "src/utils/localStorage";

export const Summary = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const inspection = useAppSelector(getCompletedInspection);
  const allErrors = useAppSelector(getInspectionDataErrors(t));
  const summaryData = useAppSelector(getSummaryData());
  const windowsSummary = useAppSelector(getWindowsSummary());
  const ownerPreferences = useAppSelector(getOwnerPreferencesData);
  const SummarySchema = getSummaryPageSchema(t);

  const { id } = useParams();
  // List of rejected image requests that will be sent again when internet connection is restored
  const rejectedImageRequests = customLocalStorage.getItem(`${Prefixes.HOUSE_IMAGES}${id}`);

  const isOnline = useNetworkStatus();
  const [imageToUpload, setImageToUpload] = useState<{ count: number; name: string } | null>(null);

  type SummarySchemaType = InferType<typeof SummarySchema>;

  const form = useForm<SummarySchemaType>({
    resolver: yupResolver(SummarySchema),
    mode: "all",
    shouldUnregister: false,
    defaultValues: {
      floor: {
        preference: ownerPreferences?.floor?.preference || SummaryAgreement.DECLINE,
        reason: ownerPreferences?.floor?.reason || t("noChanges") || "",
      },
      wall: {
        preference: ownerPreferences?.wall?.preference || SummaryAgreement.DECLINE,
        reason: ownerPreferences?.wall?.reason || t("noChanges") || "",
      },
      roof: {
        reason: ownerPreferences?.roof?.reason || t("noChanges") || "",
        preference: ownerPreferences?.roof?.preference || SummaryAgreement.DECLINE,
      },
      solar: {
        reason: ownerPreferences?.solar?.reason || t("noChanges") || "",
        preference: ownerPreferences?.solar?.preference || SummaryAgreement.DECLINE,
      },
      windows: ownerPreferences?.windows?.map((el) => {
        return {
          reason: el?.reason ?? t("noChanges") ?? "",
          preference: el?.preference ?? SummaryAgreement.DECLINE,
        };
      }),
    },
  });

  const {
    trigger,
    formState: { errors },
  } = form;

  useEffect(() => {
    trigger();
  }, [trigger]);

  const checkIfFormDataIsValid = () => {
    trigger();

    const housePartErrors = Object.entries(errors);
    if (housePartErrors.length > 0) {
      if (Object.keys(housePartErrors[0][1])[0] === "preference") {
        toast.error(`${t("requiredField")}: ${t(housePartErrors[0][0])} ${t("preference")}`);
        return document
          .querySelector(`div[class*=parent-${housePartErrors[0][0]}]`)
          ?.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center" });
      } else {
        toast.error(`${t("requiredField")}: ${t(housePartErrors[0][0])} ${t("reason")}`);
        return document
          .querySelector(`div[class*=parent-${housePartErrors[0][0]}]`)
          ?.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center" });
      }
    }
    if (allErrors.length > 0) {
      toast.error(t("errorsFound") + ": " + allErrors.join(", ").trim());
      return;
    }

    return true;
  };

  const sendInspection = async (inspection: IInspection) => {
    const solarCheckboxValues = getSolarCheckboxesValues(
      inspection.solar.measures,
      inspection.solar.currentPhaseConnection,
      inspection.solar.requiredPhaseConnection,
      inspection.solar.mainFuseBoxLocation,
      inspection.solar.suggestedCableRoute,
    );

    const updatedInspection: IInspection = {
      ...inspection,
      solar: {
        ...inspection.solar,
        currentPhaseConnection: solarCheckboxValues.currentPhaseConnection,
        requiredPhaseConnection: solarCheckboxValues.requiredPhaseConnection,
        mainFuseBoxLocation: solarCheckboxValues.mainFuseBoxLocation,
        suggestedCableRoute: solarCheckboxValues.suggestedCableRoute,
      },
    };

    const res = await dispatch(saveInspection({ inspection: updatedInspection }));
    if (res.meta.requestStatus === "rejected") {
      return toast.error(t("inspectionSaveFail"));
    }
    toast.success(t("inspectionSaveSuccess"));

    await dispatch(fetchHouses());
    await deleteDBInspection(inspection.sphId);
    navigate(Path.InspectionComplete, { state: { id: inspection.sphId } });
  };

  const renderSendButton = () => {
    return isOnline ? (
      <Box sx={{ mx: 4, my: 8, display: "flex", justifyContent: "center" }}>
        <MainButton
          sx={{ width: "100%" }}
          onClick={handleSave}
          variant={!imageToUpload ? "primary" : "disabled"}
          text={imageToUpload ? t("sendingImagesAndInspection") : `${t("send")} ${t("inspection")}`}
          disabled={!!imageToUpload}
        />
      </Box>
    ) : (
      <Box sx={{ mx: 4, my: 8, display: "flex", justifyContent: "center" }}>
        <MainButton
          sx={{ width: "100%" }}
          onClick={() => null}
          variant="disabled"
          text={`${t("send")} ${t("inspection")}`}
          icon={<WifiOffIcon />}
          disabled
        />
      </Box>
    );
  };

  const renderProgressBar = () => {
    return imageToUpload ? (
      <SectionTitle
        title={`Uploading image ${imageToUpload?.count}/${rejectedImageRequests.length}: ${imageToUpload?.name}`}
        sx={{
          mb: 4,
          justifyContent: "center",
          display: "flex",
          width: "100%",
          fontWeight: "700",
        }}
      />
    ) : null;
  };
  const handleSave = async () => {
    const isValid = checkIfFormDataIsValid();

    // If some of the form data is invalid,  stop further execution
    if (!isValid) return;

    const inspectionCopy = { ...inspection };

    // Iterate through rejected image requests stored in local storage
    if (id && rejectedImageRequests?.length) {
      for (let index = 0; index < rejectedImageRequests.length; index++) {
        const image = rejectedImageRequests[index];
        setImageToUpload({ count: index + 1, name: image.name });

        // Retreive stored image in indexedDB
        const imageFile = await getImageFromHouseDatabase(+id, image.id);

        try {
          // Send request to upload image
          const { sphId, imgName } = await dispatch(
            uploadInspectionImage({
              sphId: id,
              image: imageFile.file,
              filename: imageFile.filename.replace(Prefixes.LOCAL, ""),
            }),
          ).unwrap();

          /**
           *  NOTE: If we add a new house part that contains images in the future,
           *  we need to ensure we update this logic to handle replacing image names accordingly
           *   similar to how it's done for walls, floors, etc.
           */

          // Replace the corresponding image name with the one retrieved from the backend after a successful upload

          // BUILDING
          if (image.housePartElement === "building") {
            const updatedImages = inspectionCopy.images.map((imageCopy: IImage) => {
              if (imageCopy.id === image.id) {
                return { tag: imageCopy.tag, filename: imgName };
              } else {
                return imageCopy;
              }
            });

            inspectionCopy["images"] = [...updatedImages];
          }

          // ATTIC FLOOR
          if (image.housePartElement === "atticFloor") {
            const updatedImages = (inspectionCopy.atticFloor?.images ?? []).map(
              (imageCopy: IImage) => {
                if (imageCopy.id === image.id) return { tag: imageCopy.tag, filename: imgName };
                return imageCopy;
              },
            );

            inspectionCopy.atticFloor
              ? (inspectionCopy.atticFloor = {
                  ...inspectionCopy.atticFloor,
                  images: [...updatedImages],
                })
              : "";
          }

          // SOLAR
          if (image.housePartElement === "solar") {
            const updatedImages = inspectionCopy.solar.images.map((imageCopy: IImage) => {
              if (imageCopy.id === image.id) return { tag: imageCopy.tag, filename: imgName };
              return imageCopy;
            });

            inspectionCopy.solar = { ...inspectionCopy.solar, images: [...updatedImages] };
          }

          // FLOORS
          if (image.housePartElement === "floor") {
            const floorsCopy = inspectionCopy.floors.map((floor) => {
              if (floor.name === image.elementName) {
                const images = floor.images.map((imageCopy) => {
                  if (imageCopy.id === image.id) return { tag: imageCopy.tag, filename: imgName };
                  return imageCopy;
                });

                return { ...floor, images };
              }

              return floor;
            });
            inspectionCopy.floors = floorsCopy;
          }

          // WALLS
          if (image.housePartElement === "walls") {
            const wallsCopy = inspectionCopy.walls.map((wall) => {
              if (wall.name === image.elementName) {
                const images = wall.images.map((imageCopy) => {
                  if (imageCopy.id === image.id) return { tag: imageCopy.tag, filename: imgName };
                  return imageCopy;
                });

                return { ...wall, images };
              }

              return wall;
            });
            inspectionCopy.walls = wallsCopy;
          }

          // ROOFS
          if (image.housePartElement === "roofs") {
            const roofsCopy = inspectionCopy.roofs.map((wall) => {
              if (wall.name === image.elementName) {
                const images = wall.images.map((imageCopy) => {
                  if (imageCopy.id === image.id) return { tag: imageCopy.tag, filename: imgName };
                  return imageCopy;
                });

                return { ...wall, images };
              }

              return wall;
            });
            inspectionCopy.roofs = roofsCopy;
          }

          // SOLAR PANELS

          if (image.housePartElement === "solarArray") {
            const solarPanelsCopy = inspectionCopy.solar.measures.map((panel) => {
              if (panel.name === image.elementName) {
                const images = panel.images.map((imageCopy) => {
                  if (imageCopy.id === image.id) return { tag: imageCopy.tag, filename: imgName };
                  return imageCopy;
                });

                return { ...panel, images };
              }

              return panel;
            });

            inspectionCopy.solar = { ...inspection.solar, measures: solarPanelsCopy };
          }

          // ROOF - DORMERS
          if (image.housePartElement === "dormers") {
            const roofsCopy = inspectionCopy.roofs.map((roof) => {
              if (roof.name === image.parentName) {
                const updatedDormers = roof.dormers.map((dormer) => {
                  if (dormer.name === image.elementName) {
                    const updatedImages = dormer.images.map((imageCopy) => {
                      if (imageCopy.id === image.id) {
                        return { tag: imageCopy.tag, filename: imgName };
                      }
                      return imageCopy;
                    });
                    return { ...dormer, images: updatedImages };
                  }
                  return dormer;
                });
                return { ...roof, dormers: updatedDormers };
              }
              return roof;
            });

            inspectionCopy.roofs = roofsCopy;
          }

          // WINDOWS
          if (image.housePartElement === "windows") {
            if (image.parentElement === "[Daken]") {
              //roofs

              const updatedRoofs = inspectionCopy.roofs.map((roof) => {
                if (roof.name === image.parentName) {
                  const updatedWindows = roof.windowGroups.map((window) => {
                    if (window.name === image.elementName) {
                      const updatedImages = window.images.map((imageCopy) => {
                        if (imageCopy.id === image.id)
                          return { tag: imageCopy.tag, filename: imgName };

                        return imageCopy;
                      });
                      return { ...window, images: updatedImages };
                    }
                    return window;
                  });
                  return { ...roof, windowGroups: updatedWindows };
                }
                return roof;
              });

              inspectionCopy.roofs = updatedRoofs;
            }

            if (image.parentElement === "[Muren]") {
              // walls

              const wallsCopy = inspectionCopy.walls.map((wall) => {
                if (wall.name === image.parentName) {
                  const updatedWindows = wall.windowGroups.map((window) => {
                    if (window.name === image.elementName) {
                      const updatedImages = window.images.map((imageCopy) => {
                        if (imageCopy.id === image.id)
                          return { tag: imageCopy.tag, filename: imgName };

                        return imageCopy;
                      });
                      return { ...window, images: updatedImages };
                    }
                    return window;
                  });
                  return { ...wall, windowGroups: updatedWindows };
                }
                return wall;
              });

              inspectionCopy.walls = wallsCopy;
            }

            if (image.parentElement === "[Dakkapellen]") {
              // dormers
              const roofsCopy = inspectionCopy.roofs.map((roof) => {
                const dormersCopy = roof.dormers.map((dormer) => {
                  if (dormer.name === image.parentName) {
                    const windowGroupsCopy = dormer.windowGroups.map((windowGroup) => {
                      const imagesCopy = windowGroup.images.map((imageCopy) => {
                        if (imageCopy.id === image.id) {
                          return { tag: imageCopy.tag, filename: imgName };
                        }
                        return imageCopy;
                      });
                      return { ...windowGroup, images: imagesCopy };
                    });
                    return { ...dormer, windowGroups: windowGroupsCopy };
                  }
                  return dormer;
                });
                return { ...roof, dormers: dormersCopy };
              });

              inspectionCopy.roofs = roofsCopy;
            }
          }

          if (index === rejectedImageRequests.length - 1) {
            // Remove house image item from custom local storage
            customLocalStorage.removeItem(`${Prefixes.HOUSE_IMAGES}${id}`);

            // Update redux state
            dispatch(syncStateWithIndexDB(inspectionCopy));
            await deleteAllImagesFromHouseDatabase(+sphId);

            // Send inspection to the backend
            await sendInspection(inspectionCopy);

            setImageToUpload(null);
          }

          // Delete uploaded image from indexedDB
          await deleteImageFromHouseDatabase(+sphId, image.id);
        } catch (error: any) {
          const errorMessage: string = error?.response?.data?.message ?? error.message;
          toast.error(`${t("rejectImageUpload")}  ${errorMessage.length ? errorMessage : ""}`);

          // Remove executed image requests
          rejectedImageRequests.splice(0, index);

          //  Update local storage with the filtered list of executed requests
          if (!rejectedImageRequests.length) {
            customLocalStorage.removeItem(`${Prefixes.HOUSE_IMAGES}${id}`);
          } else {
            customLocalStorage.setItem(
              `${Prefixes.HOUSE_IMAGES}${id}`,
              JSON.stringify(rejectedImageRequests),
            );
          }

          // Update Redux state
          dispatch(syncStateWithIndexDB(inspectionCopy));
          setImageToUpload(null);
          return;
        }
      }
    } else {
      /** No images to upload, send inspection */
      return await sendInspection(inspectionCopy);
    }
  };
  return (
    <MainPageWrapper>
      <ScrollToTop />
      <BlueBgPageWrapper>
        <Box sx={{ position: "absolute", left: "10px" }}>
          <img src={logo} width="70px" alt={"DeWoonpas"} />
          <BackButton props={{ position: "relative", left: "1rem" }} />
        </Box>
        <Paper elevation={0} sx={{ margin: "5rem 4rem 2rem 5rem", borderRadius: "15px" }}>
          <NetworkStatus />
          <Box
            sx={{ display: "flex", marginTop: "2rem", marginLeft: "2rem", alignItems: "center" }}
          >
            <CommentOutlinedIcon fontSize="small" sx={{ marginRight: "1rem" }} />
            <Typography variant="h5" component={"h1"}>
              {t("summary")}
            </Typography>
          </Box>
          <FormProvider {...form}>
            {[...summaryData.entries()].map((el) => (
              <SummaryCard title={el[0]} key={el[0]} elementSummary={el[1]} />
            ))}
            {windowsSummary.map((storey, index) => (
              <WindowSummaryCard
                key={`${index}_${storey?.title}`}
                title={storey.title}
                storeyOwnerAgreements={storey.ownerAgreements}
                windowSummary={storey.windowGroups}
                index={index}
              />
            ))}
          </FormProvider>
          {renderSendButton()}
          {renderProgressBar()}
        </Paper>
      </BlueBgPageWrapper>
    </MainPageWrapper>
  );
};
