import FaceIcon from "@mui/icons-material/Face";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Box, BoxProps, Typography, TypographyProps } from "@mui/material";
import { FC, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import edit from "../../../assets/icons/edit.svg";
import { MainButton } from "../Button/MainButton";
import {
  ColumnContainer,
  DashboardHeader,
  DashboardHeaderIcon,
} from "../Dashboard/Dashboard.styled";
import { PlusIcon } from "../Icons";
import { CenteredEmptyBox, IconEmptyBox } from "./StepContainers.styled";
import { SmallCircleBox } from "../../NetworkStatus/NetworkStatus.styles";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";

interface ISectionTitle extends TypographyProps {
  title: string;
}

export const SectionTitle: FC<PropsWithChildren<ISectionTitle>> = ({
  children,
  title,
  ...props
}) => {
  return (
    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
      <Typography variant="h2" {...props}>
        {title}
      </Typography>
      {children}
    </Box>
  );
};

interface ICardSection extends BoxProps {
  title: string;
  Icon: any;
  hasInspection?: boolean;
  localInspection?: boolean;
  inspectorName: string;
  inspectionReceivedTime: string;
  secondaryIconColor?: string;
  handleClick: () => void;
}

export const CardSection: FC<PropsWithChildren<ICardSection>> = ({
  title,
  Icon,
  hasInspection,
  localInspection,
  secondaryIconColor,
  handleClick,
  inspectorName,
  inspectionReceivedTime,
  ...props
}) => {
  const { t } = useTranslation();
  const color = localInspection ? "#FFA65A" : hasInspection ? "#2EB138" : "";

  return (
    <DashboardHeader {...props} sx={{ flexWrap: "wrap" }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <DashboardHeaderIcon>
          {localInspection ? <Icon color="#8B8B8B" /> : <Icon color="action" />}
        </DashboardHeaderIcon>
        <ColumnContainer width={"100%"}>
          <Typography>{title}</Typography>
          <Typography variant="subtitle1">
            <SmallCircleBox
              sx={{ backgroundColor: color, display: "inline-flex", marginRight: 1 }}
            />
            {hasInspection && !localInspection
              ? hasInspection
                ? t("submitted")
                : t("noInspectionSubmitted")
              : t("offlineDocument")}
          </Typography>
        </ColumnContainer>
      </Box>
      <Box sx={{ ml: "auto", display: "flex", alignItems: "center" }}>
        {inspectorName.length ? (
          <Box sx={{ mx: 1, display: "flex", alignItems: "center" }}>
            <FaceIcon sx={{ color: "#8B8B8B", fontSize: 23 }} />
            <Typography sx={{ minWidth: "max-content", marginLeft: "5px", color: "#8B8B8B" }}>
              {inspectorName}
            </Typography>
          </Box>
        ) : null}

        <Box sx={{ mx: 1, display: "flex", alignItems: "center" }}>
          <AccessTimeIcon sx={{ color: "#8B8B8B", fontSize: 23 }} />
          <Typography sx={{ minWidth: "max-content", marginLeft: "5px", color: "#8B8B8B" }}>
            {inspectionReceivedTime}
          </Typography>
        </Box>

        {localInspection || (hasInspection && !localInspection) ? (
          <MainButton
            text={t("edit")}
            variant="text"
            icon={<img src={edit} width="15px" height="15px" alt="edit-icon" />}
            onClick={handleClick}
          />
        ) : null}
      </Box>
    </DashboardHeader>
  );
};

interface ICardSectionWindows extends BoxProps {
  title: string;
  icon: string;
  windows: number;
  errors?: any;
  handleClick?: () => void;
  handleDuplicate?: () => void;
}

export const CardSectionWindows: FC<PropsWithChildren<ICardSectionWindows>> = ({
  title,
  icon,
  windows,
  errors,
  handleClick,
  handleDuplicate,
  ...props
}) => {
  const { t } = useTranslation();
  const cardSubtitle = windows ? (windows > 1 ? windows + " Ruiten" : "1 Ruit") : "";
  return (
    <DashboardHeader {...props}>
      <DashboardHeaderIcon>
        <img src={icon} alt={"window-icon"} width={"40px"} height={"40px"} />
      </DashboardHeaderIcon>
      <ColumnContainer width={"100%"}>
        <Typography>{title}</Typography>
        <Box sx={{ display: "flex" }}>
          <Typography variant="subtitle1">{cardSubtitle}</Typography>
          {errors > 0 ? (
            <Typography color="#F12F21" variant="subtitle1" ml={2}>
              {errors} errors
            </Typography>
          ) : null}
        </Box>
      </ColumnContainer>
      <MainButton
        handleClick={handleClick}
        text={t("edit")}
        variant="text"
        icon={<img src={edit} />}
        sx={{ ml: "auto", px: 2 }}
      />
      {handleDuplicate && (
        <MainButton
          text={t("duplicate")}
          variant="text"
          icon={<ContentCopyRoundedIcon sx={{ color: "#54C1F0" }} width="10px" fontSize="small" />}
          sx={{ px: 2 }}
          handleClick={handleDuplicate}
        />
      )}
    </DashboardHeader>
  );
};

interface IEmptyContainer extends BoxProps {
  title: string;
  icon?: any;
  /* icon?:
    | FC<IconBaseProps>
    | (OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
        muiName: string;
      }); */
  action?: any /* store dispatch? */;
}

export const EmptyContainer: FC<PropsWithChildren<IEmptyContainer>> = ({
  title,
  icon,
  action,
  ...props
}) => {
  const { t } = useTranslation();
  return !icon ? (
    <CenteredEmptyBox {...props}>
      <Typography variant="body1">{title}</Typography>
    </CenteredEmptyBox>
  ) : (
    <IconEmptyBox {...props}>
      <Box display="flex" alignItems="center">
        <DashboardHeaderIcon sx={{ backgroundColor: "#FFF" }}>
          <img src={icon} alt={"window-icon"} width={"40px"} height={"40px"} />
        </DashboardHeaderIcon>
        <Typography variant="body1">{title}</Typography>
      </Box>
      <MainButton
        text={t("add")}
        variant="secondary"
        icon={<PlusIcon color="white" width="10px" />}
        handleClick={action}
      />
    </IconEmptyBox>
  );
};
