import { ReactNode } from "react";
import { styled } from "@mui/material/styles";
import { Grid, GridProps } from "@mui/material";

const StyledGrid = styled(Grid)(() => ({
  marginTop: "2rem",
  width: "100%",
}));

interface ITwoColumnsContainer extends GridProps {
  childrenFirstColumn: ReactNode;
  childrenSecondColumn: ReactNode;
}

export const TwoColumnsContainer = ({
  childrenFirstColumn,
  childrenSecondColumn,
  ...props
}: ITwoColumnsContainer) => {
  return (
    <StyledGrid {...props}>
      <Grid container spacing={{ xs: 2, md: 1 }} columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          {childrenFirstColumn}
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          {childrenSecondColumn}
        </Grid>
      </Grid>
    </StyledGrid>
  );
};
