import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import type { InferType } from "yup";
import { MainButton } from "../../components/common/Button/MainButton";
import { MainPageWrapper } from "../../components/common/Container/MainPage";
import { PageWrapper } from "../../components/common/Container/PageWrapper";
import { Cover } from "../../components/common/Cover/Cover";
import { Form } from "../../components/common/Form/Form";
import {
  AdditionalData,
  AdditionalDataWrapper,
} from "../../components/common/StepComponents/AdditionalData";
import { useAppDispatch } from "../../store/hooks";
import { saveWindowNotes } from "../../store/slices";
import { getGlazingSchema } from "../../types/schema";

export const GlazingNotes = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const { flatId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const glazingSchema = getGlazingSchema(t);
  type GlazingType = InferType<typeof glazingSchema>;

  let ownerWindowNotes,
    supplierWindowNotes = "";

  if (flatId) {
    ownerWindowNotes =
      state?.ownerWindowNotes && state?.ownerWindowNotes.length >= 0
        ? state?.ownerWindowNotes[+flatId]
        : "";
    supplierWindowNotes =
      state?.supplierWindowNotes && state?.supplierWindowNotes.length >= 0
        ? state?.supplierWindowNotes[+flatId]
        : "";
  }

  const form = useForm<GlazingType>({
    mode: "all",
    resolver: yupResolver(glazingSchema),
    defaultValues: {
      ownerWindow: ownerWindowNotes,
      supplierWindow: supplierWindowNotes,
    },
  });

  const {
    handleSubmit,
    trigger,
    formState: { errors },
  } = form;

  useEffect(() => {
    trigger();
  }, [trigger]);

  const handleSave = () => {
    if ("ownerWindow" in form.formState.errors) {
      toast.error(t("mandatoryField"));
      form.setFocus("ownerWindow");
      return;
    }
    dispatch(saveWindowNotes({ ...form.getValues(), index: flatId }));
    navigate(-1);
  };
  return (
    <MainPageWrapper>
      <Cover
        title={
          t("windows") +
          " " +
          (flatId && +flatId === 0 ? t("groundFloor") : `${flatId}e ${t("floor")}`)
        }
        showBackButton={true}
      />
      <PageWrapper>
        <Form onSubmit={handleSubmit(handleSave, handleSave)}>
          <AdditionalDataWrapper title={t("ownerAgreements")}>
            <AdditionalData
              name="ownerWindow"
              form={form}
              suggestions={state.windowSuggestionOwner}
              error={errors.ownerWindow}
            />
          </AdditionalDataWrapper>
          <AdditionalDataWrapper title={t("contractorAgreements")}>
            <AdditionalData
              name="supplierWindow"
              form={form}
              suggestions={state.windowSuggestionSupplier}
              error={errors.supplierWindow}
            />
          </AdditionalDataWrapper>
        </Form>
        <MainButton
          text={t("save")}
          variant="primary"
          onClick={handleSave}
          sx={{ margin: "30px 0" }}
        />
      </PageWrapper>
    </MainPageWrapper>
  );
};
