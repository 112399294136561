import { Box, styled } from "@mui/material";
import { Card } from "../Container/Containers";

export const LShape = styled(Box)(() => ({
  width: "20px",
  height: "20px",
  border: "1px solid #DDD",
  borderRight: "none",
  borderTop: "none",
  marginRight: "7px",
}));

export const ColumnContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
}));

export const DashboardHeader = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
}));

export const DashboardHeaderIcon = styled(Box)(() => ({
  padding: "0.5rem",
  border: "1px solid #DDD",
  borderRadius: 20,
  lineHeight: 0,
  marginRight: 20,
}));
