import { TextareaAutosize } from "@mui/base";
import { Backdrop, Box, Fade, Modal, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../store/hooks";
import { getNotes, update } from "../../store/slices/notesSlice";
import { Card } from "./Container/Containers";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { MainButton } from "./Button/MainButton";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

const Close = styled(CloseIcon)(() => ({
  position: "absolute",
  top: "20px",
  right: "20px",
}));

const NotesWrapper = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  padding: "50px",
  lineHeight: 0,
  backgroundColor: theme.palette.neutral[900],
  boxSizing: "border-box",
  textarea: {
    resize: "none",
    color: theme.palette.neutral[700],
  },
  [theme.breakpoints.up("md")]: {
    padding: "50px 100px",
  },
}));

const CustomInput = styled(TextareaAutosize)(({ theme }) => ({
  fontSize: 30,
  position: "relative",
  width: "100%",
  height: "100%",
  minHeight: "calc(100% - 250px)",
  boxSizing: "border-box",
  "::-webkit-resizer": {
    borderWidth: "10px",
    borderStyle: "solid",
    borderColor: `transparent ${theme.palette.primary.dark} ${theme.palette.primary.dark} transparent`,
  },
}));

export const NotesModal = ({
  isOpen,
  handleModal,
}: {
  isOpen: boolean;
  handleModal: () => void;
}) => {
  const { notes } = useAppSelector(getNotes);
  const textRef = useRef<HTMLTextAreaElement>(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return (
    <Modal
      aria-labelledby="notes-modal-title"
      aria-describedby="notes-modal-description"
      open={isOpen}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
          onClick: () => {
            handleModal();
          },
        },
      }}
    >
      <Fade in={isOpen}>
        <NotesWrapper>
          <Close onClick={() => handleModal()} sx={{ color: "#fff" }} />
          <Card>
            <Typography variant="h2" mb={2} sx={{ display: "flex", alignItems: "center" }}>
              <EditNoteIcon />
              {t("generalNotes")}
            </Typography>
            <CustomInput placeholder={`${t("makeNote")}...`} defaultValue={notes} ref={textRef} />
            <MainButton
              variant="primary"
              sx={{ marginTop: "20px" }}
              text={t("save")}
              handleClick={() => {
                dispatch(update(textRef.current?.value));
                handleModal();
              }}
            />
          </Card>
        </NotesWrapper>
      </Fade>
    </Modal>
  );
};
