import { Backdrop, Fade, Modal } from "@mui/material";
import { ImageContainer } from "./Photos.styled";

export interface IPhotoModalProps {
  imageSrc: string;
  isOpen: boolean;
  handlePhotoModal: () => void;
}

export function PhotoModal({ imageSrc, isOpen, handlePhotoModal }: IPhotoModalProps) {
  return (
    <Modal
      keepMounted
      aria-labelledby="Open image modal"
      aria-describedby="Click on image to enlarge"
      open={isOpen}
      onClose={handlePhotoModal}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={isOpen}>
        <ImageContainer>
          <img
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
            src={imageSrc}
            alt="img title..."
            onClick={handlePhotoModal}
          />
        </ImageContainer>
      </Fade>
    </Modal>
  );
}
