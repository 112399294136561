import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Box, styled } from "@mui/material";
import { AccordionSummaryItem } from "../StepComponents/AccordionElements";
import { MainButton } from "../Button/MainButton";
import { PlusIcon } from "../Icons";
import { useTranslation } from "react-i18next";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";

/**
 * Mandatory fields for accordion
 * @param title - The title of the accordion.
 * @param panel - This is the custom name each panel has. It is used as a unique value for managing open/close changes.
 * @param expanded - Used for managing opening/closing accordion.
 * @param handleChange - Callback for handling accordion open/close state.
 * @param children - Content for the accordion.
 */
interface IAccordionContainerWrapperProps {
  title: string;
  panel: string;
  area?: number | undefined;
  expanded: boolean;
  errors: string[] | [];
  handleChange: (panel: string) => void;
  handleDuplicate?: () => void;
  children?: React.ReactNode; // children is optional for now because we don't have data
}

const RoundedAccordion = styled(Accordion)(({ theme }) => ({
  // borderStyle: "solid",
  // // borderTop: 10,
  // borderColor: "red",
  borderRadius: "25px",
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  "&.MuiPaper-root::before": { all: "unset" },
  ".MuiCollapse-entered": {
    paddingBottom: theme.spacing(1),
  },
}));

export const AccordionContainer = ({
  title,
  children,
  panel,
  area,
  expanded,
  errors,
  handleChange,
  handleDuplicate,
}: IAccordionContainerWrapperProps) => {
  const { t } = useTranslation();
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <RoundedAccordion
        elevation={0}
        disableGutters
        expanded={expanded}
        onChange={() => handleChange(panel)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <AccordionSummaryItem title={title} errors={errors} expanded={expanded} area={area} />
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </RoundedAccordion>
      {handleDuplicate && (
        <MainButton
          text={t("duplicate")}
          variant="white"
          icon={<ContentCopyRoundedIcon sx={{ color: "#54C1F0" }} width="10px" fontSize="small" />}
          sx={{ p: 2, mt: 1, alignSelf: "center" }}
          handleClick={handleDuplicate}
        />
      )}
    </Box>
  );
};
