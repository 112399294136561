import Box from "@mui/material/Box";
import { FC, PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";
import { MainButton } from "../Button/MainButton";

interface IPageWrapper {
  children: React.ReactNode;
  path?: string;
  buttonText?: string;
}

export const PageWrapper: FC<PropsWithChildren<IPageWrapper>> = ({
  buttonText,
  path,
  children,
  ...props
}) => {
  const navigate = useNavigate();

  return (
    <>
      <Box px={3} width="100%" {...props}>
        <>
          {children}
          {path && (
            /* TODO - change navigation when path/component exists */
            <Box my={5} sx={{ display: "flex", justifyContent: "flex-end" }}>
              <MainButton
                handleClick={() => navigate(path)}
                text={buttonText || "Start new recording"}
                variant="primary"
              />
            </Box>
          )}
        </>
      </Box>
    </>
  );
};
