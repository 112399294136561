import { Box, Typography } from "@mui/material";

export function AppVersion() {
  return (
    <Box
      sx={{
        position: "absolute",
        width: "auto",
        bottom: "1px",
        right: "0",
        left: "0",
        marginLeft: "auto",
        marginRight: "auto",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Typography variant="subtitle2">v.{process.env.REACT_APP_VERSION}</Typography>
    </Box>
  );
}
