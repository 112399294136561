import { BoxProps, Box, Grid, Typography, TextField, styled, Stack } from "@mui/material";
import { FC, PropsWithChildren, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ISuggestion } from "../../../types";
import { MainButton } from "../Button/MainButton";
import { InputFieldError } from "../Form/FormInput";

export type AdditionalDataWrapperProps = {
  title: string;
} & BoxProps;

export const AdditionalDataWrapper: FC<PropsWithChildren<AdditionalDataWrapperProps>> = ({
  children,
  title,
  ...props
}) => {
  return (
    <Box minHeight={100} bgcolor="neutral.50" {...props} sx={{ borderRadius: "25px" }} mt={2}>
      <Box sx={{ display: "flex", pl: 3, pt: 5 }}>
        <Typography fontWeight="bold" variant="h2">
          {title}
        </Typography>
      </Box>
      {children}
    </Box>
  );
};

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& label.Mui-focused": {
    color: theme.palette.primary.dark,
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.primary.dark,
    },
  },
}));

export type AdditionalDataProps = {
  suggestions: ISuggestion[];
  error?: InputFieldError;
  form?: any;
  name?: string;
};

export const AdditionalData: FC<AdditionalDataProps> = ({ suggestions, error, form, name }) => {
  const { t } = useTranslation();
  const placeholder = t("typing");
  const errorText = error?.message;
  const { register, trigger } = form;
  const [countSuggestions, setCountSuggestions] = useState(0);
  const [inputValue, setInputValue] = useState("");

  const handleAddSuggestion = useCallback(
    (value: string) => {
      setCountSuggestions(countSuggestions + 1);
      const predefinedSuggestion = form.getValues(name);

      if (countSuggestions < 1 && inputValue.length < 1 && predefinedSuggestion.length < 1) {
        form.setValue(name, form.getValues(name).concat(value));
        trigger();
      } else {
        if ( predefinedSuggestion.length < 1) {
          form.setValue(name, form.getValues(name).concat(value));
          trigger();

          return
        }
        form.setValue(name, `${form.getValues(name)}\n${value}`);
        trigger();
      }
    },
    [form, name, countSuggestions, inputValue.length, trigger],
  );

  useEffect(() => {
    trigger();
  }, [trigger]);

  return (
    <Grid container flexWrap="wrap" p={3}>
      <Grid item container sm={12}>
        <Grid item sm={12} mb={5}>
          <StyledTextField
            {...register(name)}
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            placeholder={placeholder}
            error={!!errorText}
            helperText={errorText}
            onChange={(event) => setInputValue((event.target as HTMLInputElement).value)}
          />
        </Grid>
      </Grid>
      <Grid item container sm={12}>
        <Grid item sm={12} mb={2}>
          <Stack
            direction="row"
            justifyContent="flex-start"
            sx={{ flexWrap: "wrap", columnGap: "10px", rowGap: "15px" }}
          >
            {suggestions.map((suggestion, index) => (
              <MainButton
                key={suggestion.name + index}
                text={suggestion.value}
                variant="suggest"
                handleClick={() => handleAddSuggestion(suggestion.value)}
                sx={{ marginBottom: "2px", minWidth: "160px" }}
              />
            ))}
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  );
};
