import { useState } from "react";
import {
  FloorIcon,
  GeneralInfo,
  HouseIcon,
  NotesIcon,
  OverviewIcon,
  RoofIcon,
  SolarIcon,
  WindowIcon,
} from "../common/Icons";
import logo from "../../assets/icons/logo.png";
import { Path } from "../../routes";
import { NotesModal } from "../common/NotesComponent";
import { NavListStyled, SidebarStyled } from "./Sidebar.styles";
import { SidebarItem } from "./SidebarItem";
import { SidebarNotes } from "./SidebarNotes";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

const Sidebar = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const { id } = useParams();
  const { t } = useTranslation();
  const handleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <SidebarStyled>
      <NavListStyled>
        <img src={logo} width="60px" alt={"DeWoonpas"} onClick={() => navigate(`${Path.Houses}/${id}`)} style={{ marginLeft: "5px" }} />
        <SidebarItem Icon={OverviewIcon} text={t("overview")} path={Path.Dashboard} />
        <SidebarItem Icon={GeneralInfo} text={t("general")} path={Path.HouseInfo} />
        <SidebarItem Icon={HouseIcon} text={t("walls")} path={Path.Wall} />
        <SidebarItem Icon={WindowIcon} text={t("windows")} path={Path.Flat} />
        <SidebarItem Icon={RoofIcon} text={t("roof")} path={Path.Roof} />
        <SidebarItem Icon={FloorIcon} text={t("floors")} path={Path.Floor} />
        <SidebarItem Icon={SolarIcon} text={t("solar")} path={Path.Solar} />
        <SidebarNotes Icon={NotesIcon} handleNotes={handleModal} />
      </NavListStyled>
      <NotesModal isOpen={isOpen} handleModal={handleModal} />
    </SidebarStyled>
  );
};

export default Sidebar;
