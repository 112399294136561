import { Typography, Box, Fade, Alert, Snackbar } from "@mui/material";
import React, { useState } from "react";
import { MainButton } from "../Button/MainButton";
import { Card } from "../Container/Containers";
import { BlackBackground, ButtonName } from "./Dialog.styles";
import { useTranslation } from "react-i18next";

interface IBlackPopUp {
  title?: string;
  description?: string;
  isOpen?: boolean;
  confirmBtnLabel?: string;
  children?: React.ReactNode;
  handlePopUp?: () => void;
  onClose?: any;
  onConfirm?: any;
  deleteMessage?: string;
  cancelBtnLabel?: string;
}

const DialogComponent = ({
  title,
  description,
  isOpen,
  onClose,
  onConfirm,
  confirmBtnLabel,
  deleteMessage,
  cancelBtnLabel,
}: IBlackPopUp) => {
  const [openNotification, setOpenNotification] = useState(false);
  const { t } = useTranslation();

  const handleCloseNotification = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenNotification(false);
  };

  return (
    <>
      <Fade in={isOpen}>
        <BlackBackground>
          <Card>
            <Typography>{title}</Typography>
            <Box sx={{ margin: "30px 0", color: "#707070" }}>{description}</Box>
            <ButtonName>
              <MainButton
                variant="text"
                onClick={() => onClose()}
                text={cancelBtnLabel || t("cancel")}
              />
              <MainButton
                variant="text"
                onClick={() => {
                  onConfirm(), setOpenNotification(true);
                }}
                sx={{ marginLeft: "10px" }}
                text={confirmBtnLabel}
              />
            </ButtonName>
          </Card>
        </BlackBackground>
      </Fade>
      {openNotification && (
        <Snackbar open={openNotification} autoHideDuration={2000} onClose={handleCloseNotification}>
          <Alert onClose={handleCloseNotification} severity="success" sx={{ width: "100%" }}>
            {deleteMessage ? deleteMessage : t("defaultDeleteMessage")}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default DialogComponent;
