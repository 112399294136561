import { Box, Button, styled } from "@mui/material";

export const HoverBox = styled(Box)(() => ({
  position: "absolute",
  right: "30px",
  top: "20px",
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "flex-end",
  width: "100px",
  height: "100px",
  cursor: "pointer",
}));

export const SmallCircleBox = styled(Box)(() => ({
  position: "relative",
  width: "8px",
  height: "8px",
  borderRadius: "8px",
}));

export const UsernameBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  columnGap: "5px",
  cursor: "pointer",
}));

export const LogoutBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50px",
  right: "10px",
  zIndex: 2,
  padding: theme.spacing(3),
  background: "#F2F9FF",
  borderRadius: "25px",
}));

export const UsernameInitials = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  width: "60px",
  height: "60px",
  borderRadius: "60px",
  backgroundColor: theme.palette.primary[800],
  color: theme.palette.white.main,
}));

export const SingOutBox = styled(Button)(({ theme }) => ({
  padding: theme.spacing(3),
  alignItems: "center",
  cursor: "pointer",
  background: "transparent",
  border: "transparent",
  width: "100%",
  color: theme.palette.neutral[600],
  ":hover": {
    background: "none",
    color: theme.palette.neutral[600],
  }
}));